import React from 'react';
import { Link } from 'react-router-dom';
import { AppButton, AppButtons, LogInForm } from '../../Components';
import { makeBookingPathBuilders } from '../MakeBooking';
import styles from './LogIn.module.css';

export const LoginPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.backgroundImage}>
                <div className={styles.logoContainer}>
                    <img
                        className={styles.image}
                        src="/assets/images/logo/logo.png"
                        alt="Mobile Car Valeting in Edinburgh & Glasgow | Fresh Car Valeting"
                    />
                    <p className={styles.strapLine}>Wherever you park it, we'll clean it!</p>
                </div>
            </div>
            <div className={styles.loginContent}>
                <p className={styles.notExistingCustomer}><b>New Customer?</b></p>
                <Link
                    className={styles.bookFirstValet}
                    to={makeBookingPathBuilders.locationCheck()}>
                    <AppButtons>
                        <AppButton type="submit" variant="inverted" flexGrow={1}>
                            Get a Quote
                        </AppButton>
                    </AppButtons>
                </Link>
                <div className={styles.loginBelowContainer}>
                    <div />
                    <span className={styles.loginBelowText}><b>Or Login below</b></span>
                    <div />
                </div>
                <LogInForm />
            </div>
        </div>
    );
};
