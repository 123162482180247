import React, { useEffect, useState } from "react";
import { HttpClientResponse, lookUpCarByNumberPlate } from "../../api";
import { AddCarDto } from "../../models";
import { CarLookup } from "../../models/CarLookup";
import AppButton from "../AppButton";
import { AppCardButtons, AppCardContent, AppCardMessage } from "../AppCard";
import AppForm from "../AppForm";
import AppFormError from "../AppFormError";
import { AppInput } from "../AppInputs";
import { CountryCode, useLocalisation } from "../Localisation";
import CarLookupResult from "./CarLookupResult";
import CarModelSearchForm from "./CarModelSearchForm";

interface CarFormContainerProps {
  onSubmit: (car: AddCarDto) => void;
  isSubmitting?: boolean;
}

const CarFormContainer: React.FC<CarFormContainerProps> = ({
  onSubmit,
  isSubmitting,
}) => {
  const { country } = useLocalisation();
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [notMyCarClicked, setNotMyCarClicked] = useState(false);
  const [numberPlate, setNumberPlate] = useState("");
  const [numberplateValidationError, setNumberplateValidationError] =
    useState("");
  const [isSearchingForNumberPlate, setIsSearchingForNumberPlate] =
    useState(false);
  const [numberPlateSearchResponse, setNumberPlateSearchResponse] =
    useState<HttpClientResponse<CarLookup> | null>(null);
  const [selectedModel, setSelectedModel] = useState<CarLookup | null>(null);

  const forceManualInput = country === CountryCode.IE;

  const handleSearchForNumberPlate = async () => {
    setNumberplateValidationError("");
    setSelectedModel(null);

    if (numberPlate.length < 2) {
      setNumberplateValidationError(
        "Your numberplate must contain two characters or more."
      );
      return;
    }

    setNotMyCarClicked(false);
    setIsSearchingForNumberPlate(true);
    var response = await lookUpCarByNumberPlate(numberPlate);
    setNumberPlateSearchResponse(response);
    setIsSearchingForNumberPlate(false);
  };

  const handleSubmit = () => {
    setSubmitErrorMessage("");
    if (!forceManualInput) {
      if (
        !selectedModel &&
        (!numberPlateSearchResponse || numberPlateSearchResponse.isError)
      ) {
        setSubmitErrorMessage("Please select the model of your car.");
        return;
      }
    }

    if (numberPlate.length < 2) {
      setNumberplateValidationError(
        "Your numberplate must contain two characters or more."
      );
      return;
    }

    if (selectedModel) {
      onSubmit({
        ...selectedModel,
        registrationNumber: numberPlate,
      });
      return;
    }

    if (!numberPlateSearchResponse || numberPlateSearchResponse.isError) return;

    onSubmit({
      ...numberPlateSearchResponse.content,
      registrationNumber: numberPlate,
    });
  };

  const handleNotMyCar = () => {
    setNotMyCarClicked(true);
    setNumberPlateSearchResponse(null);
  };

  return (
    <>
      <AppCardContent>
        {!forceManualInput &&
          <AppCardMessage gutterBottom>
          Let's find your car make and model.
        </AppCardMessage>
        }
        <AppForm>
          {forceManualInput && (
            <AppInput
              placeholder="Your Reg"
              value={numberPlate}
              onChange={(event) => setNumberPlate(event.target.value)}
              errorMessage={numberplateValidationError}
            />
          )}
          {!forceManualInput && (
            <AppInput
              disabled={isSearchingForNumberPlate}
              placeholder="Your Reg"
              value={numberPlate}
              onChange={(event) => setNumberPlate(event.target.value)}
              onEnter={handleSearchForNumberPlate}
              showSearch
              errorMessage={numberplateValidationError}
            />
          )}
          {numberPlateSearchResponse && (
            <CarLookupResult carLookupResponse={numberPlateSearchResponse} />
          )}
          {(notMyCarClicked ||
            forceManualInput ||
            (numberPlateSearchResponse &&
              numberPlateSearchResponse.statusCode === 404)) && (
            <>
              <p style={{ textAlign: "center" }}>
                Let's try and find your car by make and model.
              </p>
              <CarModelSearchForm onSelect={setSelectedModel} />
            </>
          )}
        </AppForm>
        <AppFormError show={!!submitErrorMessage}>
          {submitErrorMessage}
        </AppFormError>
      </AppCardContent>
      {((numberPlateSearchResponse && !numberPlateSearchResponse.isError) ||
        selectedModel) && (
        <AppCardButtons>
          <AppButton
            disabled={isSubmitting}
            flexGrow={1}
            onClick={handleSubmit}
            variant="inverted"
          >
            {isSubmitting ? "Submitting..." : "Confirm"}
          </AppButton>
          {!(notMyCarClicked || numberPlateSearchResponse?.isError || forceManualInput) && (
            <AppButton flexGrow={1} onClick={handleNotMyCar}>
              Not my car
            </AppButton>
          )}
        </AppCardButtons>
      )}
    </>
  );
};

export default CarFormContainer;
