import React, { useState } from 'react';

import DropdownToggle from './DropdownToggle';
import DropdownMenu from './DropdownMenu';
import config from '../../config';

const AboutFresh = () => {
    const isTouchScreen = "ontouchstart" in document.documentElement;
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const renderToggle = () => (
        <DropdownToggle
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
        />
    );

    return (
        <>
            <li
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{ position: 'relative' }}
            >
                <a href={config.freshStaticSiteAbout}>
                    About Fresh
                </a>
                {renderToggle()}
                <DropdownMenu
                    isOpen={(!isTouchScreen && isHovered) || isOpen}
                    items={[
                        { label: 'Meet the Team', onClick: () => window.location.href = config.freshStaticSiteMeetTheTeam },
                        { label: 'Fresh Car Mobile Car Valeting Locations', onClick: () => window.location.href = config.freshStaticSiteLocations },
                        { label: 'Hope for Justice', onClick: () => window.location.href = config.freshStaticSiteHopeForJustice },
                        { label: 'Our Blog', onClick: () => window.location.href = config.freshStaticSiteBlog },
                    ]}
                />
            </li>
        </>
    );
}

export default AboutFresh;
