import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addCar } from '../../api';
import { CarForm } from '../../Components';
import AppCard, { AppCardsLayout, AppCardTitle } from '../../Components/AppCard';
import { AddCarDto } from '../../models';
import { pathBuilders } from '../../Routes';

export const AddCarPage = () => {
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (car: AddCarDto) => {
        setIsSubmitting(true);
        const result = await addCar(car);
        setIsSubmitting(false);

        if (!result.isError) {
            history.push(pathBuilders.accountDashboard());
        }
    }

    return (
        <AppCardsLayout>
            <AppCard>
                <AppCardTitle>Add Car</AppCardTitle>
                <CarForm
                    onSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                />
            </AppCard>
        </AppCardsLayout>
    )
}
