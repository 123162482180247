import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HttpClientResponse, makeBookingExistingCustomer } from '../../api';
import { ApiMakeBookingResponse } from '../../api/Models';
import { useAuth } from '../../auth';
import { Currency, useLocalisation } from '../../Components/Localisation';
import { trackBooking } from '../../marketingHelpers';
import { BookingTimeSlot } from '../../models';
import { formatPriceWithCurrencySymbol } from "../../priceHelpers";
import { actions, aggregateSelectors, selectors } from '../../store';
import ChooseDateForm, { ChooseDateFormValues } from './ChooseDateForm';
import { makeBookingPathBuilders } from './makeBookingPaths';

export interface ChooseDateFormContainerProps {

}

const ChooseDateFormContainer: React.FC<ChooseDateFormContainerProps> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useAuth();
    const cars = useSelector(selectors.customerCars.all);
    const postcodeType = useSelector(selectors.makeBooking.selectPostcodeType);
    const bookingDetails = useSelector(selectors.makeBooking.selectBookingDetails);
    const makeBookingDto = useSelector(selectors.makeBooking.selectMakeBookingExistingCustomerDto);
    const { currency, country } = useLocalisation();

    const totalPriceSummary = useSelector(
      aggregateSelectors.selectTotalBookingPriceSummary(country)
    );

    const setBookingDetails = (bookingDetails: ChooseDateFormValues) => {
        dispatch(actions.makeBooking.setBookingDetails({
            date: bookingDetails.date.toISOString(),
            additionalComments: bookingDetails.additionalComments,
            timeSlot: bookingDetails.timeSlot as BookingTimeSlot,
            automatedSlot: bookingDetails.automatedSlot ? bookingDetails.automatedSlot : null,
            paymentOption: bookingDetails.paymentOption,
        }));
    };

    const handleSubmitBooking = async (formValues: ChooseDateFormValues): Promise<HttpClientResponse<ApiMakeBookingResponse>> => {
        setBookingDetails(formValues);

        const formattedPriceInEuros = currency.currency !== Currency.EUR ?
            null :
            formatPriceWithCurrencySymbol(
                totalPriceSummary.grandTotal,
                currency.symbol);

        const additionalComments = currency.currency === Currency.EUR ?
            makeBookingDto.booking.additionalComments + `\n\nPrice displayed in euros: ${formattedPriceInEuros}` :
            makeBookingDto.booking.additionalComments ;

        if (auth.isLoggedIn && formValues.paymentOption === 'isPayLater') {

            const bookingDto = {
                ...makeBookingDto,
                booking: {
                    ...makeBookingDto.booking,
                    additionalComments,
                    currency: currency.currency,
                    bookingCustomerCars: makeBookingDto.booking.bookingCustomerCars.map(customerCar => {
                        const carDetails = cars.find(car => car.id === customerCar.customerCarId);
                        customerCar.category = carDetails?.category;
                        return customerCar;
                    })
                }
            };

            const response = await makeBookingExistingCustomer(bookingDto);
            if (response.isError) return response;
            dispatch(actions.makeBooking.setCreatedBookingStatusId(response.content.bookingStatusId));
            trackBooking('Booking');
            return response;
        }

        return {
            isError: false,
            content: {
                bookingStatusId: -1,
                paymentSessionId: '',
            },
            statusCode: 0,
        }
    };

    const handleSuccess = () => {
        const path = auth.isLoggedIn ?
            bookingDetails.paymentOption === 'isPayLater' ?
                makeBookingPathBuilders.enquirySent() :
                makeBookingPathBuilders.makePayment()
            :
            makeBookingPathBuilders.completeFirstTimeBooking();

        history.push(path);
    }

    return (
        <ChooseDateForm
            initialValues={{
                postcodeType: postcodeType ? postcodeType : 'Secondary',
                date: new Date(bookingDetails.date),
                additionalComments: bookingDetails.additionalComments,
                timeSlot: bookingDetails.timeSlot,
                automatedSlot: bookingDetails.automatedSlot ? bookingDetails.automatedSlot : '',
                paymentOption: auth.isLoggedIn && postcodeType === 'Primary' ? 'isPayNow' : 'isPayLater'
            }}
            onSuccess={handleSuccess}
            onSubmit={handleSubmitBooking}
            willUnmount={setBookingDetails}
        />
    );
}

export default ChooseDateFormContainer;