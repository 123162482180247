import React, { useEffect, useState, useCallback } from "react";
import { Form, FormikProps, useField, withFormik } from "formik";
import * as Yup from "yup";
import {
  AppButton,
  AppCalendar,
  AppCard,
  AppCardButtons,
  AppCardContent,
  AppCardsLayout,
  AppCardTitle,
  ApiErrorLoaderWrapper,
} from "../../Components";
import AppForm from "../../Components/AppForm";
import { AppFormikSelect, AppInput } from "../../Components/AppInputs";
import AppFormikTextArea from "../../Components/AppInputs/AppFormikTextArea";
import {
  actions,
  selectApiAutomatedSlotLookup,
  selectors,
  useDispatchEffect,
} from "../../store";
import {
  getAllPostcodeCoveringValeters,
  HttpClientFailureResponse,
  HttpClientResponse,
  queryAutomatedSlots,
} from "../../api";
import AppFormError from "../../Components/AppFormError";
import { formatDateDateOnly, formatTimeOnly } from "../../dateFormatter";
import { AutomatedSlotLookup, BookingTimeSlot } from "../../models";
import { isAfterTime, isToday } from "../../dateHelpers";
import { useSelector } from "react-redux";
import { ApiMakeBookingResponse } from "../../api/Models";
import { useAuth } from "../../auth";
import AppFormikRadioGroup from "../../Components/AppInputs/AppFormikRadioGroup";
import { Valeter } from "../../models/PreferredValeters";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppFormikValetSelect, {
  AppFormikValetSelectOption,
} from "../../Components/AppInputs/AppFormikValetSelect";
import styles from "./ChooseDateForm.module.css";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { pathBuilders } from "../../Routes";
import { CountryCode, useLocalisation } from "../../Components/Localisation";
import { BookingTimeSlotPicker } from "../../Components/BookingTimeSlotPicker";

const morningCutoff = 12; // morning slot for today can only be booked until this time.
const afternoonCutoff = 16; // afternoon slot for today can only be booked until this time.

export interface ChooseDateFormValues {
  postcodeType: string;
  date: Date;
  timeSlot?: BookingTimeSlot | "";
  automatedSlot?: string;
  additionalComments: string;
  paymentOption: string;
}

export interface OtherProps {
  willUnmount: (values: ChooseDateFormValues) => void;
}

const InnerChooseDateForm: React.FC<
  OtherProps & FormikProps<ChooseDateFormValues>
> = ({ isSubmitting, status, willUnmount, values }) => {
  const auth = useAuth();
  const { country } = useLocalisation();
  useEffect(() => () => willUnmount(values));
  useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(2));
  useDispatchEffect(actions.preferredValeters.fetchAllIfRequired);
  const postcode = useSelector(selectors.makeBooking.selectSelectedPostcode);
  const postcodeType = useSelector(selectors.makeBooking.selectPostcodeType);
  const carPackageIds = useSelector(selectors.makeBooking.selectCarPackageIds);
  const [loading, setLoading] = useState(false);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [apiErrorSlots, setApiErrorSlots] = useState<
    HttpClientFailureResponse | undefined
  >();
  const [availableSlots, setAvailableSlots] = useState<AutomatedSlotLookup[]>(
    []
  );
  const [dateFieldProps, , dateFieldHelpers] = useField<Date>("date");
  const [, , timeSlotFieldHelpers] = useField<string>("timeSlot");
  const [automatedTimeSlotFieldProps, , automatedTimeSlotFieldHelpers] =
    useField<string>("automatedSlot");
  const [valeterIdFieldProps, ,] = useField<number>("valeter");
  const [selectedDate, setSelectedDate] = useState<Date>(values.date);
  const automatedSlotLookup = useSelector(selectApiAutomatedSlotLookup);
  const { isLoading, apiError } = useSelector(
    selectors.preferredValeter.apiState
  );
  const preferredValeters = useSelector(selectors.preferredValeter.all);
  const [postcodeCoveringValeters, setPostcodeCoveringValeters] = useState<
    Valeter[]
  >([]);

  const forceAfternoonTimeslotIfRequired = (newDate: Date) => {
    if (isToday(newDate)) {
      if (isAfterTime(morningCutoff)) {
        timeSlotFieldHelpers.setValue("afternoon");
      }
    }
  };

  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
    forceAfternoonTimeslotIfRequired(newDate);
    newDate.setHours(12);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    dateFieldHelpers.setValue(newDate);
  };

  const handleCalendarClear = () => {
    setAvailableSlots([]);
    automatedTimeSlotFieldHelpers.setValue("");
  };

  const getAvailableAutomatedSlots = useCallback(
    async (date: Date = selectedDate) => {
      setSlotsLoading(true);
      setApiErrorSlots(undefined);
      handleCalendarClear();
      if (
        postcodeType === "Primary" &&
        automatedSlotLookup.carBookings.length !== 0
      ) {
        const availableSlots = await queryAutomatedSlots({
          ...automatedSlotLookup,
          postcode,
          // Below is hack to handle the fact that MBH doesn't behave correctly when you pass a UTC date.
          // New MBH should not require this.
          resourceId:
            valeterIdFieldProps.value > 0
              ? valeterIdFieldProps.value
              : undefined,
          date: date.toISOString().replace("Z", ""),
        });

        if (availableSlots.isError) {
          setAvailableSlots([]);
          setApiErrorSlots(availableSlots);
        } else {
          setAvailableSlots(availableSlots.content);
        }
      }

      setSlotsLoading(false);
    },
    [
      selectedDate,
      automatedSlotLookup,
      postcode,
      postcodeType,
      valeterIdFieldProps.value,
    ]
  );

  const getAvailableValeters = useCallback(async () => {
    setLoading(true);

    let packageIds = [];

    for(const carId in carPackageIds) {
      packageIds.push(carPackageIds[carId]);
    }

    const availableSlots = await getAllPostcodeCoveringValeters(postcode, packageIds);

    if (availableSlots.isError) {
      setPostcodeCoveringValeters([]);
      setApiErrorSlots(availableSlots);
    } else {
      setPostcodeCoveringValeters(availableSlots.content);
    }

    setLoading(false);
  }, [selectedDate, automatedSlotLookup, postcode, postcodeType]);

  const minDate = new Date();
  if (isAfterTime(afternoonCutoff)) {
    minDate.setDate(minDate.getDate() + 1);
  }

  useEffect(() => {
    getAvailableAutomatedSlots();
  }, [getAvailableAutomatedSlots]);

  useEffect(() => {
    if (postcodeType === "Primary") {
      getAvailableValeters();
    }
  }, [getAvailableValeters]);

  const slotPickerPlaceHolder = () => {
    if (slotsLoading || loading) {
      return "Loading...";
    } else if (availableSlots.length === 0) {
      return "No time slots available for this date";
    } else {
      return "Please select an available timeslot";
    }
  };

  const slotPicker =
    postcodeType !== "Primary" ? (
      <AppFormikSelect
        name="timeSlot"
        label="Preferred Timeslot"
        placeholder={
          loading ? "Loading..." : "Please select your preferred timeslot"
        }
        options={[
          { value: "morning", label: "Morning" },
          { value: "afternoon", label: "Afternoon" },
        ]}
        disabled={isToday(dateFieldProps.value) && isAfterTime(morningCutoff)}
      />
    ) : (
      <BookingTimeSlotPicker
        disabled={availableSlots.length === 0}
        name="automatedSlot"
        label="Timeslot"
        placeholder={slotPickerPlaceHolder()}
        availableSlots={availableSlots.map((slot: AutomatedSlotLookup) => ({
          value: JSON.stringify({
            slotStartTime: slot.slotStartTime,
            resourceId: slot.resourceId,
          }),
          label: formatTimeOnly(new Date(slot.slotStartTime)),
          isEcoSlot: slot.isEcoSlot,
        }))}
      />
    );

  const paymentOptionRadio =
    postcodeType === "Primary" && auth.isLoggedIn ? (
      <AppFormikRadioGroup
        name="paymentOption"
        label="Payment Option"
        placeholder="Please select your payment option"
        options={[
          {
            value: "isPayNow",
            label: "Pay now",
            description: "For a smoother experience, pay now.",
          },
          {
            value: "isPayLater",
            label: "Pay later",
            description: "Pay on the day.",
          },
        ]}
      />
    ) : null;

  const valeterList = () => {
    const valeterList: Valeter[] = postcodeCoveringValeters.map(
      (valeter: Valeter) => ({
        id: valeter.id,
        name: valeter.name,
        isPreferred: preferredValeters
          .map((valeter) => valeter.resourceId)
          .includes(valeter.id)
          ? true
          : false,
      })
    );
    valeterList.forEach((valet, index) => {
      if (valet.isPreferred === true) {
        valeterList.splice(index, 1);
        valeterList.unshift(valet);
      }
    });
    valeterList.unshift({
      id: -1,
      name: "Any",
      isPreferred: false,
    });
    return valeterList;
  };

  const valeterPicker = (
    <AppFormikValetSelect
      name="valeter"
      label="Valeter"
      placeholder="Please select"
      options={valeterList().map((valeter: Valeter) => ({
        value: valeter.id,
        label: valeter.name,
        extraProperties: { isPreferredValeter: valeter.isPreferred },
      }))}
      optionFormat={(option: AppFormikValetSelectOption) => (
        <div style={{ display: "flex" }}>
          {option.extraProperties.isPreferredValeter ? (
            <FontAwesomeIcon icon={faStar} className={styles.pvIcon} />
          ) : (
            <div className={styles.pvIconSpace} />
          )}

          <div>{option.label}</div>
        </div>
      )}
    />
  );

  const bookingMessage =
    postcodeType === "Primary"
      ? "Please note this is a confirmed booking"
      : "Please note this is an enquiry and not a confirmed booking";

  const pleaseSelectValeterMessage = (
    <>
      <p className={styles.emptyValeter}>Please select a valeter</p>
    </>
  );

  
  document.documentElement.style.setProperty('--columnsOnTablet','1');

  return (
    <AppCardsLayout>
      <AppCard>
        <AppCardTitle subtitle={bookingMessage}>Choose A Date</AppCardTitle>
        <Form>
          <AppCardContent style={{ paddingBottom: "1rem" }}>
            <AppForm>
              {country !== CountryCode.IE &&
                <ApiErrorLoaderWrapper isLoading={isLoading} httpError={apiError}>
                {valeterPicker}
              </ApiErrorLoaderWrapper>
              }
              <AppCalendar
                className={styles.calendar}
                value={dateFieldProps.value}
                onChange={handleDateChange}
                minDate={minDate}
              />
              <AppInput
                disabled
                value={formatDateDateOnly(dateFieldProps.value)}
                label={postcodeType !== "Primary" ? "Preferred Date" : "Date"}
              />
              <ApiErrorLoaderWrapper
                isLoading={false}
                httpError={apiErrorSlots}
              >
                {slotPicker}
              </ApiErrorLoaderWrapper>
              <AppFormikTextArea
                name="additionalComments"
                label="Notes to Your Valeter"
                placeholder="Please add any notes here that could help your valeter such as which buzzer to press or how best to reach you on arrival."
              />
              {paymentOptionRadio}
            </AppForm>
          </AppCardContent>
          <AppCardButtons>
            <Typography variant="body2" className={styles.termsAndConditions}>
              Please note that by placing this booking you agree to our{" "}
              <Link to={pathBuilders.termsAndConditions()} target="_blank">
                terms and conditions
              </Link> including cancellation fees
            </Typography>
            <AppButton type="submit" variant="inverted" disabled={isSubmitting}>
              {isSubmitting
                ? "Submitting..."
                : values.paymentOption === "isPayLater"
                ? "Book now"
                : "Make payment"}
            </AppButton>
          </AppCardButtons>
          <AppFormError show={status}>
            Sorry there was a problem submitting your data.
          </AppFormError>
        </Form>
      </AppCard>
    </AppCardsLayout>
  );
};

interface ChooseDateFormProps {
  initialValues: ChooseDateFormValues;
  onSubmit: (
    data: ChooseDateFormValues
  ) => Promise<HttpClientResponse<ApiMakeBookingResponse>>;
  onSuccess: () => void;
  willUnmount: (values: ChooseDateFormValues) => void;
}

const ValidationSchema = Yup.object().shape<ChooseDateFormValues>({
  postcodeType: Yup.string()
    .oneOf(["Primary", "Secondary"], "required")
    .required(),
  date: Yup.date().required("You must select a date."),
  timeSlot: Yup.string()
    .oneOf(["morning", "afternoon"], "required")
    .when("postcodeType", (postcodeType: string, schema: Yup.StringSchema) => {
      return postcodeType === "Secondary"
        ? schema.required("Please select a time slot")
        : schema.notRequired();
    }),
  additionalComments: Yup.string(),
  automatedSlot: Yup.string().when(
    "postcodeType",
    (postcodeType: string, schema: Yup.StringSchema) => {
      return postcodeType === "Primary"
        ? schema.required("Please select a time slot")
        : schema.notRequired();
    }
  ),
  paymentOption: Yup.string().required(),
});

const ChooseDateForm = withFormik<ChooseDateFormProps, ChooseDateFormValues>({
  mapPropsToValues: (props) => props.initialValues,
  validationSchema: ValidationSchema,
  handleSubmit: async (
    values,
    { setStatus, props: { onSubmit, onSuccess } }
  ) => {
    if (values.automatedSlot || values.timeSlot) {
      const response = await onSubmit(values);

      if (response.isError) {
        setStatus(true);
        return;
      }

      if (
        values.postcodeType !== "Primary" ||
        values.paymentOption !== "isPayNow"
      ) {
        onSuccess();
        return;
      }

      if (values.paymentOption === "isPayNow") {
        onSuccess();
        return;
      }

      setStatus(true);
    } else {
      setStatus(true);
      return;
    }
  },
})(InnerChooseDateForm);

export default ChooseDateForm;
