import React from 'react';
import classnames from 'classnames';

import styles from './DropdownToggle.module.css';

interface DropdownToggleProps {
    isOpen: boolean,
    onClick: () => void,
    disabled?: boolean;
}

const DropdownToggle: React.FC<DropdownToggleProps> = ({
    isOpen,
    onClick,
    disabled
}) => {
    return (
        <button
            className={styles.root}
            onClick={onClick}
            disabled={disabled}
        >
            <span
                className={classnames({
                    [styles.open]: isOpen,
                })}
            />
        </button>
    );
}

export default DropdownToggle;
