import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocalisation } from "../../Components/Localisation";
import { Car } from "../../models";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";
import { aggregateSelectors, selectors } from "../../store";
import RegisterAndBookPriceSummary from "./RegisterAndBookPriceSummary";
import styles from "./RegisterAndBookPriceSummary.module.css";
import ViewCarsCard from "./ViewCarsCard";

interface RegisterAndBookPriceSummaryPropsContainer {
  selectedCars: Car[];
}

const RegisterAndBookPriceSummaryContainer: React.FC<
  RegisterAndBookPriceSummaryPropsContainer
> = ({ selectedCars }) => {
  const { country, currency } = useLocalisation();
  const bookingPriceSummary = useSelector(
    aggregateSelectors.selectTotalBookingPriceSummary(country)
  );

  const totalCostPriceToDisplay = formatPriceWithCurrencySymbol(
    bookingPriceSummary.grandTotal,
    currency.symbol
  );

  return (
    <div className={styles.root}>
      {selectedCars.map((car) => {
        return car && <RegisterAndBookPriceSummary key={car.id} car={car} />;
      })}
      <h2 className={styles.grandTotal}>
        Total Cost: <span>{totalCostPriceToDisplay}</span>
      </h2>
    </div>
  );
};

export default RegisterAndBookPriceSummaryContainer;
