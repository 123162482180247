import React from 'react';
import classnames from 'classnames';

import styles from './AppCard.module.css';
 
interface AppCardMessageProps {
    variant?: 'inverted'|'error',
    header?: string,
    gutterBottom?: boolean,
}

const AppCardMessage: React.FC<AppCardMessageProps> = ({
    variant,
    header,
    gutterBottom,
    children
}) => {
    return (
        <div className={classnames({
            [styles.message]: true,
            [styles.inverted]: variant === 'inverted',
            [styles.error]: variant === 'error',
            [styles.gutterBottom]: gutterBottom,
        })}>
            {header && <h4>{header}</h4>}
            <p>{children}</p>
        </div>
    );
}
 
export default AppCardMessage;