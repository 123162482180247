import React, { useEffect, useState } from 'react';

import authClient from './authClient';
import { AuthContext } from './AuthContext';
import { UpdatePasswordDto } from './models/UpdatePassword';
import { UserConsentDto } from './models/UpdateUserConsent';
import { UpdateUserDetailsDto } from './models/UpdateUserDetails';
import { AccountInformation } from './sessionStorageClient';

type AuthProviderProps = {
    children: React.ReactNode,
};

const AuthProvider: React.FC<AuthProviderProps> = ({
    children,
}) => {
    const [displayName, setDisplayName] = useState<string|null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const sessionState = authClient.loadSession();
        if (sessionState.isLoggedIn) {
            setDisplayName(sessionState.userName || null);
            setIsLoggedIn(true);
        }
    }, [setDisplayName])

    const handleRegister = async (email: string, password: string) => {
        const authResult = await authClient.register(email, password, password);

        if (!authResult.isError) {
            setDisplayName(authResult.displayName);
            setIsLoggedIn(true);
        }
        return authResult;
    }

    const handleLogIn = async (email: string, password: string) => {
        const authResult = await authClient.logIn(email, password);

        if (!authResult.isError) {
            setDisplayName(authResult.displayName);
            setIsLoggedIn(true);
        }

        return authResult;
    };

    const handleSetLogInformation = (accountInformation: AccountInformation, token: string) => {
        authClient.setLogInInformation(accountInformation, token);
        setDisplayName(accountInformation.displayName);
        setIsLoggedIn(true);
    }

    const handleLogOut = async () => {
        const authResult = await authClient.logOut();
        if (!authResult.isError) {
            setDisplayName('');
            setIsLoggedIn(false);
        }
        return authResult;
    };

    const handleUpdateUserDetails = async (newDetails: UpdateUserDetailsDto) => {
        const authResult = await authClient.updateUserDetails(newDetails);

        if (!authResult.isError) {
            setDisplayName(authResult.displayName);
        }
    };

    const handleUpdateUserConsent = async (newDetails: UserConsentDto) => {
        const authResult = await authClient.updateUserConsent(newDetails);

        if (!authResult.isError) {
            setDisplayName(authResult.displayName);
        }
    }

    const handleUpdatePassword = async (newDetails: UpdatePasswordDto) => {
        const authResult = await authClient.updatePassword(newDetails);

        if (!authResult.isError) {
            setDisplayName(authResult.displayName);
        }
        return authResult;
    }

    const handleGetAccountInformation = () => {
        var account = authClient.getAccountInformation();

        if (!account) {
            throw new Error('AuthProvider: Expected account information to exist.');
        }

        return account;
    }

    return (
        <AuthContext.Provider value={{
            register: handleRegister,
            logIn: handleLogIn,
            setLogInInformation: handleSetLogInformation,
            logOut: handleLogOut,
            userName: displayName,
            isLoggedIn,
            getToken: authClient.getToken,
            getAccountInformation: handleGetAccountInformation,
            getFullAccountInformation: authClient.getFullAccountInformation,
            updateUserDetails: handleUpdateUserDetails,
            updateUserConsent: handleUpdateUserConsent,
            updatePassword: handleUpdatePassword,
            forgotPassword: authClient.forgotPassword,
            resetPassword: authClient.resetPassword,
        }}>
            {children}
        </AuthContext.Provider>
    );
};
 
export default AuthProvider;