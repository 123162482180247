import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AppButton, AppCard, AppCardButtons, AppCardContent, AppCardTitle, AppForm, AppFormikInput } from '../../Components';

export interface UpdatePasswordFormValues {
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
}

interface OtherProps {
    onCancel: () => void,
}

const InnerUpdatePasswordCard: React.FC<OtherProps & FormikProps<UpdatePasswordFormValues>> = ({
    isSubmitting,
    onCancel,
    resetForm
}) => {
    return (
        <AppCard maxFullScreenWidth="50%">
            <AppCardTitle size="small">Update Password</AppCardTitle>
            <Form>
                <AppCardContent>
                    <AppForm>
                        <AppFormikInput
                            type="password"
                            label="Current Password"
                            placeholder="Current Password"
                            name="oldPassword"
                        />
                        <AppFormikInput
                            type="password"
                            label="New Password"
                            placeholder="New Password"
                            name="newPassword"
                        />
                        <AppFormikInput
                            type="password"
                            label="Confirm New Password"
                            placeholder="Confirm New Password"
                            name="confirmNewPassword"
                        />
                    </AppForm>
                </AppCardContent>
                <AppCardButtons>
                    <AppButton onClick={onCancel}>Cancel</AppButton>
                    <AppButton disabled={isSubmitting} type="submit" variant="inverted">Save Details</AppButton>
                </AppCardButtons>
            </Form>
        </AppCard>
    );
}

interface UpdatePasswordCardProps {
    onCancel: () => void,
    onSubmit: (values: UpdatePasswordFormValues) => Promise<boolean>,
}

const UpdatePasswordFormValidationSchema = Yup.object().shape<UpdatePasswordFormValues>({
    oldPassword: Yup.string().required('Required.'),
    newPassword: Yup.string()
        .min(6, 'Must be at least 6 characters.')
        .matches(/[^a-zA-Z\d\s]/, 'Must contain at least one character that isn\'t a number or a letter')
        .matches(/.*[0-9].*/, 'Must contain at least one digit.')
        .matches(/.*[a-z].*/, 'Must contain at least one lower case letter.')
        .matches(/.*[A-Z].*/, 'Must contain at least one upper case letter.')
        .required('Required.'),
    confirmNewPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match.'),
});

const UpdatePasswordCard = withFormik<UpdatePasswordCardProps, UpdatePasswordFormValues>({
    mapPropsToValues: () => ({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    }),
    validationSchema: UpdatePasswordFormValidationSchema,
    handleSubmit: async (values, { props: { onSubmit }, resetForm }) => {
       const result = await onSubmit(values);
        if(result != true){resetForm()}
    }
})(InnerUpdatePasswordCard);

export default UpdatePasswordCard;
