import { useMediaQuery } from 'react-responsive';

export const useIsMobileMedia = () => {
    return useMediaQuery({
        query: '(max-width: 768px)',
    });

}

export const useIsTabletMedia = () => {
    return useMediaQuery({
        query: '(min-width: 768px) and (max-width: 899px)',
    });

}