const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const prependZero = (number: number): string => {
    if(number < 10) {
      return `0${number}`;
    } else {
      return number.toString();
    }
  }

  const formatTo12hrClock = (number: number): number => {
    if(number > 12) {
      return (number - 12);
    } else {
      return number;
    }
  }

  export const formatDateStringDateOnly = (dateString: string): string => {
      try {
        const date = new Date(dateString);
        
        return formatDateDateOnly(date);
      }
      catch (error) {
          console.error('Error formatting date from string:', error);
          throw error;
      }
  }

  export const formatDateStringDateTime = (dateString: string): string => {
      try {
        const date = new Date(dateString);
        
        return formatDateDateTime(date);
      }
      catch (error) {
          console.error('Error formatting date time from string:', error);
          throw error;
      }
  }

  export const formatDateDateTime = (date: Date): string => {
    try {
        return `${formatDateDateOnly(date)} ${formatTimeOnly(date)}`;
      }
      catch (error) {
          console.error('Error formatting date time from date:', error);
          throw error;
      }
  }

  export const formatDateDateOnly = (date: Date): string => {
    try {
        const monthString = months[date.getMonth()];
        return `${date.getDate()}-${monthString.substring(0, 3)}-${date.getFullYear()}`;
      }
      catch (error) {
          console.error('Error formatting date from date:', error);
          throw error;
      }
  }

  export const formatTimeOnly = (date: Date): string => {
    try {
      const hour = formatTo12hrClock(date.getHours()),
            minute = prependZero(date.getMinutes()),
            hourForPeriod = prependZero(date.getHours()),
            period = parseInt(hourForPeriod) >= 12 ? 'pm' : 'am';

      return `${hour}:${minute}${period}`;
    } catch (error) {
      console.log('Error formatting time from date: ', error);
      throw error;
    }
  }

  // Use in places where you can only show one value for the date i.e. you have to combine requested, confirmed and time of day.
  export const formatUnifiedDateTime = (requestedDate: string, confirmedDate?: string, timeOfDay?: string) => {
    const formattedDate = confirmedDate ? formatDateStringDateTime(confirmedDate) : // If confirmed then we have exact date time.
        timeOfDay ? formatDateStringDateOnly(requestedDate) : // Case where morning / afternoon was selected so time in requested date is meaningless.
        formatDateStringDateTime(requestedDate);
    const showTimeOfDay = timeOfDay && !confirmedDate;

    return formattedDate + (showTimeOfDay ? ` ${timeOfDay}` : '');
  }