import React, { useState } from 'react';
import { faKey, faUserEdit, faUserSlash }  from '@fortawesome/free-solid-svg-icons';

import { pathBuilders } from '../../Routes';
import { useAuth } from '../../auth';
import styles from './Account.module.css';

import DropdownMenu, { DropdownMenuItem } from './DropdownMenu';
import { useHistory } from 'react-router-dom';

interface AccountProps {
    closeMenu: () => void,
}

const Account: React.FC<AccountProps> = ({
    closeMenu,
}) => {
    const isTouchScreen = "ontouchstart" in document.documentElement;
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const auth = useAuth();
    const history = useHistory();

    const loggedOutMenuItems: DropdownMenuItem[] = [
        {
            label: 'Log In',
            onClick: () => {
                setIsOpen(false);
                closeMenu();
                history.push(pathBuilders.logIn());
            },
            icon: faUserEdit,
        },
    ];
    
    const loggedInMenuItems: DropdownMenuItem[] = [
        {
            label: 'Log Out',
            onClick: () => {
                auth.logOut();
                setIsOpen(false);
                closeMenu();
                history.push(pathBuilders.logIn());
            },
            icon: faUserSlash,
        },
        {
            label: 'Account',
            onClick: () => {
                setIsOpen(false);
                closeMenu();
                history.push(pathBuilders.accountDashboard());
            },
            icon: faKey,
        },
        {
            label: 'Personal Details',
            onClick: () => {
                setIsOpen(false);
                closeMenu();
                history.push(pathBuilders.accountManagement());
            },
            icon: faUserEdit,
        }
    ];

    const menuItems = auth.isLoggedIn ? loggedInMenuItems : loggedOutMenuItems;

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <li
            className={styles.root}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ position: 'relative' }}
        >
            <button onClick={handleClick}>
                {auth.isLoggedIn ? auth.userName : 'Account'}
            </button>
            <DropdownMenu
                isOpen={(!isTouchScreen && isHovered) || isOpen}
                items={menuItems}
                inverted
            />
        </li>
    );
}

export default Account;