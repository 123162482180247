import React from "react";
import { ServiceType } from "../../models";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeBookingPathBuilders, useCarId } from ".";
import {
  ApiErrorLoaderWrapper,
  AppButton,
  AppCard,
  AppCardButtons,
  AppCardContent,
} from "../../Components";
import { selectors } from "../../store";
import PackageSelectTitle from "./PackageSelectTitle";

import styles from "./SelectServiceType.module.css";

interface ServiceTypeCardProps {
  serviceType: ServiceType;
}

interface CustomServiceTypeCardProps {
  name: string;
  description: string;
  imageSrc: string;
  href: string;
}

const CustomServiceTypeCard = ({
  name,
  description,
  imageSrc,
  href,
}: CustomServiceTypeCardProps) => (
  <AppCard maxFullScreenWidth="100%" fullScreenWidth="100%">
    <img className={styles.image} src={imageSrc} alt={name} />
    <AppCardContent>
      <div className={styles.cardContent}>
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </AppCardContent>
    <AppCardButtons>
      <AppButton
        variant="inverted"
        fullWidth
        onClick={() => window.open(href, "_blank")}
      >
        Get a quote
      </AppButton>
    </AppCardButtons>
  </AppCard>
);

const ServiceTypeCard: React.FC<ServiceTypeCardProps> = ({
  serviceType: { id, title, description, imageUrl },
}) => {
  const history = useHistory();
  const carId = useCarId();
  const packageCategories = useSelector(
    selectors.packageInfo.makeSelectPackageCategoriesByServiceTypeId(id)
  );

  // below is used to skip car package category selection if there is only 1 car package category
  const onServiceCardButtonClick = () =>
    packageCategories.length === 1
      ? history.push(
          makeBookingPathBuilders.carPackages(
            carId,
            id,
            packageCategories[0].packageGroupCategoryId
          )
        )
      : history.push(makeBookingPathBuilders.carPackageCategories(carId, id));

  return (
    <AppCard maxFullScreenWidth="100%" fullScreenWidth="100%">
      <img className={styles.image} src={imageUrl} alt={title} />
      <AppCardContent>
        <div className={styles.cardContent}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </AppCardContent>
      <AppCardButtons>
        <AppButton
          variant="inverted"
          fullWidth
          onClick={onServiceCardButtonClick}
        >
          View Packages
        </AppButton>
      </AppCardButtons>
    </AppCard>
  );
};

const SelectServiceType: React.FC = () => {
  const packageInfoApi = useSelector(selectors.packageInfo.apiState);
  const serviceTypes = useSelector(selectors.packageInfo.selectAllServiceTypes);

  return (
    <ApiErrorLoaderWrapper
      isLoading={packageInfoApi.isLoading}
      httpError={packageInfoApi.apiError}
    >
      <PackageSelectTitle message="Different services on offer for your car" />
      <div className={styles.cardContainer}>
        {serviceTypes.map((s) => (
          <ServiceTypeCard serviceType={s} key={s.id} />
        ))}
        <CustomServiceTypeCard
          name="Paintwork Repairs"
          description="SMART repairs &#38; touch-ups including repair of scuffs, scratches, and minor dents."
          imageSrc="https://freshbookingclientassets.blob.core.windows.net/bookingclientassets/SMART_Repair.jpeg"
          href={process.env.REACT_APP_FRESH_STATIC_SITE_SMART_REPAIR_URL || ""}
        />
        <CustomServiceTypeCard
          name="Mechanics"
          description="A full range of mobile mechanics including Servicing, tyres, and repairs."
          imageSrc="https://freshbookingclientassets.blob.core.windows.net/bookingclientassets/ClickMechanic.jpeg"
          href={process.env.REACT_APP_FRESH_STATIC_SITE_MECHANICS_URL || ""}
        />
      </div>
    </ApiErrorLoaderWrapper>
  );
};

export default SelectServiceType;
