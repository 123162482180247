import React from 'react';
import classnames from 'classnames';

import styles from './AppInput.module.css';

export interface AppInputLabelProps {
    required?: boolean,
}
 
const AppInputLabel: React.FC<AppInputLabelProps> = ({
    children,
    required,
}) => {
    if (!children) return null;
    
    return (
        <label
            className={classnames({
                [styles.label]: true,
                [styles.required]: required,
            })}
        >
            {children}
        </label>
    );
}
 
export default AppInputLabel;