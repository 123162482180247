import React from 'react';

import styles from './TermsAndConditions.module.css';

export const CarClubTermsAndConditions: React.FC = () => {
    return (
        <div className={styles.content}>
            <h1 className={styles.title}>Car Club Terms and Conditions</h1>
            <p>Once a subscription has been set up, you are bound under these terms & conditions.</p>
            <h3><b>FREQUENCY OF APPOINTMENTS</b></h3>
            <ol>
            <li>The Car Club is designed to maintain your car to a good standard of cleanliness. As such, it allows you to make appointments, for the specific car on file, every 1 to 8 weeks.</li>
            <li>The membership is car, location and valeter specific - as such, you are unable to swap between different cars of the same household.</li>
            <li>You can have more than one membership if you have more than one car to be valeted.</li>
            <li>You can change your desired location for the car club appointment but only within the postcodes covered by your usual preferred valeter.</li>
            </ol>
            <h3><b>YOUR DUTIES AS PART OF THE CAR CLUB</b></h3>
            <ol>
            <li>It is your responsibility to make your own appointments ; and cancel them, should the situation arise.</li>
            <li>Payment from the card on file will be taken on a regular basis, mirroring the frequency of your appointments - you should be vigilant that payment was successful to be able to make new appointments.</li>
            <li>Should you wish to pause or cancel your membership, it is your responsibility to ensure that this is done in a timely fashion before a charge is made.</li>
            </ol>
            <h3><b>SUBSCRIPTION MANAGEMENT</b></h3>
            <ol>
            <li>Over the course of your subscription, 'car club cleans' will be acquired depending on the frequency you have selected.</li>
            <li>You can pause your membership yourself, at any time, on the app. Any car club cleans can still be used as normal within your membership.</li>
            <li>You can pause your membership for one month per six months period. Payments will be halted whilst your membership is paused.</li>
            <li>You can cancel your membership yourself, at any time, on the app. Any outstanding club cleans can still be redeemed as credit against future bookings but can not be refunded.</li>
            </ol>
            <h3><b>CANCELLATIONS / NO SHOWS</b></h3>
            <ol>
            <li>All cancellations and amendments must be made with at least 24 hours notice of an appointment you have made.</li>
            <li>In the event that you cancel your booking with less than 24 hours notice, cancellation fees will be charged as below:</li>
            <ol className={styles.numbered}>
            <li>Job cancelled after our valeter has arrived at the location, or within one working hour of the specified time: 100% of the agreed price</li>
            <li>Within 12 working hours of the appointment start time: 50% of the agreed price with a minimum of £20</li>
            <li>Within 24 working hours of the appointment start time: 25% of the agreed price with a minimum of £20</li>
            </ol>
            <li>All cancellation fees must be settled before you may resume use of your subscription</li>
            </ol>
            <h3><b>PRICING</b></h3>
            <ol>
            <li>Your Car Club membership quote depends on the type of membership selected, the frequency of appointments, and the size of your car.</li>
            <li>If Car Club rates were to change, we will give you at least 4 weeks notice.</li>
            <li>The rates applied as part of the Car Club do not qualify for further discounts.</li>
            </ol>
            <h3><b>PAYMENT</b></h3>
            <p>A valid credit or debit card must be linked to your account. All appointments are pre-paid with the payment card on file.</p>
        </div>
    );
};