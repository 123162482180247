export const isToday = (date: Date): boolean => {
    const today = new Date();

    return today.getDate() === date.getDate() &&
        today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear();
}

export const isAfterTime = (timeHours: number) => {
    const time = new Date();
    time.setHours(timeHours);
    time.setMinutes(0);
    time.setSeconds(0);

    const now = new Date();

    return now > time;
}

export const serialiseDate = (newDate: Date) => {
    newDate.setHours(12);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    
    return newDate.toISOString();
}