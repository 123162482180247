import { parseApiAddress } from '../models';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import { ApiStandardResponse, unpackStandardResponse } from './common/ApiStandardResponse';
import * as httpClient from './common/httpClient'
import { ApiAddress, ApiCreateAddressDto } from './Models';

export const getAllCustomerAddresses = async () => {
    const url = buildUrl('addresses');
    const response = await httpClient.getRequest<ApiStandardResponse<ApiAddress[]>>(url);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, addresses => addresses.map(parseApiAddress));
}

export const addCustomerAddress = async (address: ApiCreateAddressDto) => {
    const url = buildUrl(`addresses`);
    const response = await httpClient.postRequest(url, address);
    doErrorToastIfRequired(response);
    return response;
}

export const deleteCustomerAddress = async (id: number) => {
    const url = buildUrl(`addresses/${id}`);
    const response = await httpClient.patchRequest(url, { isDeleted: true });
    doErrorToastIfRequired(response);
    return response;
}