import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import InnerForogottenPasswordForm, { ResetPasswordFormValues as ForgotPasswordFormValues } from './ForgotPasswordForm';
import { useAuth } from '../../auth';
import { AppCardMessage } from '../AppCard';

export interface ForgotPasswordFormContainerProps {
    
}

const initialValues: ForgotPasswordFormValues = {
    email: '',
}

const ForgotPasswordFormValidationSchema = Yup.object().shape<ForgotPasswordFormValues>({
    email: Yup.string().email().required('Required.'),
});
 
const ResetPasswordContainer: React.FC<ForgotPasswordFormContainerProps> = () => {
    const { forgotPassword } = useAuth();
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordFormValidationSchema}
            onSubmit={async values => {
                var response = await forgotPassword(values);

                if (!response.isError) {
                    setEmailHasBeenSent(true);
                }
            }}
        >
            {formikProps => (
                <Form>
                    <InnerForogottenPasswordForm
                        {...formikProps}
                        emailHasBeenSent={emailHasBeenSent}
                    />
                </Form>
            )}
        </Formik>
    );
}
 
export default ResetPasswordContainer;