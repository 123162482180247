import React, { useState } from "react";
import classnames from "classnames";

import config from "../../config";
import styles from "./ResponsiveNavBar.module.css";

import AboutFresh from "./AboutFresh";
import Hamburger from "./Hamburger";
import NavBarLogo from "./NavBarLogo";
import Account from "./Account";
import CountryPicker from "./CountryPicker";
import Packages from "./Packages";

const ResponsiveNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header
      className={classnames({
        [styles.root]: true,
        [styles.open]: isOpen,
      })}
    >
      <div>
        <div className={styles.logo}>
          <NavBarLogo />
        </div>
        <div className={styles.hamburger}>
          <Hamburger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
        </div>
        <nav>
          <ul>
            <li>
              <a href={config.freshStaticSiteCarClub}>Car Club</a>
            </li>
            <li>
              <a href={config.freshStaticSiteDetailing}>Detailing</a>
            </li>
            <Packages />
            <li>
              <a href={config.freshStaticSiteFAQs}>FAQs</a>
            </li>
            <li>
              <a href={config.freshStaticSiteFranchising}>Franchising</a>
            </li>
            <AboutFresh />
            <li>
              <a href={config.freshStaticSiteLocations}>Locations</a>
            </li>
            <Account closeMenu={() => setIsOpen(false)} />
            <CountryPicker />
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default ResponsiveNavBar;
