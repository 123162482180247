import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiErrorLoaderWrapper, AppButton, AppCardList } from "../../Components";
import { actions, selectors, useDispatchEffect } from "../../store";

interface ValeterItem {
    id: number,
    valeterName: string
}

const PreferredValeterList: React.FC = () => {
    const {
        isLoading,
        apiError,
    } = useSelector(selectors.preferredValeter.apiState);
    const valeters = useSelector(selectors.preferredValeter.all);
    const dispatch = useDispatch();

    const deleteValeter = (customerPreferredValeterId: number) => dispatch(actions.preferredValeters.deletePreferredValeter(customerPreferredValeterId));

    const confirmDeleteValeter = (customerPreferredValeterId: number) => {
        const confirmed = window.confirm('Are you sure you want to remove this valeter from your preferred list?');
        
        if (confirmed) {
            deleteValeter(customerPreferredValeterId);
        }
    }

    useDispatchEffect(actions.preferredValeters.fetch);

    const preferredValeters: ValeterItem[] = valeters
    .map(valeter => ({
        id: valeter.customerPreferredValeterId,
        valeterName: valeter.resourceName,
    }));

    return ( 
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <AppCardList
                items={preferredValeters}
                getPrimaryInfo={valeter => valeter.valeterName}
                emptyMessage="You don't have any preferred valeters yet."
                renderButton={customerPreferredValeterId => (
                    <AppButton
                        size="small"
                        variant="black"
                        disabled={isLoading}
                        onClick={() => confirmDeleteValeter(customerPreferredValeterId)}
                    >
                        Delete
                    </AppButton>
                )}
            />
        </ApiErrorLoaderWrapper>
     );
}
 
export default PreferredValeterList;