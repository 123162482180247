import React, { useState } from 'react';
import { getRequest, postRequest } from '../api';
import config from '../config';

export const TestAuthPage = () => {
    const [apiGetResponse, setApiGetResponse] = useState<any>(null);
    const [apiGetError, setGetApiError] = useState('');
    const [apiPostResponse, setApiPostResponse] = useState<any>(null);
    const [apiPostError, setApiPostError] = useState('');

    const makeGetRequest = async () => {
        const response = await getRequest(`${config.authApiBaseUrl}/healthcheck/test-auth`);
        if (response.isError) {
            setGetApiError(response.message);
        }
        else {
            setApiGetResponse(response.content);
        }
    }

    const makePostRequest = async () => {
        const response = await postRequest(`${config.authApiBaseUrl}/healthcheck/test-auth`, { name: 'eric' });
        if (response.isError) {
            setApiPostError(response.message);
        }
        else {
            setApiPostResponse(response.content);
        }
    }

    return (
        <>
            <h1>Test Auth</h1>

            <h2>Get Request</h2>
            <button onClick={makeGetRequest}>Make Get Request</button>
            <h3>Error</h3>
            {apiGetError}
            <h3>Response</h3>
            <pre>{JSON.stringify(apiGetResponse, null, 2)}</pre>

            <h2>Post Request</h2>
            <button onClick={makePostRequest}>Make Post Request</button>
            <h3>Error</h3>
            {apiPostError}
            <h3>Response</h3>
            <pre>{JSON.stringify(apiPostResponse, null, 2)}</pre>
        </>
    )
};
