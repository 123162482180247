import * as Yup from 'yup';
import { CountryCode } from './Components/Localisation';

interface ValidationFailureResult {
    isValid: false,
    errorMessage: string,
}

interface ValidationSuccessResult {
    isValid: true,
}

export type ValidationResult = ValidationFailureResult|ValidationSuccessResult

const postcodeValidationSchema = (country: CountryCode) => Yup.string()
    .required('Please enter your postcode')
    .min(5, 'Missing part of your postcode')
    .max(12, 'Too many characters')
    .test(
        'validatePostcodeFormat',
        'Please check you have entered a valid postcode',
        p => {
            if(country === CountryCode.IE) return true;
            if (!p) return true;
            // Postcode regex taken from fresh norse site. It's ok for this as we are cloning that site but don't copy it without testing elsewhere.
            const matches = p.match(/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i);
            return !!matches && matches.length !== 0;
        }
    );

export const validatePostcode = async (postcode: string, country: CountryCode): Promise<ValidationResult> => {
    try {
        await postcodeValidationSchema(country).validate(postcode);
        return {
            isValid: true,
        }
    }
    catch (error) {
        return {
            isValid: false,
            errorMessage: error.message,
        }
    }
}