import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useServiceTypeId } from '.';

import ApiErrorLoaderWrapper from '../../Components/ErrorLoaderWrapper';
import { actions, selectors, useDispatchEffect } from '../../store';
import { makeBookingPathBuilders, useCarId, usePackageCategoryId } from './makeBookingPaths';
import SelectPackage from './SelectPackage';

const SelectPackageContainer = () => {
    const history = useHistory();
    const carId = useCarId();
    const serviceTypeId = useServiceTypeId();
    const packageCategoryId = usePackageCategoryId();
    const { isLoading, apiError } = useSelector(selectors.packageInfo.apiState);
    const selectPackages = selectors.packageInfo.makeSelectPackagesByCategoryId(packageCategoryId);
    const packages = useSelector(selectPackages);

    useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(1));
    const dispatch = useDispatch();

    const handlePackageSelect = (packageId: number) => {
        dispatch(actions.makeBooking.setCarPackage(carId, packageId));
        history.push(makeBookingPathBuilders.optionalExtras(carId, serviceTypeId, packageCategoryId, packageId));
    }

    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <SelectPackage
                packages={packages}
                onPackageSelect={handlePackageSelect}
            />
        </ApiErrorLoaderWrapper>
    )
}

export default SelectPackageContainer;