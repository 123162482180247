import React from 'react';
import { AppCard, AppCardContent, AppCardsLayout, AppCardTitle } from '../../Components';
import { RegisterForm } from '../../Components';
import { actions, selectors, useDispatchEffect } from '../../store';
import RegisterAndBookPriceSummaryContainer from './RegisterAndBookPriceSummaryContainer';
import { loadStripe } from "@stripe/stripe-js";
import config from "../../config";
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { Car } from '../../models';
import { useIsTabletMedia } from '../../mediaQueries';

export const RegisterAndBook = () => {
    const stripePromise = loadStripe(config.stripePrivateKey);
    useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(3));

    const allCars = useSelector(selectors.customerCars.all);
    const selectedCarIds = useSelector(selectors.makeBooking.selectedCarIds);
    const selectedCars = selectedCarIds.map(carId => allCars.find(c => c.id === carId) as Car);

    const isTablet = useIsTabletMedia();
    document.documentElement.style.setProperty('--columnsOnTablet','1');
    
    return (
        <AppCardsLayout>
            <AppCard 
                maxFullScreenWidth={isTablet ? "70%": "50%"}
                fullScreenWidth={isTablet ? "70%": undefined}
            >
                <AppCardTitle subtitle="Please enter the following information so we can complete your booking">Complete Your Booking</AppCardTitle>
                <Elements stripe={stripePromise}>
                    <RegisterForm />
                </Elements>
            </AppCard>
            <AppCard 
                maxFullScreenWidth={isTablet ? "70%": "30%"}
                fullScreenWidth={isTablet ? "70%": undefined}>
                <AppCardTitle>Summary</AppCardTitle>
                <AppCardContent>
                    <RegisterAndBookPriceSummaryContainer
                        selectedCars={selectedCars} />
                </AppCardContent>
            </AppCard>
        </AppCardsLayout>
    );
}
