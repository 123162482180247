import React, { useEffect, useState } from "react";
import { CountryCode, useLocalisation } from "../Localisation";
import styles from "./CountryPicker.module.css";
import DropdownMenu from "./DropdownMenu";
import DropdownToggle from "./DropdownToggle";

const CountryPicker: React.FC = () => {
  const isTouchScreen = "ontouchstart" in document.documentElement;
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { country, isLocked, handleLocalisationChange } = useLocalisation();

  const renderToggle = (disabled: boolean) => (
    <DropdownToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen && !disabled)} disabled={disabled} />
  );

  useEffect(() => {
    setIsOpen(false);
  }, [country]);

  return (
    <li
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${styles.pickerParent} ${isLocked && styles.pickerLocked}`}
    >
      <button className={styles.picker} onClick={() => setIsOpen(!isOpen)} disabled={isLocked}>
        {country.toUpperCase()}
      </button>
      {renderToggle(isLocked)}
      <DropdownMenu
        isOpen={!isLocked && ((!isTouchScreen && isHovered) || isOpen)}
        items={[
          {
            label: "UK",
            onClick: () => handleLocalisationChange(CountryCode.UK),
          },
          {
            label: "IE",
            onClick: () => handleLocalisationChange(CountryCode.IE),
          },
        ]}
      />
    </li>
  );
};

export default CountryPicker;
