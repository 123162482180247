import React from "react";
import { useSelector } from "react-redux";
import { CustomerCarBooking } from "../../models";
import { selectors } from "../../store";
import styles from './BookingOverview.module.css';


export interface BookingOverViewCarDetailsProps {
    carDetails: CustomerCarBooking
}

const BookingOverViewCarDetails: React.FC<BookingOverViewCarDetailsProps> = ({ carDetails }) => {

    const packageInfo = useSelector(selectors.packageInfo.makeSelectPackageById(carDetails.packageId));
    const optionalExtraInfo = useSelector(selectors.packageInfo.makeSelectOptionalExtrasByIds(carDetails.optionalExtraIds));



    return (
        <>
            <h1 className={styles.carMakeAndModel}>{carDetails.makeAndModel}</h1>
            <div className={styles.infoText}>
                <p className={styles.packageName}><b>{packageInfo?.name}</b></p>
                <p>{packageInfo?.description}</p>
                <p>{packageInfo?.totalCost}</p>
            </div>
            {optionalExtraInfo?.map(extra => (
                <div className={styles.infoText}>
                    <p><b>{extra?.name}</b></p>
                    <p>{extra?.description}</p>
                </div>
            ))}
        </>
    );
}

export default BookingOverViewCarDetails;