import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import "./variables.css";
import "./app.css";
import Routes from "./Routes";
import { AuthProvider } from "./auth";
import { ResponsiveNavBar } from "./Components";
import store from "./store";
import { LocalisationProvider } from "./Components/Localisation";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer className="toast" />
      <AuthProvider>
        <Router>
          <LocalisationProvider>
            <ResponsiveNavBar />
            <div className="main">
              <Routes />
            </div>
          </LocalisationProvider>
        </Router>
      </AuthProvider>
    </Provider>
  );
}

export default App;
