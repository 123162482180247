import { useField } from "formik";
import React from "react";
import { BookingSlot } from "./BookingTimeSlotPicker";
import styles from "./BookingTimeSlotPicker.module.css";
import EcoIcon from "../../assets/eco-icon.svg";

interface BookingTimeSlotProps {
  index: number;
  name: string;
  slot: BookingSlot;
  disabled?: boolean;
}

function BookingTimeSlot({
  index,
  name,
  slot,
  disabled,
}: BookingTimeSlotProps) {
  const [inputProps, _, fieldHelpers] = useField(name);

  return (
    <div
      className={`${styles.timeSlot} ${
        slot.isEcoSlot ? styles.ecoSlot : styles.nonEcoSlot
      }`}
      key={index}
    >
      <input
        type="radio"
        name={name}
        value={slot.value}
        onChange={() => fieldHelpers.setValue(slot.value)}
        checked={inputProps.value === slot.value}
        disabled={disabled}
      />
      <span>
        {slot.label} {slot.isEcoSlot && <img src={EcoIcon} alt="Eco slot" />}
      </span>
    </div>
  );
}

export default BookingTimeSlot;
