import React from "react";
import { useLocalisation } from "../../Components/Localisation";

import { Package, PackageItem } from "../../models";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";
import styles from "./CarEnquirySummary.module.css";
import CarEnquirySummaryItem from "./CarEnquirySummaryItem";
import CarEnquirySummaryMainItem from "./CarEnquirySummaryMainItem";

export interface CarEnquirySummaryProps {
  carPackage?: Package;
  carCategory: string;
  optionalExtras: (PackageItem | undefined)[];
}

const CarEnquirySummary: React.FC<CarEnquirySummaryProps> = ({
  carPackage,
  carCategory,
  optionalExtras,
}) => {
  const { currency } = useLocalisation();
  let totalOptionalExtrasCost = 0;
  const price = carPackage
    ? carPackage.possiblePrices.find(
        (possiblePrice) => possiblePrice.carCategory === carCategory
      )?.price
    : 0;
  optionalExtras.forEach(
    (item) =>
      (totalOptionalExtrasCost += item
        ? exchangeRateAdjustedPrice(
            item.optionalExtraPrice,
            currency.exchangeRate
          )
        : 0)
  );
  let totalCost = price
    ? exchangeRateAdjustedPrice(price, currency.exchangeRate)
    : 0;
  totalCost += totalOptionalExtrasCost;
  const priceToDisplay = formatPriceWithCurrencySymbol(
    totalCost,
    currency.symbol
  );

  return (
    <div className={styles.packageDetails}>
      <div>
        <h3>Package:</h3>
        <CarEnquirySummaryMainItem
          name={carPackage ? carPackage.name : "No package selected."}
          price={price}
        />
      </div>
      <div>
        <h3>Optional Extras:</h3>
        {optionalExtras.length === 0 ? (
          <p>No optional extras selected.</p>
        ) : (
          optionalExtras.map((optionalExtra) => (
            <CarEnquirySummaryItem
              key={optionalExtra?.packageItemId}
              name={optionalExtra ? optionalExtra.name : ""}
              price={optionalExtra?.optionalExtraPrice}
            />
          ))
        )}
      </div>
      <div>
        <h3>
          Total Cost: <span>{priceToDisplay}</span>
        </h3>
      </div>
    </div>
  );
};

export default CarEnquirySummary;
