import React from 'react';
import styles from './AppForm.module.css';

interface AppFormProps {
    style?: React.CSSProperties,
}

const AppForm: React.FC<AppFormProps> = ({
    children,
    style,
}) => {
    return (
        <div className={styles.root} style={style}>
            {children}
        </div>
    );
}

export default AppForm;
