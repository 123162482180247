import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { Package, PackageCategory, PackageItem, PossiblePrice, ServiceType } from '../models';
import * as apiClient from '../api';
import { getAllPackageInfo, HttpClientFailureResponse } from '../api';
import { ApiPackageInfo } from '../api/Models';

export const sliceName = 'packageInfo';

const asyncThunk = createAsyncThunk<
    ApiPackageInfo,
    string,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/fetchAll`,
    async (postcode, { rejectWithValue }) => {

        const response = await (
            postcode.length > 0 || postcode === 'ireland' ?
                apiClient.getPackageInfoByPostcode(postcode === 'ireland' ? '' : postcode)
                : apiClient.getAllPackageInfo()
        );

        if (response.isError) {
            return rejectWithValue(response);
        }
        return response.content;
    }
)

const serviceTypesEntityAdapter = createEntityAdapter<ServiceType>({
    selectId: p => p.id,
});

const packagesEntityAdapter = createEntityAdapter<Package>({
    selectId: p => p.packageGroupId,
});

const packageCategoriesEntityAdapter = createEntityAdapter<PackageCategory>({
    selectId: p => p.packageGroupCategoryId,
});

const optionalExtrasEntityAdapter = createEntityAdapter<PackageItem>({
    selectId: p => p.packageItemId,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type SliceState = {
    serviceTypeEntities: EntityState<ServiceType>,
    packageCategoryEntities: EntityState<PackageCategory>,
    packageEntities: EntityState<Package>,
    optionalExtraEntities: EntityState<PackageItem>,
    isLoading: boolean,
    apiError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    serviceTypeEntities: serviceTypesEntityAdapter.getInitialState(),
    packageCategoryEntities: packageCategoriesEntityAdapter.getInitialState(),
    packageEntities: packagesEntityAdapter.getInitialState(),
    optionalExtraEntities: optionalExtrasEntityAdapter.getInitialState(),
    isLoading: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(asyncThunk.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(asyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.apiError = payload;
        });
        builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            const packageCategoryStates: PackageCategory[] = payload.categories.map(packageCategory => {
                const packagePrices = payload.packageGroups
                    .filter(p => p.packageGroupCategoryId === packageCategory.packageGroupCategoryId)
                    .map(p => [...p.possiblePrices])
                    .flat(1)
                    .sort((a, b) => a.price - b.price);

                
                const uniqueCategories = Array.from(new Set(packagePrices.map(packagePrice => packagePrice.carCategory)));
                
                const startingPrices: PossiblePrice[] = [];

                uniqueCategories.forEach(category => {
                    const lowestPossiblePrice = packagePrices.find(packagePrice => packagePrice.carCategory === category);

                    if(lowestPossiblePrice) {
                        startingPrices.push(lowestPossiblePrice);
                    }
                });

                return {
                    ...packageCategory,
                    startingPrices: startingPrices
                };
            });

            serviceTypesEntityAdapter.removeAll(state.serviceTypeEntities);
            packageCategoriesEntityAdapter.removeAll(state.packageCategoryEntities);
            packagesEntityAdapter.removeAll(state.packageEntities);
            optionalExtrasEntityAdapter.removeAll(state.optionalExtraEntities);

            serviceTypesEntityAdapter.setAll(state.serviceTypeEntities, payload.serviceTypes);
            packageCategoriesEntityAdapter.setAll(state.packageCategoryEntities, packageCategoryStates);
            packagesEntityAdapter.setAll(state.packageEntities, payload.packageGroups);
            optionalExtrasEntityAdapter.setAll(state.optionalExtraEntities, payload.packageItems);
        });
    }
});

export const {
    reducer,
} = slice;

export const actions = {
    fetchIfRequired: (postcode: string = '') => (dispatch: any, getState: () => SliceState) => {
        const allPackages = selectAllPackages(getState());
        if (allPackages.length === 0 || postcode.length > 0) {
            dispatch(asyncThunk(postcode));
        }
    }
};

const selectSliceState = (state: any) => state[sliceName] as SliceState;
const serviceTypesEntitySelectors = serviceTypesEntityAdapter.getSelectors();
const packageCategoriesEntitySelectors = packageCategoriesEntityAdapter.getSelectors();
const packagesEntitySelectors = packagesEntityAdapter.getSelectors();
const optionalExtrasEntitySelectors = optionalExtrasEntityAdapter.getSelectors();

const selectAllServiceTypes = createSelector(
    selectSliceState,
    state => serviceTypesEntitySelectors.selectAll(state.serviceTypeEntities)
);

const selectAllPackages = createSelector(
    selectSliceState,
    state => packagesEntitySelectors.selectAll(state.packageEntities)
);

const selectAllPackageCategories = createSelector(
    selectSliceState,
    state => packageCategoriesEntitySelectors.selectAll(state.packageCategoryEntities)
);

const selectAllOptionalExtras = createSelector(
    selectSliceState,
    state => optionalExtrasEntitySelectors.selectAll(state.optionalExtraEntities)
);

export const selectors = {
    apiState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isLoading,
            apiError: state.apiError,
        }),
    ),
    selectAllServiceTypes,
    selectAllPackages,
    selectAllPackageCategories,
    selectAllOptionalExtras,
    makeSelectPackageCategoriesByServiceTypeId: (serviceTypeId: number) => createSelector(
        selectAllPackageCategories,
        packageCategories => packageCategories.filter(packageCategory => packageCategory.serviceTypeId === serviceTypeId)
    ),
    makeSelectPackageById: (packageId: number) => createSelector(
        selectSliceState,
        state => packagesEntitySelectors.selectById(state.packageEntities, packageId)
    ),
    makeSelectOptionalExtrasByIds: (optionalExtraIds: number[]) => createSelector(
        selectSliceState,
        state => optionalExtraIds.map(optionalExtraId => optionalExtrasEntitySelectors
            .selectById(state.optionalExtraEntities, optionalExtraId))
    ),
    makeSelectPackagesByCategoryId: (categoryId: number) => createSelector(
        selectAllPackages,
        allPackages => allPackages.filter((p: Package)  => p.packageGroupCategoryId === categoryId || p.secondaryPackageGroupCategoryIds.includes(categoryId))
    ),
    makeSelectOptionalExtrasForPackage: (packageId: number) => createSelector(
        selectSliceState,
        selectAllOptionalExtras,
        (state, allOptionalExtras) => {
            const packageGroup = packagesEntitySelectors.selectById(state.packageEntities, packageId);
            if (!packageGroup) return [];
            return packageGroup.optionalExtraIds
                .map(optionalExtraId => optionalExtrasEntitySelectors.selectById(state.optionalExtraEntities, optionalExtraId));
        }
    )
};