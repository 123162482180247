import React from 'react';

import styles from './AppFormError.module.css';

export interface AppFormErrorProps {
    show: boolean,
}
 
const AppFormError: React.FC<AppFormErrorProps> = ({
    show,
    children,
}) => {
    if (show) {
        return (
            <p className={styles.root}>
                {children}
            </p>
        );
    }

    return null;
}

export default AppFormError;