import React from 'react';

import { ExistingBooking } from '../../models';
import styles from './BookingsList.module.css';
import BookingListPreviousItem from './BookingsListPreviousItem';
import BookingListUpcomingItem from './BookingsListUpcomingItem';

export interface BookingsListProps {
    bookings: ExistingBooking[],
    onRebook: () => void,
}

const BookingsList: React.FC<BookingsListProps> = ({
    bookings,
    onRebook    
}) => {
    const upcomingBookings = bookings.filter(b => !b.isPast)
    const previousBookings = bookings.filter(b => b.isPast)
    return (
        <div className={styles.root}>
            <div>
                <h3>Upcoming Bookings</h3>
                <ul className={styles.itemsList}>
                    {upcomingBookings.map(booking => (
                        <BookingListUpcomingItem
                            key={booking.id}
                            booking={booking}
                            onRebook={onRebook}
                        />
                    ))}
                </ul>
            </div>

            <div>
                <h3>Previous Bookings</h3>
                <ul className={styles.itemsList}>
                    {previousBookings.map(booking => (
                        <BookingListPreviousItem
                            key={booking.id}
                            booking={booking}
                        />
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default BookingsList;