import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import { AppButton, AppCard, AppCardButtons, AppCardContent, AppCardTitle, AppInput } from '../../Components';
import { formatDateStringDateTime } from '../../dateFormatter';
import { AppFormikInput, AppForm } from '../../Components';
import AppFormikCalendar from '../../Components/AppCalendar/AppFormikCalendar';

export interface PersonalDetailsFormValues {
    title: string,
    firstName: string,
    lastName: string,
    dateOfBirth?: string,
    contactNumber: string,
}

interface OtherProps {
    acccountCreatedOn?: string,
    initialValues: {
        title?: string,
        firstName?: string,
        lastName?: string,
        dateOfBirth?: string,
        contactNumber?: string,
    },
    onCancel: () => void,
}

const InternalPersonalDetailsCard: React.FC<OtherProps & FormikProps<PersonalDetailsFormValues>> = ({
    acccountCreatedOn,
    isSubmitting,
    onCancel,
}) => {
    return (
        <AppCard maxFullScreenWidth="50%">
            <AppCardTitle size="small">Personal Details</AppCardTitle>
            <Form>
                <AppCardContent>
                    <AppForm>
                        <AppFormikInput
                            label="Title"
                            name="title"
                            placeholder="Title"
                            type="string"
                        />
                        <AppFormikInput
                            label="First Name"
                            name="firstName"
                            placeholder="First Name"
                            type="string"
                        />
                        <AppFormikInput
                            label="Last Name"
                            name="lastName"
                            placeholder="Last Name"
                            type="string"
                        />
                        <AppFormikInput
                            label="Contact Number"
                            name="contactNumber"
                            placeholder="Contact Number"
                            type="string"
                        />
                        <AppFormikCalendar
                            label="Date of Birth"
                            name="dateOfBirth"
                            maxDate={new Date()}
                            minDetail="decade"
                            defaultView="month"
                        />
                        {acccountCreatedOn && (
                            <AppInput
                                label="Date Account Created"
                                value={formatDateStringDateTime(acccountCreatedOn)}
                                disabled
                            />
                        )}
                    </AppForm>
                </AppCardContent>
                <AppCardButtons>
                    <AppButton onClick={onCancel}>Cancel</AppButton>
                    <AppButton type="submit" variant="inverted" disabled={isSubmitting}>Save Details</AppButton>
                </AppCardButtons>
            </Form>
        </AppCard>
    );
}

interface PersonalDetailsFormProps {
    acccountCreatedOn?: string,
    initialValues: {
        title?: string,
        firstName?: string,
        lastName?: string,
        contactNumber?: string,
        dateOfBirth?: string,
    },
    onSubmit: (values: PersonalDetailsFormValues) => Promise<void>,
    onCancel: () => void,
}

const PersonalDetailsFormSchema = Yup.object().shape<PersonalDetailsFormValues>({
    title: Yup.string().required('Required.').max(10, 'Cannot exceed 10 characters'),
    firstName: Yup.string().required('Required.'),
    lastName: Yup.string().required('Required.'),
    contactNumber: Yup.string().required('Required.'),
});

const PersonalDetailsCard = withFormik<PersonalDetailsFormProps, PersonalDetailsFormValues>({
    mapPropsToValues: ({ initialValues }) => ({
        title: initialValues.title || '',
        firstName: initialValues.firstName || '',
        lastName: initialValues.lastName || '',
        contactNumber: initialValues.contactNumber || '',
        dateOfBirth: initialValues.dateOfBirth || undefined,
    }),
    validationSchema: PersonalDetailsFormSchema,
    handleSubmit: async (values, { props: { onSubmit } }) => {
        await onSubmit(values);
    },
})(InternalPersonalDetailsCard);

export default PersonalDetailsCard;