import config from "./config";
import { MarketingBookingType, DataLayerObj } from "./models/Marketing";

const trackBookings = config.trackBookings;

declare global {
    interface Window { dataLayer?: object[]; }
}

const pushToDataLayer = (obj: DataLayerObj) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
}

export const trackBooking = (trackingType: MarketingBookingType) => {
    if(trackBookings) {
        pushToDataLayer({event: trackingType});
    }
}