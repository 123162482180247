import React from 'react';
import styles from './AppCardList.module.css';

export interface AppCardListProps<T> {
    items: T[],
    getPrimaryInfo: (item: T) => string,
    getSecondaryInfo?: (item: T) => string,
    emptyMessage: string,
    renderButton: (itemId: number) => React.ReactNode,
}
 
function AppCardList<T extends { id: number }>({
    items,
    getPrimaryInfo,
    getSecondaryInfo,
    emptyMessage,
    renderButton,
}: AppCardListProps<T>) {
    return (
        <ul className={styles.root}>
            {items.map(item => (
                <li key={item.id}>
                    <div className={styles.itemDetails}>
                        <b>{getPrimaryInfo(item)}</b>
                        { getSecondaryInfo &&
                            <p>{getSecondaryInfo(item)}</p>
                        }
                    </div>
                    {
                        renderButton(item.id)
                    }
                </li>
            ))}
            {items.length === 0 && <p>{emptyMessage}</p>}
        </ul>
    );
}
 
export default AppCardList;