import React from 'react';

import { Address } from '../../models';
import { AppButton, AppCardList, ApiErrorLoaderWrapper } from '../../Components';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors, useDispatchEffect } from '../../store';
import { deleteCustomerAddress } from '../../api';

const AddressesList: React.FC = () => {
    const {
        isLoading,
        isDeleting,
        apiError,
    } = useSelector(selectors.customerAddresses.apiState);
    const addresses = useSelector(selectors.customerAddresses.all);
    const dispatch = useDispatch();
    const deleteAddress = (addressId: number) => dispatch(actions.customerAddresses.deleteSavedAddress(addressId));
    
    const confirmDeleteAddress = (addressId: number) => {
        const confirmed = window.confirm('This action is irreversible, are you sure you want to delete this address?');
        
        if (confirmed) {
            deleteAddress(addressId);
        }
    }

    useDispatchEffect(actions.customerAddresses.fetch);

    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <AppCardList
                items={addresses as Address[]}
                getPrimaryInfo={address => address.addressLine1}
                getSecondaryInfo={address => address.postcode}
                emptyMessage="You don't have any addresses yet."
                renderButton={addressId => (
                    <AppButton
                        size="small"
                        variant="black"
                        disabled={isDeleting}
                        onClick={() => confirmDeleteAddress(addressId)}
                    >
                        Delete
                    </AppButton>
                )}
            />
        </ApiErrorLoaderWrapper>
    )
}

export default AddressesList;