import React from 'react';
import { PageTitle, RegisterForm } from '../Components';

export interface RegisterPageProps {

}

export const RegisterPage: React.FC<RegisterPageProps> = () => {
    return (
        <>
            <PageTitle>Register</PageTitle>
            <p>Note: This is just a placeholder at the moment as this will become par of the "first valet" flow.</p>
            <RegisterForm />
        </>
    );
}