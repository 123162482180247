import { ConsentItem, ConsentItemKey } from '../../models';

const consentItems: { [K in ConsentItemKey]: ConsentItem } = {
    emailConsent: {
        label: 'Email Consent',
        description: 'I consent to receive promotional emails about your products and services and understand that I can opt out of this service at any time by unchecking this tick box.'
    },
    phoneConsent: {

        label: 'Phone Consent',
        description: 'I consent to receive promotional calls about your products and services and understand that I can opt out of this service at any time by unchecking this tick box.'
    },
    smsConsent: {
        label: 'SMS Consent',
        description: 'I consent to receive promotional text messages or sms about your products and services and understand that I can opt out of this service at any time by unchecking this tick box.'
    },
    postalConsent: {
        label: 'Postal Consent',
        description: 'I consent to receive promotional letters about your products and services and understand that I can opt out of this service at any time by unchecking this tick box.'
    },
};

export default consentItems;
