import { ApiCar } from '../api/Models';

export interface AddCarDto {
    makeAndModel: string,
    registrationNumber: string,
    category: string,
    colour: string,
    year: string
}

export interface Car extends AddCarDto {
    id: number
}

export const parseApiCars = (apiCars: ApiCar[]): Car[] => {
    let normalizedCars = [];

    for(let i = 0; i < apiCars.length; i++) {
        normalizedCars.push({
            id: apiCars[i].customerCarId,
            makeAndModel: apiCars[i].makeAndModel,
            registrationNumber: apiCars[i].registrationNumber,
            category: apiCars[i].category,
            colour: apiCars[i].colour,
            year: apiCars[i].year
        });
    }

    return normalizedCars;
};