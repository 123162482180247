import React from 'react';
import classnames from 'classnames';

import { ExistingBooking, CustomerCarBooking } from '../../models';
import styles from './BookingsList.module.css';
import { formatUnifiedDateTime } from '../../dateFormatter';
import { useSelector } from 'react-redux';
import { actions, useDispatchEffect, selectors } from '../../store';
import { AppButton } from '../../Components';
import { useHistory } from 'react-router-dom';
import { pathBuilders } from '../../Routes';

export interface BookingListPreviousItemProps {
    booking: ExistingBooking,
}

interface BookingCarPackageDetailProps {
    car: CustomerCarBooking
}

const BookingCarPackageDetail: React.FC<BookingCarPackageDetailProps> = ({ car }) => {
    const packageInfo = useSelector(selectors.packageInfo.makeSelectPackageById(car.packageId));

    return (
        <div>
            <h4>{packageInfo?.name}</h4>
            <p key={car.id}>{car.makeAndModel} {car.registrationNumber ? `(${car.registrationNumber})` : ""}</p>
        </div>
    );
}

const BookingListPreviousItem: React.FC<BookingListPreviousItemProps> = ({
    booking: {
        addressId,
        id,
        bookingCustomerCars,
        confirmedDate,
        requestedDate,
        timeOfDay
    }
}) => {
    const history = useHistory();
    const address = useSelector(selectors.customerAddresses.all).find(address => address.id === addressId);

    const formattedDateWithTimeOfDay = formatUnifiedDateTime(requestedDate, confirmedDate, timeOfDay);

    const carList = bookingCustomerCars.map((car, i) =>
        <BookingCarPackageDetail car={car} key={i} />
    );

    return (
        <li key={id} className={classnames(styles.booking, styles.previousBooking)}>
            <div className={styles.bookingCarPackagesContainer}>
                {carList}
            </div>
            <p className={classnames(styles.bookingAddress)}>{address ? address.postcode : '(Address Removed)'}</p>
            <p>{formattedDateWithTimeOfDay}</p>
            <div className={styles.bookingListButtons}>
                <AppButton
                    onClick={() => history.push(pathBuilders.bookingOverview(id))}
                    size="small"
                > Overview
                </AppButton>
                <div className={styles.rebookButton}>
                {/* <AppButton
                    onClick={() => console.log('Rebook')}
                    variant="inverted"
                    size="small"
                    > Rebook
                </AppButton> */}
                    </div>
            </div>
        </li>
    );
}

export default BookingListPreviousItem;