import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Address } from '../models';
import * as apiClient from '../api';
import { HttpClientFailureResponse } from '../api';
import { authClient } from '../auth';

export const sliceName = 'customerAddresses';

const asyncThunk = createAsyncThunk<
    Address[],
    void,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/fetchAll`,
    async (_, { rejectWithValue }) => {
        var response = await apiClient.getAllCustomerAddresses();
        if (response.isError) {
            return rejectWithValue(response);
        }
        return response.content;
    }
)

const deleteThunk = createAsyncThunk<
    number,
    number,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/delete`,
    async (addressId, { rejectWithValue }) => {
        var response = await apiClient.deleteCustomerAddress(addressId);
        if (response.isError) {
            return rejectWithValue(response);
        }
        return addressId;
    }
)

const addressesEntityAdapter = createEntityAdapter<Address>({
    selectId: p => p.id,
    sortComparer: (a, b) => a.addressLine1.localeCompare(b.addressLine1),
});

type SliceState = {
    addressEntities: EntityState<Address>,
    isLoading: boolean,
    apiError?: HttpClientFailureResponse,
    isDeleting: boolean,
    deleteApiError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    addressEntities: addressesEntityAdapter.getInitialState(),
    isLoading: false,
    isDeleting: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addNewCustomerCar: (state, { payload }: PayloadAction<Address>) => {
            addressesEntityAdapter.addOne(state.addressEntities, payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(asyncThunk.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(asyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.apiError = payload;
        });
        builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            addressesEntityAdapter.removeAll(state.addressEntities);
            addressesEntityAdapter.setAll(state.addressEntities, payload);
        });

        builder.addCase(deleteThunk.pending, state => {
            state.isDeleting = true;
        });
        builder.addCase(deleteThunk.rejected, (state, { payload }) => {
            state.isDeleting = false;
            state.deleteApiError = payload;
        });
        builder.addCase(deleteThunk.fulfilled, (state, { payload }) => {
            state.isDeleting = false;
            addressesEntityAdapter.removeOne(state.addressEntities, payload);
        });
    }
});

export const {
    reducer,
} = slice;

export const actions = {
    fetch: asyncThunk,
    fetchIfRequired: () => (dispatch: any, getState: () => SliceState) => {
        if (!authClient.getAccountInformation()) return;
        
        const allPackages = selectAllAddresses(getState());
        if (allPackages.length === 0) {
            dispatch(asyncThunk());
        }
    },
    deleteSavedAddress: (addressId: number) => deleteThunk(addressId),
};

const selectSliceState = (state: any) => state[sliceName] as SliceState;
const addressEntitySelectors = addressesEntityAdapter.getSelectors();

const selectAllAddresses = createSelector(
    selectSliceState,
    state => addressEntitySelectors.selectAll(state.addressEntities)
);

export const selectors = {
    apiState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isLoading,
            isDeleting: state.isDeleting,
            apiError: state.apiError,
        }),
    ),
    all: selectAllAddresses,
}