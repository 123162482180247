import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Car } from '../models';
import * as apiClient from '../api';
import { HttpClientFailureResponse } from '../api';
import { authClient } from '../auth';

export const sliceName = 'customerCars';

type RootState = {
    [sliceName]: SliceState
}

interface CarState extends Car {
    isNew?: boolean,
}

const asyncThunk = createAsyncThunk<
    Car[],
    void,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/fetchAll`,
    async (_, { rejectWithValue }) => {
        var response = await apiClient.getAllCars();

        if (response.isError) {
            return rejectWithValue(response);
        }

        return response.content;
    }
)

const deleteThunk = createAsyncThunk<
    number,
    number,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/delete`,
    async (carId, { rejectWithValue }) => {
        var response = await apiClient.deleteCar(carId);
        if (response.isError) {
            return rejectWithValue(response);
        }
        return carId;
    }
)

const carsEntityAdapter = createEntityAdapter<CarState>({
    selectId: p => p.id,
    sortComparer: (a, b) => a.makeAndModel.localeCompare(b.makeAndModel),
});

type SliceState = {
    carEntities: EntityState<CarState>,
    isLoading: boolean,
    apiError?: HttpClientFailureResponse,
    isDeleting: boolean,
    deleteApiError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    carEntities: carsEntityAdapter.getInitialState(),
    isLoading: false,
    isDeleting: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addNewCustomerCar: (state, { payload }: PayloadAction<Car>) => {
            const newCar: CarState = {
                ...payload,
                isNew: true,
            };

            carsEntityAdapter.addOne(state.carEntities, newCar);
        },
        reset: state => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(asyncThunk.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(asyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.apiError = payload;
        });
        builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            carsEntityAdapter.removeAll(state.carEntities);
            carsEntityAdapter.setAll(state.carEntities, payload);
        });

        builder.addCase(deleteThunk.pending, state => {
            state.isDeleting = true;
        });
        builder.addCase(deleteThunk.rejected, (state, { payload }) => {
            state.isDeleting = false;
            state.deleteApiError = payload;
        });
        builder.addCase(deleteThunk.fulfilled, (state, { payload }) => {
            state.isDeleting = false;
            carsEntityAdapter.removeOne(state.carEntities, payload);
        });
    },
});

export const {
    reducer,
} = slice;

export const actions = {
    fetch: asyncThunk,
    fetchIfRequired: () => (dispatch: any, getState: () => RootState) => {
        if (!authClient.getAccountInformation()) return;
        
        const allCars = selectAllCars(getState());
        const allSavedCars = allCars.filter(car => !car.isNew);

        if (allSavedCars.length === 0) {
            dispatch(asyncThunk());
        }
    },
    deleteSavedCar: (carId: number) => deleteThunk(carId),
    addNewCustomerCar: (newCar: Car) => slice.actions.addNewCustomerCar(newCar),
    reset: slice.actions.reset,
};

const selectSliceState = (state: RootState) => state[sliceName] as SliceState;
const carEntitySelectors = carsEntityAdapter.getSelectors();

const selectAllCars = createSelector(
    selectSliceState,
    state => carEntitySelectors.selectAll(state.carEntities)
);

export const selectors = {
    apiState: createSelector(
        selectSliceState,
        state => ({
            isLoading: state.isLoading,
            isDeleting: state.isDeleting,
            apiError: state.apiError,
        }),
    ),
    all: selectAllCars,
    makeSelectCarById: (carId: number) => createSelector(
        selectSliceState,
        state => carEntitySelectors.selectById(state.carEntities, carId),
    ),
    selectMaxExistingId: createSelector(
        selectSliceState,
        state => {
            if (state.carEntities.ids.length === 0) return 1;
            return Math.max(...state.carEntities.ids as number[]);
        },
    )
}

export const selectStateToSave = (state: RootState): SliceState => {
    const carsToSave = selectAllCars(state)
        .filter(car => car.isNew);

    return {
        ...initialState,
        carEntities: carsEntityAdapter.addMany(carsEntityAdapter.getInitialState(), carsToSave),
    };
}