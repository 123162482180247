import { useParams } from 'react-router-dom';
import { pathBuilders as rootPathBuilders } from '../../Routes';

export const makeBookingPathBuilders = {
    locationCheck: () => `${rootPathBuilders.makeBooking()}/location-check`,
    addCar:  () => `${rootPathBuilders.makeBooking()}/add-car`,
    cars: () => `${rootPathBuilders.makeBooking()}/cars`,
    serviceTypes: (carId: string|number) => `${rootPathBuilders.makeBooking()}/cars/${carId}/service-types`,
    carPackageCategories: (carId: string|number, serviceTypeId: string|number) => `${rootPathBuilders.makeBooking()}/cars/${carId}/service-types/${serviceTypeId}/categories`,
    carPackages: (carId: string|number, serviceTypeId: string|number, packageCategory: string|number) => (
        `${rootPathBuilders.makeBooking()}/cars/${carId}/service-types/${serviceTypeId}/categories/${packageCategory}/packages`
    ),
    optionalExtras: (carId: string|number, serviceTypeId: string|number, packageCategoryId: string|number, packageId: string|number) => (
        `${rootPathBuilders.makeBooking()}/cars/${carId}/service-types/${serviceTypeId}/categories/${packageCategoryId}/packages/${packageId}/optional-extras`
    ),
    chooseDate: () => `${rootPathBuilders.makeBooking()}/choose-date`,
    makePayment: () => `${rootPathBuilders.makeBooking()}/make-payment`,
    completeFirstTimeBooking:  () => `${rootPathBuilders.makeBooking()}/first-time-booking`,
    enquirySent: () => `${rootPathBuilders.makeBooking()}/enquiry-sent`,
    paymentConfirmed: (bookingId: string|number) => `${rootPathBuilders.makeBooking()}/payment-confirmed/${bookingId}`,
}

export const useCarId = () => {
    const { carId } = useParams<{ carId: string }>();
    return parseInt(carId);
}

export const useServiceTypeId = () => {
    const { serviceTypeId } = useParams<{ serviceTypeId: string }>();
    return parseInt(serviceTypeId);
}

export const usePackageCategoryId = () => {
    const { categoryId } = useParams<{ categoryId: string }>();
    return parseInt(categoryId);
}

export const useBookingId = () => {
    const { bookingId } = useParams<{ bookingId: string }>();
    return parseInt(bookingId);
}