import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './DashboardCard.module.css';
import AppButton from '../../Components/AppButton';
import { useIsMobileMedia } from '../../mediaQueries';

export interface CardProps {
    title: React.ReactNode,
    titleIcon: any,
    titleLinkText?: string,
    onTitleLinkClick?: () => void,
}
 
const DashboardCard: React.FC<CardProps> = ({
    title,
    titleIcon,
    titleLinkText,
    onTitleLinkClick,
    children,
}) => {
    const isMobile = useIsMobileMedia();

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={titleIcon} />
                <span>{title}</span>
                <div className={styles.spacer} />
                {titleLinkText &&
                    <AppButton
                    onClick={onTitleLinkClick}
                    variant="inverted"
                    size="small"
                    >
                    {isMobile ? '+' : titleLinkText}
                </AppButton>
                }
            </div>
            {children}
        </div>
    );
}
 
export default DashboardCard;