import React from 'react';
import styles from './AppInput.module.css';

export interface AppInputErrorProps {
    show?: boolean,
}
 
const AppInputError: React.FC<AppInputErrorProps> = ({
    show = true,
    children,
}) => {
    
    if (!show || !children) return null;

    return (
        <div className={styles.error}>{children}</div>
    )
}
 
export default AppInputError;