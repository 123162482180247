import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth';
import { pathBuilders } from '../../Routes';
import LoginForm, { LoginFormValues } from './LogInForm';

const LogInFormContainer = () => {
    const auth = useAuth();
    const history = useHistory();

    const handleLogin = async (values: LoginFormValues) => {
        return await auth.logIn(values.email, values.password);
    };
    
    return (
        <LoginForm
            login={handleLogin}
            onSuccess={() => history.push(pathBuilders.accountDashboard())}
        />
    );
};

export default LogInFormContainer;
