import React from "react";
import { useSelector } from "react-redux";

import {
  AppButton,
  ApiErrorLoaderWrapper,
  AppCardsLayout,
  AppButtons,
} from "../../Components";
import {
  actions,
  aggregateSelectors,
  selectors,
  useDispatchEffect,
} from "../../store";
import { Car } from "../../models";

import { useHistory } from "react-router-dom";
import { makeBookingPathBuilders } from "./makeBookingPaths";
import ViewCarsCard from "./ViewCarsCard";
import TotalPrice from "./TotalPrice";
import { useAuth } from "../../auth";
import { useLocalisation } from "../../Components/Localisation";

export interface ViewCarsProps {}

const ViewCars: React.FC<ViewCarsProps> = () => {
  const auth = useAuth();
  const history = useHistory();
  const { country } = useLocalisation();
  const carsApi = useSelector(selectors.customerCars.apiState);
  const allCars = useSelector(selectors.customerCars.all);
  const selectedCarIds = useSelector(selectors.makeBooking.selectedCarIds);
  const packageInfoApi = useSelector(selectors.packageInfo.apiState);
  const allCarsHavePackages = useSelector(
    selectors.makeBooking.selectAllCarsHavePackages
  );
  const totalPriceSummary = useSelector(
    aggregateSelectors.selectTotalBookingPriceSummary(country)
  );
  useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(1));
  useDispatchEffect(actions.customerCars.fetchIfRequired);

  const selectedCars = selectedCarIds.map(
    (carId) => allCars.find((c) => c.id === carId) as Car
  );

  const handleCarClick = (cardId: number) => {
    history.push(makeBookingPathBuilders.serviceTypes(cardId));
  };

  const handleChooseDateClick = () => {
    if (allCarsHavePackages) {
      history.push(makeBookingPathBuilders.chooseDate());
      return;
    }

    window.alert("Please select a package for every car first.");
  };

  const numberOfColumns = selectedCars.length > 1 ? '2' : '1';
  document.documentElement.style.setProperty(
    '--columnsOnTablet',
    numberOfColumns
    );

  return (
    <ApiErrorLoaderWrapper
      isLoading={carsApi.isLoading || packageInfoApi.isLoading}
      httpError={carsApi.apiError || packageInfoApi.apiError}
    >
      <AppCardsLayout>
        {selectedCars.map((car) => {
          return (
            car && (
              <ViewCarsCard
                key={car.id}
                car={car}
                onCarClick={handleCarClick}
              />
            )
          );
        })}
      </AppCardsLayout>
      <TotalPrice totalPrice={totalPriceSummary.grandTotal} />
      <AppButtons sideBySide>
        {!auth.isLoggedIn && (
          <AppButton
            onClick={() => history.push(makeBookingPathBuilders.addCar())}
          >
            Add Another Car
          </AppButton>
        )}
        <AppButton
          variant="inverted"
          onClick={handleChooseDateClick}
          displayDisabled={!allCarsHavePackages}
        >
          Choose Date &amp; Time
        </AppButton>
      </AppButtons>
    </ApiErrorLoaderWrapper>
  );
};

export default ViewCars;
