import React from 'react';

import styles from './PrivacyPolicy.module.css';

export const PrivacyPolicyPage: React.FC = () => {
    return (
        <div className={styles.content}>
            <h1 className={styles.title}>Privacy Policy</h1>
            <p>
                This privacy notice applies to visitors to our website, individuals who use our mobile application (referred to as “Application” throughout this policy) and any of you who decide to call us, e-mail us or contact us through any other means (including other electronic means).
            </p>
            <h2>
                1. Who we are
            </h2>
            <p>
                We are Fresh Car Valeting Limited, a company registered in Scotland (company number SC540938) whose registered office is at 29 Stafford Street. Edinburgh EH3 7BJ
                We are a controller for the purposes of the General Data Protection Regulation
            </p>
            <h2>
                2. How to contact us
            </h2>
            <p>
                If you have any questions about this privacy notice or our data protection policies generally, please contact us:
                <ul>
                    <li>
                        By post: 29 Stafford Street. Edinburgh EH3 7BJ
                    </li>
                    <li>
                        By email: clean@freshcarvaleting.com
                    </li>
                </ul>
            </p>
            <h2>
                3. Privacy notice
            </h2>
            <p>
                <ol>
                    <li>
                        We are committed to protecting your personal data and your privacy. This privacy notice sets out the basis on which any personal data we collect from you, or that you provide to us, or that we acquire through a third party, will be processed by us. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.
                    </li>
                    <li>
                        Before we process your personal data, we are obliged to inform you of who we are, why we need to process your personal data, what we will do with your personal data and to whom we will pass your personal data.
                    </li>
                    <li>
                        It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
                    </li>
                </ol>
            </p>
            <h2>
                4. Visitors to the website
            </h2>
            <p>
                <ol>
                    <li>
                        What information do we collect and process?
                        When you visit our website (as hosted on freshcarvaleting.com) (“Website“) or any other domain name registered in our name), we use cookies to automatically collect information about your operating system, including your IP address, information about your visit, pages visited and how you use our Website. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Website may become inaccessible or not function properly. You can read more about how we use cookies in our “Cookie” policy at freshcarvaleting.com/cookies.
                        Please note the Website is not intended for children and we do not knowingly collect personal data relating to children from the Website.
                    </li>
                    <li>
                        How do we use your information?
                        We use your information collected automatically from our Website to improve the customer experience and functionality of our Website. i.e. if we know how and why you are using our Website, we can make sure we’re providing you with the best possible experience.
                        We may use your personal data to provide you with online advertising but you can adjust or delete cookies on your browser at any time to prevent this (see our “Cookie” policy freshcarvaleting.com/cookies).
                    </li>
                    <li>
                        Which third parties can see your personal data?
                        Please see our “Cookie” policy freshcarvaleting.com/cookies
                    </li>
                    <li>
                        Third-party websites
                        Our Website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our Website, we encourage you to read the privacy notice of every website you visit.
                    </li>
                    <li>
                        What is the legal basis for processing your personal data?
                        When we process your personal data for the purposes of improving our Website, we do so on the basis of our legitimate interests being those business interests in maintaining a relevant and functioning website and improving customer experience.
                    </li>
                </ol>
            </p>
            <h2>
                5. People who use our products and services (our customers)
            </h2>
            <p>
                <ol>
                    <li>
                        What information do we collect and process?
                        <ol>
                            <li>
                                <h3>Telephone</h3>
                                When you call us, we will collect your name and telephone number, and where relevant, we may collect other details including other contact details and information relating to any contract for products or services relating to you.
                            </li>
                            <li>
                                <h3>Email</h3>
                                When you email us, we will collect your name, e-mail address and the contents of your message and any attachments.
                            </li>
                            <li>
                                <h3>Website contact forms</h3>
                                When you fill out any of our contact forms, we may collect:
                                <ul>
                                    <li>Your name</li>
                                    <li>Your email address</li>
                                    <li>Your address (if your query is such that we need to know where you are located)</li>
                                    <li>Your phone number (if we need to contact you to discuss your query in more detail)</li>
                                    <li>Your car registration, make and model</li>
                                </ul>
                                <h3>Post</h3>
                                If you send us a letter or other correspondence by post, we may collect any personal data contained in such correspondence.
                            </li>
                            <li>
                                <h3>Contract</h3>
                                Where you create an account with us to use our services or Application, we will collect personal data about you that is necessary for us to be able to perform the contract with you.
                            </li>
                            <li>
                                <h3>Application</h3>
                                When you download and use our Application we may collect:
                                <ul>
                                    <li>Your name</li>
                                    <li>Your email address</li>
                                    <li>Your location</li>
                                    <li>Your address</li>
                                    <li>Your phone number</li>
                                    <li>Your car registration, make and model</li>
                                </ul>
                                You must provide your consent for us to process your personal data with regard to certain features of our services. You may adjust your privacy settings at any time on the Application, but please note that certain features of our service may not be available to you as a result, for example if you do not allow us to collect your location data we will not be able to supply services that require us to process such data.
                            </li>
                            <li>
                                <h3>Anonymised Aggregated Data</h3>
                                We also collect, use and share anonymised aggregated data such as statistical or demographic data for any purpose. Aggregated anonymised data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your personal data to calculate the percentage of users accessing a specific Application feature. However, if we combine or connect anonymised aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.
                            </li>
                        </ol>
                    </li>
                    <li>
                        How do we use your information?
                        <ol>
                            <li>
                                We may use any information obtained by telephone, e-mail, Website /Application, contact forms, business cards or other media to handle customer and other enquiries, including addressing any complaints. This may include contacting you to discuss any such enquiries or complaints.
                            </li>
                            <li>
                                We may use any information obtained by telephone, e-mail, Website/Application or other media to provide our products and services to you.
                            </li>
                            <li>
                                We may monitor e-mails and other electronic messages for security reasons. We use e-mail monitoring or blocking software (spam filters).
                            </li>
                            <li>
                                We may also use any personal data obtained by telephone, e-mail, Website/Application or other media to improve our internal processes, including staff training and quality control.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Which third parties can see your personal data?
                        <ol>
                            <li>
                                We may use third parties to develop or provide our services, and these third parties will have access to your personal data that we store. Such third parties are data processors and will only process your personal data on our instructions and subject to obligations of confidentiality. Please see section 13 for full details of these third parties.
                            </li>
                        </ol>
                    </li>
                    <li>
                        What happens if you don’t give us your personal data?
                        Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with an mobile valet we would need to know your address, car details and contact date). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.
                    </li>
                    <li>
                        What is the legal basis for processing your personal data?
                        <ol>
                            <li>
                                When we process your personal data in connection with telephone calls, e-mails, Website/Application or other media, we do so on the basis of our legitimate interests being those business interests in responding to customer and other queries and in creating and retaining records of such communications for training purposes and so that we have a record of the content of any communications with you.
                            </li>
                            <li>
                                Where you are an existing or prospective customer, we process your personal data arising from telephone calls, e-mails, Website/Application or media on the basis of meeting the terms of a contract with you (or steps necessary to enter into such a contract in the case of prospective customers) where such communications are relevant to such a contract. In essence, this means we need to process your personal data to provide you with access to our services and software.
                            </li>
                            <li>
                                We may use anonymised and aggregated personal data to innovate and improve our products so that we give you the best customer experience we possibly can.
                            </li>
                        </ol>
                    </li>
                </ol>
            </p>
            <h2>
                6. Personal data of children
            </h2>
            <p>
                Due to the nature of our business we do not anticipate collecting of data from children.
            </p>
            <h2>
                7. How long do we retain your data?
            </h2>
            <p>
                We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
            </p>
            <h2>
                8. Marketing
            </h2>
            <p>
                If you are an individual consumer, we will only provide you with direct marketing communications where you have consented to receive such communications or you have contacted us directly to request specific information about our products and services. You can subscribe to such marketing communications, and you can adjust your marketing preferences at any time by contacting us at clean@freshcarvaleting.com
                If you represent another business, we may provide you with direct marketing communications where we feel that this may be relevant to your business (provided that you have not opted out of such communications). When we use your personal data for such purposes, we do so on the basis that it is in our business interests to pursue direct marketing, provided that it constitutes fair processing of your personal data to do so.
                You can also opt-out or unsubscribe from all or some of these marketing communications at any time by contacting us at clean@freshcarvaleting.com
                Where you opt out of receiving these marketing communications, this opt-out will not apply to personal data provided to us for any other purpose (for example your personal data which we require to carry out a contract with you).
            </p>
            <h2>
                9. Additional Information
            </h2>
            <p>
                <ol>
                    <li>
                        Compliance with legal obligations, etc.
                        We may process (including disclose) your personal data in order to comply with any legal obligations binding on us; to protect the rights, property, or safety of our staff, our customers, or others; and to establish, exercise or defend our legal rights.
                        When we process your personal data for the above purposes, we do so on the basis that such processing is necessary for compliance with a legal obligation to which we are subject or necessary in order to protect the vital interests of a natural person, respectively or necessary in order to pursue our business interests in exercising our legal rights.
                    </li>
                    <li>
                        Corporate governance
                        We may share your personal data with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries.
                        We may disclose your personal data to third parties:
                        <ol>
                            <li>
                                in the event that we sell or buy any business or assets, in which case we may if relevant disclose your personal data to the prospective seller or buyer of such business or assets; or
                            </li>
                            <li>
                                if we or substantially all of our assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets.
                            </li>
                        </ol>
                        When we process your personal data for the above purposes, we do so on the basis that we have an interest in pursuing business aims and objectives.
                    </li>
                </ol>
            </p>
            <h2>
                10. Data transfers
            </h2>
            <p>
                <ol>
                    <li>
                        We do not transfer personal data outside of the European Economic Area (EEA) except with regard to customer information which is processed and held by Mailchimp, Hubspot, Livechat, Ringcentral. For further information on these companies, please see section 13 below.  Where we do transfer personal data outside the EEA, we will ensure that appropriate safeguards are put in place to protect such transfers.
                    </li>
                    <li>
                        Where you use the Application in a country outside the EEA, your personal data will be transferred to and processed in the country from where you have accessed it. You acknowledge and agree that such a transfer of your personal data out with the EEA is entirely your responsibility.
                    </li>
                </ol>
            </p>
            <h2>
                11. Data Security
            </h2>
            <p>
                <ol>
                    <li>
                        We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to only named employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
                    </li>
                    <li>
                        We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
                    </li>
                </ol>
            </p>
            <h2>
                12. Your rights
            </h2>
            <p>
                <ol>
                    <li>
                        Your personal data is protected by legal rights, which include your rights:
                        <ol>
                            <li>
                                <h3>Request access to your personal data</h3>
                                (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                            </li>
                            <li>
                                <h3>Request correction of the personal data that we hold about you</h3>
                        This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                            </li>
                            <li>
                                <h3>Request erasure of your personal data.</h3>
                                This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                            </li>
                            <li>
                                <h3>Object to processing of your personal data</h3>
                                Where we are relying on a business interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes.
                            </li>
                            <li>
                                <h3>Request restriction of processing of your personal data</h3>
                                This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                            </li>
                            <li>
                                <h3>Request the transfer of your personal data to you or to a third party</h3>
                                We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                            </li>
                            <li>
                                <h3>Withdraw consent at any time where we are relying on consent to process your personal data</h3>
                                However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                            </li>
                        </ol>
                    </li>
                    <li>
                        If you want to exercise any of these rights, please contact us using the details in section 2.
                    </li>
                    <li>
                        You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
                    </li>
                    <li>
                        You also have the right to complain to the Information Commissioner’s Office, which regulates the processing of personal data, about how we are processing your personal data.
                    </li>
                </ol>
            </p>
            <h2>
                13. Third parties with which we share personal data
            </h2>
            <p>
                <ol>
                    <li>
                        <h3>GOOGLE ANALYTICS</h3>
                        This cookie allows us to see information on user website activities including, but not limited to page views, source and time spent on the website. The information is depersonalised and is displayed as numbers, meaning it cannot be traced back to individuals. This will help to protect your privacy. Using Google Analytics we can see what content is popular on our website, and strive to give you more of the things you enjoy reading and watching.
                    </li>
                    <li>
                        <h3>HUBSPOT</h3>
                        Hubspot is our customer relationship management system. It stores customer email addresses, contact names and addresses to allow us to provide the Products and Services to you.
                    </li>
                    <li>
                        <h3>LIVECHAT</h3>
                        Livechat is our customer support management platform. In order to provide customer support services to you we share your name, email address, IP address and address with Livechat
                    </li>
                    <li>
                        <h3>DOUBLE CLICK BID MANAGER</h3>
                        We use remarketing codes to log when users view specific pages, allowing us to provide targeted advertising in the future.
                    </li>
                    <li>
                        <h3>HOTJAR</h3>
                        Personal Data collected: Cookies, Usage Data and various types of Data as specified in the privacy policy of the service.
                    </li>
                    <li>
                        <h3>CALL TRACKING</h3>
                        This is used to set dynamic phone numbers which help us identify how you found the Dropless website when you call us. This allows us to identify the source that you used to find the Dropless website and helps us control our advertising methods in the future.
                    </li>
                    <li>
                        <h3>RINGCENTRAL</h3>
                        Ringcentral is our telephone based customer support management platform. In order to provide customer support services to you we share your name, location  phone number, IP address with Ringcentral
                    </li>
                    <li>
                        <h3>STRIPE</h3>
                        We use Stripe to take payments from our central office and collect your full name, card details and email address for the purposes of payment transactions and sending you receipts.
                    </li>
                    <li>
                        <h3>MAILCHIMP</h3>
                        We may use Mailchimp for marketing and communications purposes and collect your full name, email, IP address and information otherwise customised based on your selected preferences.
                    </li>
                    <li>
                        <h3>CLEAR SKY LOGIC</h3>
                        Clear Sky Logic is the company we use to develop and support our website and Application technologies and do not collect data directly but have access to our databases for the purposes of internal development and maintenance only.
                    </li>
                    <li>
                        <h3>FACEBOOK/INSTAGRAM</h3>
                        We use Facebook and Instagram for marketing and communication purposes and collect enquiry data that you supply such as your name, email and car details.   We also collect IP addresses, Cookies and Usage Data.
                    </li>
                    <li>
                        <h3>YOUTUBE</h3>
                    </li>
                </ol>
            </p>
            <h2>
                14. How to close your account with us
            </h2>
            <p>
                All users of our products and services are able to close the accounts they have with us by emailing clean@freshcarvaleting.com.  When we receive a request from you to close your account we will delete all of the personal data we hold on our products and services but we may retain limited personal data.  We may retain personal information relating to your initial purchase of our products and services.
            </p>
        </div>
    );
}
