import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../store';
import { useCarId } from './makeBookingPaths';
import styles from './PackageSelectTitle.module.css';

export interface PackageSelectSubtitleProps {
    message: string
}

const PackageSelectTitle: React.SFC<PackageSelectSubtitleProps> = ({
    message
}) => {
    const carId = useCarId();
    const selectCar = selectors.customerCars.makeSelectCarById(carId);
    const selectedCar = useSelector(selectCar);
    
    return (
        <div className={styles.root}>
            <h1>{selectedCar?.makeAndModel}</h1>
            <p>{message}</p>
        </div>
    );
}

export default PackageSelectTitle;