import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../auth";
import { useLocalisation } from "../../Components/Localisation";
import { pathBuilders } from "../../Routes";
import {
  actions,
  aggregateSelectors,
  selectConfirmationSummaryCars,
  selectors,
  useDispatchEffect,
} from "../../store";
import EnquiryConfirmation from "./EnquiryConfirmation";

const EnquiryConfirmationContainer: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { country } = useLocalisation();
  const createdBookingStatusId = useSelector(
    selectors.makeBooking.selectCreatedBookingStatusId
  );
  const cars = useSelector(selectConfirmationSummaryCars);
  const totalPriceSummary = useSelector(
    aggregateSelectors.selectTotalBookingPriceSummary(country)
  );
  const bookingDetails = useSelector(
    selectors.makeBooking.selectBookingDetails
  );
  useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(3));

  useEffect(() => {
    history.push(
      auth.isLoggedIn ? pathBuilders.accountDashboard() : pathBuilders.logIn()
    );
    history.push(routeMatch.url);
  }, [auth.isLoggedIn, history, routeMatch.url]);

  const handleReturnToDashboardClicked = () =>
    history.push(pathBuilders.accountDashboard());

  return (
    <EnquiryConfirmation
      statusId={createdBookingStatusId}
      onReturnToDashboardClicked={handleReturnToDashboardClicked}
      cars={cars}
      totalPrice={totalPriceSummary.grandTotal}
      isPrepaid={bookingDetails.paymentOption === "isPayNow"}
    />
  );
};

export default EnquiryConfirmationContainer;
