import React from "react";
import classnames from "classnames";

import styles from "./TotalPrice.module.css";
import { useLocalisation } from "../../Components/Localisation";
import { formatPriceWithCurrencySymbol } from "../../priceHelpers";

export interface TotalPriceProps {
  hideBottomBorder?: boolean;
  style?: React.CSSProperties;
  totalPrice: number;
  isPrepaid?: boolean;
}

const TotalPrice: React.FC<TotalPriceProps> = ({
  hideBottomBorder,
  style,
  totalPrice,
  isPrepaid,
}) => {
  const { currency } = useLocalisation();
  let formattedPrice = formatPriceWithCurrencySymbol(
    totalPrice,
    currency.symbol
  );

  if (isPrepaid) {
    formattedPrice += " (Paid)";
  }

  return (
    <div
      className={classnames({
        [styles.root]: true,
        [styles.hideBottomBorder]: hideBottomBorder,
      })}
      style={style}
    >
      <p>Total Cost</p>
      <p>{formattedPrice}</p>
    </div>
  );
};

export default TotalPrice;
