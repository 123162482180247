import React from 'react';
import styles from './AppStripeInputContainer.module.css';

const AppStripeInputContainer: React.FC = ({
    children
}) => {
    return (
        <div className={styles.inputContainer}>
            {children}
        </div>
    );
};

export default AppStripeInputContainer;