import { useSelector } from "react-redux";
import classnames from "classnames";
import styles from "./BookingsList.module.css";
import { selectors } from "../../store";
import { CustomerCarBooking } from "../../models/Bookings";
import React from "react";
import {
  Currency,
  currencySymbol,
  exchangeRate,
} from "../../Components/Localisation";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";

interface BookingCarPackageDetailProps {
  car: CustomerCarBooking;
  totalCost: number;
  isPrepaid: boolean;
  currency: Currency;
  isEuros: boolean;
}

const BookingCarPackageDetail: React.FC<BookingCarPackageDetailProps> = ({
  car,
  totalCost,
  isPrepaid,
  currency,
    isEuros,
}) => {
  const packageInfo = useSelector(
    selectors.packageInfo.makeSelectPackageById(car.packageId)
  );
  const currencyDetails = {
    currency,
    symbol: currencySymbol[currency],
    exchangeRate: exchangeRate[currency],
  };
  const adjustedPrice = exchangeRateAdjustedPrice(
    totalCost ?? 0,
    currencyDetails.exchangeRate
  );
  const priceToDisplay = formatPriceWithCurrencySymbol(
    isEuros ? totalCost : adjustedPrice,
    currencyDetails.symbol
  );

  return (
    <div className={classnames(styles.bookingCarPackageDetails)}>
      <div style={{ flexShrink: 1 }}>
        <h4>
          {packageInfo?.name} {isPrepaid ? "(Prepaid)" : ""}
        </h4>
        <p key={car.id}>
          {car.makeAndModel}{" "}
          {car.registrationNumber ? `(${car.registrationNumber})` : ""}
        </p>
      </div>
      {totalCost ? (
        <p className={classnames(styles.bookingPrice)}>{priceToDisplay}</p>
      ) : ""}
    </div>
  );
};

export default BookingCarPackageDetail;
