import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from './BookingOverview.module.css';
import { Booking } from "../../models";
import { pathBuilders } from "../../Routes";
import BookingOverviewCard from "./BookingOverviewCard";
import { useEffect } from "react";
import AppLoader from "../../Components/AppLoader";
import { getBookingById } from "../../api";

export interface BookingOverviewProps {

}

export const useBookingIdParams = (): number => {
    const { bookingId } = useParams<{ bookingId: string }>();
    return parseInt(bookingId);
};

export const BookingOverview: React.FC<BookingOverviewProps> = () => {
    const id = useBookingIdParams()
    const history = useHistory();
    const [booking, setBooking] = useState<Booking>()

    const getBooking = async () => {
        const response = await getBookingById(id);
        if (!response.isError) {
            setBooking(response.content)
        }
    }

    useEffect(() => {
        getBooking()
    }, [])

    const backToDashboard = () => history.push(pathBuilders.accountDashboard());

    return (
        <>
            <div className={styles.root}>
                {booking ? (
                    <BookingOverviewCard
                        booking={booking}
                        backToDashboard={backToDashboard}
                    />
                ) : (
                    <AppLoader />
                )}
            </div>
        </>
    );
}
