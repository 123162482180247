import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Pages from './pages';

export const pathBuilders = {
    logIn: () => '/logIn',
    logInAs: () => '/logInAs',
    register: () => '/register',
    accountDashboard: () => '/account-dashboard',
    accountManagement: () => '/account-management',
    addCar: () => '/add-car',
    addAddress: () => '/add-address',
    makeBooking: () => '/make-booking',
    testAuth: () => '/test-auth',
    forgottenPassword: () => '/forgotten-password',
    resetPassword: () => '/reset-password',
    privacyPolicy: () => '/privacy-policy',
    termsAndConditions: () => '/terms-conditions',
    carClubTermsAndConditions: () => '/car-club-terms-conditions',
    bookingOverview: (bookingId: string | number): string => `/booking-overview/${bookingId}`
};

const Routes = () => {
    return (
        <Switch>
            <Route path={pathBuilders.register()}>
                <Pages.RegisterPage />
            </Route>
            <Route path={pathBuilders.logIn()}>
                <Pages.LoginPage />
            </Route>
            <Route path={pathBuilders.logInAs()}>
                <Pages.LogInAsPage />
            </Route>
            <Route path={pathBuilders.accountDashboard()}>
                <Pages.AccountDashboard />
            </Route>
            <Route path={pathBuilders.accountManagement()}>
                <Pages.AccountManagement />
            </Route>
            <Route path={pathBuilders.bookingOverview(':bookingId')}>
                <Pages.BookingOverview />
            </Route>
            <Route path={pathBuilders.addCar()}>
                <Pages.AddCarPage />
            </Route>
            <Route path={pathBuilders.addAddress()}>
                <Pages.AddAddressPage />
            </Route>
            <Route path={pathBuilders.testAuth()}>
                <Pages.TestAuthPage />
            </Route>
            <Route path={pathBuilders.makeBooking()}>
                <Pages.MakeBookingPage />
            </Route>
            <Route path={pathBuilders.forgottenPassword()}>
                <Pages.ForgotPasswordPage />
            </Route>
            <Route path={pathBuilders.resetPassword()}>
                <Pages.ResetPasswordPage />
            </Route>
            <Route path={pathBuilders.privacyPolicy()}>
                <Pages.PrivacyPolicyPage />
            </Route>
            <Route path={pathBuilders.termsAndConditions()}>
                <Pages.TermsAndConditions />
            </Route>
            <Route path={pathBuilders.carClubTermsAndConditions()}>
                <Pages.CarClubTermsAndConditions />
            </Route>
            <Redirect to={pathBuilders.logIn()} />
        </Switch>
    );
};

export default Routes;
