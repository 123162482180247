import React from 'react';
import { AppCard, AppCardsLayout, AppCardTitle } from '../Components';
import { ResetPasswordForm } from '../Components/ResetPasswordForm';
 
export const ResetPasswordPage: React.FC = () => {
    return (
        <AppCardsLayout>
            <AppCard>
                <AppCardTitle>Reset Password</AppCardTitle>
                <ResetPasswordForm />
            </AppCard>
        </AppCardsLayout>
    );
};