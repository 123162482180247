import React from 'react';
import { HttpClientResponse } from '../../api';

import { CarLookup } from '../../models';
import styles from './CarLookupResult.module.css';

interface CarLookupResultProps {
    carLookupResponse: HttpClientResponse<CarLookup>,
}

const CarLookupResult: React.FC<CarLookupResultProps> = ({
    carLookupResponse
}) => {
    const renderRow = (label: string, data: string) => (
        <tr>
            <td>{label}:</td>
            <td>{data}</td>
        </tr>
    );

    if (carLookupResponse.isError) {
        return carLookupResponse.statusCode === 404 ?
        (
            <p style={{ textAlign: 'center' }}>No car found matching your numberplate.</p>
        ) :
        (
            <>
                <h5 style={{ textAlign: 'center' }}>Sorry there was an error</h5>
                <p style={{ textAlign: 'center' }}>{carLookupResponse.message}</p>
            </>
        );
    }
    
    const carLookup = carLookupResponse.content;

    return (
        <>
            <h3 className={styles.isThisYourCar}>Is this your car?</h3>
            <table className={styles.table}>
                <tbody>
                    {renderRow('Make and Model', carLookup.makeAndModel)}
                    {renderRow('Colour', carLookup.colour)}
                    {renderRow('Year', carLookup.year)}
                    {renderRow('Body', carLookup.category)}
                </tbody>
            </table>
        </>
    );
}

export default CarLookupResult;