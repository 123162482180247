import { configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash.throttle';

import * as customerCars from './customerCars';
import * as customerAddresses from './customerAddresses';
import * as makeBooking from './makeBooking';
import * as existingBookings from './existingBookings';
import * as preferredValeters from './preferredValeters';
import * as packageInfo from './packageInfo';
import * as cancellationReasons from './cancellationReasons';
import sessionStorageKeys from '../sessionStorageKeys';

const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem(sessionStorageKeys.reduxState);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.error('Error saving redux state:', error);
        return undefined;
    }
};

const saveState = (state: RootState) => {
    try {
        const stateToSave = {
            [customerCars.sliceName]: customerCars.selectStateToSave(state),
            [makeBooking.sliceName]: makeBooking.selectStateToSave(state),
        };

        const serializedState = JSON.stringify(stateToSave);
        sessionStorage.setItem(sessionStorageKeys.reduxState, serializedState);
    } catch (error) {
        console.error('Error saving redux state:', error);
        return undefined;
    }
};

const store = configureStore({
    reducer: {
        [customerCars.sliceName]: customerCars.reducer,
        [customerAddresses.sliceName]: customerAddresses.reducer,
        [makeBooking.sliceName]: makeBooking.reducer,
        [packageInfo.sliceName]: packageInfo.reducer,
        [existingBookings.sliceName]: existingBookings.reducer,
        [preferredValeters.sliceName]: preferredValeters.reducer,
        [cancellationReasons.sliceName]: cancellationReasons.reducer,
    },
    preloadedState: loadState(),
});

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000));

export type RootState = ReturnType<typeof store.getState>

export const actions = {
  customerCars: customerCars.actions,
  customerAddresses: customerAddresses.actions,
  makeBooking: makeBooking.actions,
  packageInfo: packageInfo.actions,
  existingBookings: existingBookings.actions,
  preferredValeters: preferredValeters.actions,
  cancellationReasons: cancellationReasons.actions,
};

export * from './selectors';
export * from './common/useDispatchEffect';

export default store;