import React from "react";

import styles from "./TermsAndConditions.module.css";

export const TermsAndConditions: React.FC = () => {
  return (
    <div className={styles.content}>
      <img
        style={{ height: "120px", display: "block", margin: "0 auto" }}
        src="/assets/images/logo/logo.png"
        alt="Mobile Car Valeting in Edinburgh & Glasgow | Fresh Car Valeting"
      />
      <h3>Last updated 31 Oct 2023</h3>
      <h3>
        <b>
          <span>INTRODUCTION</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Company:</span>
        </b>
        <span>
          Fresh Car Valeting Limited, a company registered in Scotland (company
          number <span>SC540938</span>) whose registered office is at 29
          Stafford Street. Edinburgh EH3 7BJ
        </span>
      </p>

      <p>
        <b>
          <span>Service:</span>
        </b>
        <span>
          {" "}
          The Company provides a platform for you to communicate your booking (
          <b>&quot;booking&quot;</b>) for a car valeting (
          <b>&quot;Service&quot;</b>) to car valeting operators (
          <b>&quot;Operators&quot;</b>) posted on the Website (
          <b>&quot;Platform&quot;</b>).
        </span>
      </p>

      <p>
        <span>
          The Company does not provide Car Valeting Services and the Company is
          not a car valeting operator. It is up to the third party operators to
          offer car valeting services which may be scheduled through the use of
          the Website or Service. The Company offers information and a method to
          obtain such 3rd party car valeting services, but does not provide car
          valeting services or act in any way as a car valeting service
          provider, and has no responsibility or liability for any car valeting
          services provided to you by third party operators. In the event that
          you have a dispute with one or more operators, you release Fresh Car
          Valeting Limited (and our officers, directors, agents, investors,
          subsidiaries, and employees) from any and all claims, demands, or
          damages (actual or consequential) of every kind and nature, known and
          unknown, suspected and unsuspected, disclosed and undisclosed, arising
          out of or in any way connected with such disputes.
        </span>
      </p>

      <p>
        <span>
          Your use of the Platform constitutes your acceptance of and agreement
          to all of the terms and conditions in these Terms and Conditions and
          Privacy Policy and your representation that you are at least 18 years
          of age and are legally capable of entering into binding contracts.
        </span>
      </p>

      <h3>
        <b>
          <span>THE WEBSITE</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Terms:</span>
        </b>
        <span>
          {" "}
          By accessing any part of the Platform, you indicate that you accept
          these Platform Terms.
        </span>
      </p>

      <p>
        <b>
          <span>Amendment of terms:</span>
        </b>
        <span>
          {" "}
          The Platform Terms are subject to change at any time, and effective
          upon posting on the Platform. It is advised you check back regularly
          in the case of any amendments.
        </span>
      </p>

      <p>
        <b>
          <span>Website access:</span>
        </b>
        <span>
          {" "}
          You may access some areas of the Website without making a booking or
          registering your details with us. Most areas of the Website are open
          to everyone.
        </span>
      </p>

      <p>
        <b>
          <span>Responsibility:</span>
        </b>
        <span>
          {" "}
          You are responsible for making all arrangements necessary for you to
          have access to the Website. You are also responsible for ensuring that
          all persons who access the Website through your Internet connection
          are aware of these Website Terms and that they comply with them.
        </span>
      </p>

      <p>
        <b>
          <span>Website availability:</span>
        </b>
        <span>
          {" "}
          While we try to ensure the Website is normally available twenty four
          hours a day, we do not undertake any obligation to do so, and we will
          not be liable to you if the Website is unavailable at any time or for
          any period.
        </span>
      </p>

      <p>
        <b>
          <span>Information security:</span>
        </b>
        <span>
          {" "}
          The transmission of information via the internet is not completely
          secure. Although we take the steps required by law to protect your
          information, we cannot guarantee the security of your data transmitted
          to the Website; any transmission is at your own risk.
        </span>
      </p>

      <h3>
        <b>
          <span>GOVERNING LAW AND JURISDICTION</span>
        </b>
      </h3>

      <p>
        <span>
          These Website Terms shall be governed by and construed in accordance
          with English law. Disputes or claims arising in connection with these
          Website Terms (including non-contractual disputes or claims) shall be
          subject to the exclusive jurisdiction of the English courts.
        </span>
      </p>

      <h3>
        <b>
          <span>MAKING A BOOKING</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Operator Terms and Conditions:</span>
        </b>
        <span>
          {" "}
          By placing a booking and having it accepted by an Operator you are
          entering into an agreement between yourself and the Operator company
          that accepted the booking.
        </span>
      </p>

      <p>
        <b>
          <span>Making a booking:</span>
        </b>
        <span>
          {" "}
          Enter your vehicle location using the post code finder. Enter the
          details of your vehicle and select your package requirements. Once
          completed proceed to payment options. It is important you check that
          all your details are correct as once payment has been made there is no
          guarantee that the booking can be amended or cancelled without a cost
          implication.{" "}
        </span>
      </p>

      <p>
        <b>
          <span>Making a booking enquiry:</span>
        </b>
        <span>
          We offer live automated availability in most of our covered postcodes.
          However, certain postcode locations are not automate
        </span>
        <span>
          d.. For these postcodes, please note you are sending an enquiry, and
          not obtaining a confirmed booking. This is explicitly displayed on our
          calendar view. A member of our Customer Service team will be in touch
          with you to offer availability.
        </span>
      </p>

      <p>
        <b>
          <span>Booking Amendment and Cancellation:</span>
        </b>
        <span>
          {" "}
          Once you have submitted your booking and your payment has be
        </span>
        <span>
          en authorised, you may cancel and amend your booking up to 24 hours
          before the start of the booking window and you can do this within your
          customer portal. If you wish to cancel or amend your booking, you may
          also contact our customer service department by email if you are
          unable to cancel the booking yourself, although we cannot guarantee
          your cancellation request will be attended to in a time frame which
          would not incur cancellation fees. You will receive written
          confirmation of your booking being cancelled. Until you receive
          written confirmation of your booking being cancelled, it is understood
          the Agreement between yourself and the Operator is still in place.
        </span>
      </p>

      <p>
        <span>
          In the event that you cancel your booking, cancellation fees will be
          charged as detailed below:
        </span>
      </p>

      <ul>
        <li>
          Job cancelled after our valeter has arrived at the location, or within
          one hour of the specified time: <u>100% of the agreed price</u>
        </li>

        <li>
          Within 24 hours of the appointment start time:{" "}
          <u>50% of the agreed price with a</u> <u>minimum of £20</u>
        </li>
      </ul>

      <p>
        <b>
          <span>Service Delivery and Timings:</span>
        </b>
        <span>
          Estimated times for operator arrival and Service completion are
          provided by the Operators and are only estimates. Neither we nor the
          Operators guarantee that bookings will be completed within the
          estimated times.
        </span>
      </p>

      <p>
        <span>
          Our arrival time might vary slightly depending on road conditions. Our
          representative will be in touch if they are delayed by more than 30
          minutes. If it has been more than 30 minutes, please contact us by
          phone, email or through our live chat and a member of our Customer
          Service team will get back to you (subject to office opening hours).
        </span>
      </p>

      <p>
        <span>
          You are entitled to cancel your appointment free of charge if the
          Operator is delayed by more than 30 minutes and has not been in touch
          with you on the contact details provided on the booking.{" "}
        </span>
      </p>

      <p>
        <span>
          If the Operator has informed you that he will be delayed by more than
          30 minutes (providing a new arrival time) and you decide not to cancel
          your appointment at this stage, the Operator will consider the booking
          is going ahead. Cancellation on arrival will not be refundable if the
          Operator was in touch and you confirmed the appointment could go
          ahead.
        </span>
      </p>

      <p>
        <b>
          <span>Making your car available:</span>
        </b>
        <span>
          When entering into this agreement, you understand that it is your
          responsibility to make your vehicle available. This includes making
          your vehicle keys available, having your vehicle in working order (no
          flat battery, etc), providing enough space for our van to park, etc.{" "}
        </span>
      </p>

      <p>
        <span>
          The Operator will not accept liability for any issue arising from the
          Customer leaving keys unattended for the Operator to collect.
        </span>
      </p>

      <p>
        <span>
          To help ensure we complete the work within the allocated time, please
          ensure all personal belongings have been removed from the vehicle.
        </span>
      </p>

      <p>
        <b>
          <span>Customer's presence on the day:</span>
        </b>
      </p>

      <p>
        <span>
          Should the Customer not be able to be physically present at the time
          of the appointment and another person is representing them on the day,
          we will consider this person to hold the necessary authority to make
          decisions on their behalf. For example, should the services to be
          provided be discussed before the clean and agreed by the
          representative of the Customer, we will consider this decision to be
          final. Should any complaint be issued in regards to the decision taken
          by the representative of the Customer, the Operator will not be held
          liable.
        </span>
      </p>

      <p>
        <span>
          Should no one be present on the day, we ask that the Customer is
          contactable over the phone to discuss any point that might need to be
          raised by the Operator. Should the Operator be unable to contact the
          Customer due to the Customer's details not being correct or the
          Customer not responding, the Operator can't be held liable for any
          decision that has to be made about the package and delivery.
        </span>
      </p>

      <p>
        <b>
          <span>Working conditions</span>
        </b>
        <span>: </span>
      </p>

      <p>
        <span>
          We ask that a safe and legal working environment is provided to the
          Operator. This does <u>not</u>
          include commercial car parks or shopping centres, resident parking you
          do not have rightful access to, a busy/unsafe road, caravan sites,
          etc. Using a car park which the Operator needs to pay to gain access
          to is not acceptable.{" "}
        </span>
      </p>

      <p>
        <span>
          Should the vehicle be parked in a location that is not adapted to
          safely carry out the work, we will request that The Customer move the
          car to another location - please note the Operator is not allowed to
          move your vehicle for you and the time taken to move the vehicle will
          be deducted from the package time. Should there not be any other
          suitable location, full cancellation fees will apply. It is at the
          sole discretion of the Operator to refund the appointment or
          reschedule free of charge.
        </span>
      </p>

      <p>
        <b>
          <span>Weather conditions</span>
        </b>
      </p>

      <p>
        <span>
          Poor weather conditions might sometimes prevent the Operator from
          going ahead with some services included in your package. In such
          instances, we will look at either rescheduling the whole appointment
          or do as much as possible on the day and come back another day to
          finish the valet. We leave it to the discretion of the Operator to
          decide what is doable on the day and to discuss it with you directly.
          Any change to your appointment for weather reasons will not incur any
          charge.
        </span>
      </p>

      <p>
        <b>
          <span>Vouchers</span>
        </b>
      </p>

      <p>
        <span>
          Our vouchers can be redeemed for any service booked online or over the
          phone. Vouchers can be redeemed in the 12 months following the date of
          purchase. Vouchers can only be redeemed in a postcode area that is
          currently covered by our operatives. To check we cover your location,
          please check our website's location finder. If a booking value is
          lower than the value of the voucher to be redeemed against it, we are
          not in a position to refund the difference.
        </span>
      </p>

      <p>
        <b>
          <span>DISCLAIMER:</span>
        </b>
        <span> </span>
      </p>

      <p>
        <b>
          <span>Package Price</span>
        </b>
      </p>

      <p>
        <span>
          Fresh Car Valeting reserves the right to adjust the price quotation or
          cancel a booking, subject to inspection of the car. In the event the
          package booked is not adapted to the level of care required for the
          vehicle, we reserve the right to change the appointment. The Operator
          will either{" "}
        </span>
      </p>

      <p>
        <span>
          -determine what can be done in the time booked and request your
          approval,{" "}
        </span>
      </p>

      <p>
        <span>-offer to book a longer valet </span>
      </p>

      <p>
        <span>
          -or cancel and provide a refund if the booking was prepaid (please
          note a call out charge of £15 to cover for fuel expenses will be
          kept).{" "}
        </span>
      </p>

      <p>
        <span>
          In the case you agree to a longer valet and you agree to the price
          increase attached to it, the Operator will either provide the valet on
          the day (if time allows) or offer to reschedule your appointment to
          another day.
        </span>
      </p>

      <p>
        <b>
          <span>Baby Seats</span>
        </b>
      </p>

      <p>
        <span>
          Should baby seats need to be removed as part of an appointment, please
          note that our representative is NOT responsible for securely fastening
          them back into place. Please also note that baby seats are not cleaned
          as part of standard packages. Please speak directly to your operator
          when they arrive if you would like to discuss this further.{" "}
        </span>
      </p>

      <p>
        <b>
          <span>Car size</span>
        </b>
      </p>

      <p>
        <b>
          <span>
            Prices quoted on our website are based on the size of the vehicle
            declared. Should the vehicle be different on the day, the Operator
            reserves the right to amend the price on the day prior to carrying
            out the appointment.
          </span>
        </b>
      </p>

      <p>
        <span>
          If your vehicle is a minibus or converted campervan, please contact us
          for a bespoke quote. If your vehicle is a pick-up truck or 4x4 type of
          vehicle, this automatically is considered a Cat C (Large) vehicle and
          can be charged accordingly on the day.
        </span>
      </p>

      <p>
        <span>
          Prices quoted are for a maximum of seven seats, and do not apply to
          vehicles which are not cars. Your quote might need to be readjusted if
          your vehicle has more than seven seats or is a bigger vehicle.
        </span>
      </p>

      <p>
        <b>
          <span>Chemicals</span>
        </b>
      </p>

      <p>
        <span>
          The products used for our regular valeting packages are car-safe, but
          not food-safe. As such, our valeting options DO NOT include any living
          quarters in motorhomes, camper vans, HGVs, etc. Some of our operators
          do provide cleaning services for the interior of such vehicles and a
          specific request must be addressed{" "}
        </span>
        <span>
          <a href="https://www.freshcarvaleting.com/caravan-valeting/">
            <span>at this link</span>
          </a>
        </span>
        <span>.</span>
      </p>

      <p>
        <b>
          <span>Health &amp; Safety on the job</span>
        </b>
      </p>

      <p>
        <span>
          For health and safety reasons, our representatives are not allowed on
          ladders. As such, the roof of tall vehicles would not be valeted.{" "}
        </span>
      </p>

      <h4>
        <b>
          <span>PAYMENT:</span>
        </b>
      </h4>

      <p>
        <b>
          <span>Payment</span>
        </b>
        <span>
          {" "}
          The Company uses an internationally recognised company called Stripe
          to process all payments. Payment for bookings must be made by an
          accepted credit or debit card through the Platform.
        </span>
      </p>

      <p>
        <b>
          <span>Card payments:</span>
        </b>
        <span>
          {" "}
          Please note that from time to time there may be delays with the
          processing of card payments and transactions; this may result in
          payments taking up to (60) days to be deducted from your bank account
          or charged to your credit or debit card. All card payments are
          processed by Fresh Car Valeting LTD, please contact customer support
          should you have any payment related queries. Upon placing a booking,
          the operator can request to view the card used for payment. The
          operator and Fresh Car Valeting reserve the right to cancel the
          booking should the customer fail to produce the card used for payment.
        </span>
      </p>

      <p>
        <b>
          <span>Cancelled bookings:</span>
        </b>
        <span>
          {" "}
          Due to standard banking procedures, once you have submitted a booking
          that you are paying for by credit or debit card and your payment has
          been authorised, your bank or card issuer will &quot;ring-fence&quot;
          the full amount of your booking. A cancellation of a booking does not
          guarantee funds will be released back to your account immediately. You
          acknowledge and agree that neither we nor the relevant Operator will
          be responsible or liable to you in relation to a delay by your bank or
          card issuer in the release of funds back into your account.
        </span>
      </p>

      <p>
        <b>
          <span>Cancellations:</span>
        </b>
        <span> If you need to cancel or amend your appointment, </span>
        <span>
          <a href="https://bookings.freshcarvaleting.com/login">
            <span>please log in to your account</span>{" "}
          </a>
          <span>
            and hit the 'cancel' button. If you would like to rebook the job,
            click 'make a new booking' and choose a new date and time.
          </span>
        </span>
      </p>

      <p>
        <span>
          If your booking was taken over the phone and you do not have login
          details, please contact us by phone, email or through our live chat
          and a member of our Customer Service team will get back to you
          (subject to office opening hours).
        </span>
      </p>

      <p>
        <span>
          Please note, we cannot guarantee your cancellation request will be
          attended to if it was made outside our usual working hours (ie, a
          booking cancelled after 5pm for an appointment before 9am). If you
          already have the contact details of your specific operator, please
          make sure to let them know directly. We recommend using the website to
          cancel your booking from your profile to avoid cancellation fees.
        </span>
      </p>

      <p>
        <span>
          If you are a Car Club member, please use the same dedicated app (not
          the website link) where you also book your appointments.
        </span>
      </p>

      <p>
        <b>
          <span>Cancellations fees: </span>
        </b>
        <span>
          In the event that you cancel your booking, cancellation fees will be
          charged as detailed below:{" "}
        </span>
      </p>

      <p>
        <span>
          &#9679;
          <span>&nbsp;&nbsp;&nbsp;</span>
        </span>
        <span>
          Job cancelled after the Operator has arrived at the location, or
          within one hour of the specified time: 100% of the agreed price{" "}
        </span>
      </p>

      <p>
        <span>
          &#9679;
          <span>&nbsp;&nbsp;&nbsp; </span>
        </span>
        <span>
          Within 24 hours of the appointment start time: 50% of the agreed price
          with a minimum of £20{" "}
        </span>
      </p>

      <p>
        <span>&nbsp;</span>
      </p>

      <h3>
        <b>
          <span>BOOKING MAINTENANCE AND CUSTOMER FEEDBACK</span>
        </b>
      </h3>

      <p>
        <b>
          <span>General:</span>
        </b>
        <span>
          {" "}
          We welcome any feedback and want to help our customers. In the case of
          any questions or problems, please send us an email to the address
          listed at the bottom of the page. In the event that you are
          dissatisfied with the quality of any Products or the Service provided
          by an Operator, please consider providing feedback by emailing us. The
          feedback is an important part of our quality control and helps us to
          improve.
        </span>
      </p>

      <p>
        <b>
          <span>Compensation,</span>
        </b>
        <span>
          {" "}
          disputes &amp; refunds: If you are dissatisfied with the quality of
          the Service provided by an Operator and wish to seek a return visit to
          correct the issues, you are requested to contact{" "}
        </span>
        <span>
          <a href="mailto:clean@freshcarvaleting.com">
            <span>clean@freshcarvaleting.com</span>
          </a>
        </span>
        <span>
          {" "}
          to provide feedback within 48 hours of your booking being completed.
          Fresh Car Valeting will strive to make sure any complaints are
          resolved fairly. In the circumstance that there is suspected damage to
          a vehicle, Fresh Car Valeting will initially assist in coordinating an
          investigation between the customer and the Operator and advise on next
          steps.
        </span>
      </p>

      <p>
        <b>
          <span>SMART Repair return visit</span>
        </b>
      </p>

      <p>
        If you are dissatisfied by a SMART Repair provided by one of our
        Operators, we will organise a return visit free of charge with the same
        Operator. Please note we will not provide compensation without
        inspecting and attempting to repair the defect. We can't guarantee that
        another Operator can take over the return visit.{" "}
      </p>

      <p>
        Our Lifetime Guarantee is our commitment to rectifying any work
        necessary to correct defects which may arise as the result of defective
        workmanship or materials. For this guarantee to be valid, we will
        require evidence that the defects are linked directly to workmanship or
        material. A diagnosis will be required from a specialist to prove that
        point.
      </p>

      <h3>
        <b>
          <span>OPERATORS TRAINING</span>
        </b>
      </h3>

      <p>
        All new valeting operators follow an extensive internal training course
        with one of our in-house trainers. Following this, we carry a thorough
        evaluation of their skills.
      </p>

      <p>
        All new SMART Repairs operators are currently being trained by an
        external company providing them with a certification from the Institute
        of the Motor Industry (IMI).
      </p>

      <p>
        Please note that some of our SMART operators with longer experience in
        the field do not necessarily have that specific certification but have
        been evaluated internally by our Head of SMART Repairs according to the
        same standards.
      </p>

      <p>
        Our internal procedures ensure we regularly evaluate the performance of
        our operators and take all necessary steps to provide further training,
        should this be deemed necessary.
      </p>

      <h3>
        <b>
          <span>PLATFORM TERMS OF PERMITTED USE</span>
        </b>
      </h3>

      <p>
        <b>
          <span>
            You are permitted to use the Platform for your own personal
            non-commercial use on the following basis:
          </span>
        </b>
      </p>
      <ul>
        <li>
          You must not misuse the Website by or attempt to hack it in any way
        </li>

        <li>
          The copyright and other intellectual property rights (such as text,
          graphics, data, graphics, HTML, videos, images and other content) in
          the Platform and in material published on it are owned by us or our
          licensors. These works are protected by copyright laws and treaties
          around the world and all rights are reserved. Any use of such material
          from the Website is prohibited unless with prior consent.
        </li>

        <li>
          In booking to use any of the materials on the Website or the Website
          itself for commercial purposes, you will require a licence from us.
        </li>

        <li>
          You must not modify the digital or paper copies of any materials that
          you print off and you must not use any pictures, photographs or any
          other graphics, video or audio sequences separately from any
          accompanying text.
        </li>

        <li>
          In the case of any use, our status as the author of the material
          should always be acknowledged.
        </li>
      </ul>

      <p>
        <b>
          <span>Reservation of rights:</span>
        </b>
        <span>
          {" "}
          Any rights not expressly granted in these Website Terms are reserved.
        </span>
      </p>

      <h3>
        <b>
          <span>PLATFORM AND OPERATOR REVIEWS</span>
        </b>
      </h3>

      <p>
        <b>
          <span>General:</span>
        </b>
      </p>

      <ul>
        <li>
          Any reviews or information you post, upload or transmit or upload to
          the Website will be considered non-confidential and non-proprietary.
          By posting, uploading or transmitting any Visitor Data, you represent
          and warrant that you own or otherwise control all of the rights to
          such Visitor Data. You agree that we will have no obligations with
          respect to any Visitor Data, and that we and anyone we designate will
          be free to copy, disclose, distribute, incorporate and otherwise use
          any Visitor Material and all data, images, sounds, text and other
          things embodied in it for any and all commercial or non-commercial
          purposes.
        </li>

        <li>
          You represent and warrant that any Visitor Data you post, upload or
          transmit does not and will not breach any of the restrictions listed
          below.
        </li>
      </ul>

      <p>
        <b>
          <span>Visitor Data Policy: </span>
        </b>
        <span>
          You are prohibited from posting, uploading or transmitting to or from
          the Website any Visitor Material (including any Reviews) that:
        </span>
      </p>
      <ul>
        <li>breaches any applicable local, national or international law;</li>

        <li>is unlawful or fraudulent;</li>

        <li>amounts to unauthorised advertising; or</li>

        <li>contains viruses or any other harmful programs.</li>
      </ul>

      <p>
        <b>
          <span>Reviews Policy: </span>
        </b>
        <span>
          In particular (but without limitation), any Reviews that you submit
          through the Website must not:
        </span>
      </p>
      <ul>
        <li>contain any offensive , or defamatory material;</li>

        <li>infringe the intellectual property rights of another person;</li>

        <li>breach any legal duty owed to a third party;</li>

        <li>give the impression that they originate from us; or</li>

        <li>promote discrimination, illegal activity or violence;</li>

        <li>
          be used to misrepresent yourself or attempt to impersonate another
          person.
        </li>
      </ul>

      <p>
        <b>
          <span>Removal of Reviews: </span>
        </b>
        <span>
          We reserve the right and have the sole discretion to remove or edit at
          any time any Reviews or other Visitor Material posted, uploaded or
          transmitted to the Website that we determine breaches a prohibition or
          is otherwise objectionable or may expose us or any third parties to
          any harm or liability of any type, or for any other reason.
        </span>
      </p>

      <p>
        <b>
          <span>Liability: </span>
        </b>
        <span>
          You agree to indemnify us against any losses, damages and claims (and
          all related costs) incurred by or made against us by an Operator or
          any other third party arising out of or in connection with any Reviews
          or other Visitor Data that you provide in breach of any of the
          representations and warranties, agreements or restrictions set forth
          in this paragraph.
        </span>
      </p>

      <p>
        <b>
          <span>Disclosure to authorities and courts: </span>
        </b>
        <span>
          You acknowledge that we will fully cooperate with any competent
          authority requesting or directing us to disclose the identity or
          location of anyone posting any Reviews or other Visitor Material in
          breach. Furthermore you release us to the fullest extent permitted by
          law from all liability in relation to such disclosure.
        </span>
      </p>

      <h3>
        <b>
          <span>3RD PARTY WEBSITES AND LINKS</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Link permission:</span>
        </b>
        <span> In booking to link to the Website (</span>
        <span>
          <a href="http://www.frescarvaleting.com">
            <span>www.frescarvaleting.com</span>
          </a>
        </span>
        <span> and www.freshcar.co.uk), you need to ensure:</span>
      </p>
      <ul>
        <li>
          You do not damage or take advantage of our reputation by creating the
          link;
        </li>

        <li>
          you do not create an association or endorsement with the linking site
          where none exists;
        </li>

        <li>
          any website from which you link must comply with the content standards
          set out in these Website Terms;
        </li>

        <li>
          we have the right to withdraw linking permission at any time and for
          any reason.
        </li>
      </ul>

      <p>
        <b>
          <span>Third party websites:</span>
        </b>
        <span>
          {" "}
          Links to third party websites on the Website are provided solely for
          your convenience. If you use these links, you leave the Website. We
          have not reviewed and do not control any of these third party websites
          (and are not responsible for these websites or their content or
          availability). We do not endorse or make any representation about
          these websites, their content, or the results from using such websites
          or content. If you decide to access any of the third party websites
          linked to the Website, you do so entirely at your own risk.
        </span>
      </p>

      <h3>
        <b>
          <span>DISCLAIMERS OF WARRANTIES</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Website information: </span>
        </b>
        <span>
          We try to ensure all information on the site is accurate and relevant
          however we offer no guarantee of this accuracy, relevance or
          completeness. We reserve the right to make changes without notice.
        </span>
      </p>

      <p>
        <b>
          <span>Operator actions: </span>
        </b>
        <span>
          The legal contract for the supply and purchase of a Service is between
          you and the Operator that you place your booking with. We have no
          control over the actions or omissions of any Operators. You
          acknowledge and accept the following by using the Website:
        </span>
      </p>
      <ul>
        <li>
          We do not give any undertaking that the Service provided by any
          Operator through the Platform will be of satisfactory quality or
          suitable for your purpose and we disclaim any such warranties.
        </li>

        <li>
          Estimated times for arrivals are provided by the Operator and are only
          estimates. Neither we nor the Operators guarantee that bookings will
          be completed within the estimated times as traffic volumes and
          roadworks amongst other factors all play a part in logistics.
        </li>

        <li>
          Although our Operators are held to a high quality standard, we cannot
          be held liable for any damage suffered by the vehicle or object being
          Valeted, losses or consequential damages as a result of the Operators
          actions. Any recourse as a result of damage should be pursued with the
          Operator company that provided the particular service.
        </li>

        <li>
          We can not guarantee that Operators will accept all bookings, and
          Operators have the discretion to reject bookings at any time for
          reasons such as mechanical failure, illness, adverse weather
          conditions etc.
        </li>

        <li>
          The foregoing disclaimers do not affect your statutory rights against
          any Operator.
        </li>
      </ul>

      <p>
        <b>
          <span>Exclusion of terms: </span>
        </b>
        <span>
          We provide you with access to the Platform and Service on the basis
          that, to the maximum extent permitted by law, we exclude all
          representations, warranties, conditions, undertakings and other terms
          in relation to the Website and Service (including any representations,
          warranties, conditions, undertakings and other terms which might
          otherwise apply to the Website or Service, or be otherwise implied or
          incorporated into these Website Terms, by statute, common law or
          otherwise).
        </span>
      </p>

      <h3>
        <b>
          <span>LIABILITY</span>
        </b>
      </h3>

      <p>
        <b>
          <span>General: </span>
        </b>
        <span>
          Nothing in these Website Terms excludes or limits our liability for
          death or personal injury arising from our negligence, our liability
          for fraudulent misrepresentation, or any other liability which cannot
          be excluded or limited under applicable law. Nothing in these Website
          Terms affects your statutory rights.
        </span>
      </p>

      <p>
        <b>
          <span>Exclusion of liability:</span>
        </b>
        <span>
          We will under no circumstances whatsoever be liable to you, whether in
          contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with
          the Service or the Website (including the use, inability to use or the
          results of use of the Service or the Website) for:
        </span>
      </p>
      <ul>
        <li>any loss of profits, sales, business, or revenue;</li>

        <li>loss or corruption of data, information or software;</li>

        <li>loss of business opportunity;</li>

        <li>loss of anticipated savings;</li>

        <li>loss of goodwill; or</li>

        <li>any indirect or consequential loss.</li>
      </ul>

      <p>
        <b>
          <span>Limitation of liability: </span>
        </b>
        <span>
          Subject to clauses mentioned above, our total liability to you in
          respect of all other losses arising under or in connection with the
          Service or the Website, whether in contract, tort (including
          negligence), breach of statutory duty, or otherwise, shall in no
          circumstances exceed £20.
        </span>
      </p>

      <p>
        <b>
          <span>Additional costs: </span>
        </b>
        <span>
          You assume full and sole responsibility for any additional or
          associated costs that you may incur in connection with or as a result
          of your use of the Website, including without limitation costs
          relating to the servicing, repair or adaptation of any equipment,
          software or data that you may own, lease, licence or otherwise use.
        </span>
      </p>

      <h3>
        <b>
          <span>TERMINATION</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Grounds for termination: </span>
        </b>
        <span>
          We may terminate or suspend (at our absolute discretion) your right to
          use the Platform and the Service immediately by notifying you in
          writing (including by email) if we believe in our sole discretion
          that:
        </span>
      </p>
      <ul>
        <li>
          you have used the Website in breach of our permitted terms of use
        </li>

        <li>
          you have posted Reviews or other Visitor Material in breach Visitor
          Material Policy;
        </li>

        <li>
          you have breached any other material terms of these Website Terms.
        </li>
      </ul>

      <p>
        <b>
          <span>Obligations upon termination: </span>
        </b>
        <span>
          Upon termination or suspension you must immediately destroy any
          downloaded or printed extracts from the Website.
        </span>
      </p>

      <h3>
        <b>
          <span>WRITTEN COMMUNICATIONS</span>
        </b>
      </h3>

      <p>
        <span>
          Applicable laws require that some of the information or communications
          we send to you should be in writing. When using the Website or booking
          Products via the Website, you accept that communication with us will
          be mainly electronic. We will contact you by email or provide you with
          information by posting notices on the Website. For contractual
          purposes, you agree to this electronic means of communication and you
          acknowledge that all contracts, notices, information and other
          communications that we provide to you electronically comply with any
          legal requirement that such communications be in writing. This
          condition does not affect your statutory rights.
        </span>
      </p>

      <h3>
        <b>
          <span>ADDITIONAL TERMS</span>
        </b>
      </h3>

      <p>
        <b>
          <span>Privacy Policy:</span>
        </b>
        <span>
          {" "}
          We are committed to protecting your privacy and security. All personal
          data that we collect from you will be processed in accordance with our
          Privacy Policy. You should review our Privacy Policy.
        </span>
      </p>

      <p>
        <b>
          <span>Other terms:</span>
        </b>
        <span>
          {" "}
          You should also review our Cookies Policy for information regarding
          how and why we use cookies to improve the quality of the Website and
          Service.
        </span>
      </p>

      <p>
        <b>
          <span>Severability: </span>
        </b>
        <span>
          If any of these Website Terms are determined by any competent
          authority to be invalid, unlawful or unenforceable to any extent, such
          term, condition or provision will to that extent be severed from the
          remaining terms, conditions and provisions which will continue to be
          valid to the fullest extent permitted by law.
        </span>
      </p>

      <p>
        <b>
          <span>Entire agreement: </span>
        </b>
        <span>
          These Website Terms and any document expressly referred to in them
          constitute the whole agreement between you and us and supersede all
          previous discussions, correspondence, negotiations, previous
          arrangement, understanding or agreement between us relating to the
          subject matter of any contract.
        </span>
      </p>

      <p>
        <b>
          <span>No waiver: </span>
        </b>
        <span>
          Any failure or delay by you or us in enforcing (in whole or in part)
          any provision of these Website Terms will not be interpreted as a
          waiver of your or our rights or remedies.
        </span>
      </p>

      <p>
        <b>
          <span>Assignment: </span>
        </b>
        <span>
          You may not transfer any of your rights or obligations under these
          Website Terms without our prior written consent. We may transfer any
          of our rights or obligations under these Website Terms without your
          prior written consent to any of our affiliates or any business that we
          enter into a joint venture with, purchase or are sold to.
        </span>
      </p>

      <p>
        <b>
          <span>Terms &amp; Conditions - Headings: </span>
        </b>
        <span>
          The headings in these Website Terms are included for convenience only
          and shall not affect their interpretation.
        </span>
      </p>
    </div>
  );
};
