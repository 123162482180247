import React from 'react';
import classnames from 'classnames';

import styles from './AppButtons.module.css';

interface AppButtonsProps {
    sideBySide?: boolean,
    style?: React.CSSProperties,
    className?: string,
}

const AppButtons: React.FC<AppButtonsProps> = ({
    children,
    sideBySide,
    style,
    className,
}) => {
    return (
        <div
            className={classnames({
                [className || '']: true,
                [styles.root]: true,
                [styles.sideBySide]: sideBySide
            })}
            style={style}
        >
            {children}
        </div>
    )
}

export default AppButtons;