import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import { Link } from 'react-router-dom';

import { AuthResult } from '../../auth';
import { pathBuilders } from '../../Routes';

import AppInput from '../AppInputs';
import AppButton, { AppButtons } from '../AppButton';
import AppFormError from '../AppFormError';
import AppForm from '../AppForm/AppForm';

import styles from './LoginForm.module.css';

export interface LoginFormValues {
    email: string,
    password: string,
}

interface OtherProps {

}

const InnerLoginForm: React.FC<OtherProps & FormikProps<LoginFormValues>> = ({
    isSubmitting,
    status,
}) => {
    return (
        <Form>
            <AppForm>
                <AppInput type="email" name="email" placeholder="Email Address" />
                <AppInput type="password" name="password" placeholder="Password" />
                <AppButtons sideBySide>
                    <AppButton type="submit" disabled={isSubmitting} variant="inverted" flexGrow={1}>
                        Login
                    </AppButton>
                    <AppButton type="button" disabled={isSubmitting} flexGrow={1}>
                        Cancel
                    </AppButton>
                </AppButtons>
                <Link className={styles.forgotPassword} to={pathBuilders.forgottenPassword()}>
                    Forgotten password?
                </Link>
                <AppFormError show={status && status.isError}>
                    Email and/or password are incorrect.
                </AppFormError>
            </AppForm>
        </Form>
    );
};

interface LoginFormProps {
    login: (values: LoginFormValues) => Promise<AuthResult>,
    onSuccess: () => void,
}

const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
    mapPropsToValues: () => ({
        email: '',
        password: '',
        confirmPassword: '',
    }),
    handleSubmit: async (values, { props, setStatus }) => {
        setStatus({
            isError: false,
        });

        const authResult = await props.login(values);

        if (authResult.isError) {
            setStatus({
                isError: true,
            });
            return;
        }

        props.onSuccess();
    },
})(InnerLoginForm);

export default LoginForm;
