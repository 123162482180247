import React from "react";
import { useField } from "formik";
import AppInputLabel from "./AppInputLabel";
import styles from './AppInput.module.css';
import selectStyles from './AppFormikValetSelect.module.css';
import Select, { components, ValueType } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export interface AppFormikValetSelectOption {
    value: number,
    label: string,
    extraProperties?: any,
}

export interface AppFormikValetSelectProps<T> {
    name: string,
    placeholder: string,
    options: T[],
    label?: string,
    required?: boolean,
    disabled?: boolean,
    optionFormat?: (option: T) => React.ReactElement
}

function AppFormikValetSelect<T extends AppFormikValetSelectOption>({
    name,
    placeholder,
    options,
    label,
    required,
    disabled,
    optionFormat
}: AppFormikValetSelectProps<T>) {
    const [, fieldMeta, helpers] = useField(name);

    const selectComponentStyles = {
        option: (
            provided: any,
            state: { isFocused: boolean; isDisabled: boolean; isSelected: boolean },
        ) => {
            return {
                ...provided,
                background: 'transparent !important',
                color: state.isSelected ? '#3c99a0' : state.isFocused ? '#3c99a0' : 'black',
                cursor: 'pointer',
                height: '35px',
                lineHeight: '100%'
            };
        },
        menu: (
            provided: any,
            state: any
        ) => {
            return {
                ...provided,
                border: '1px solid !important',
                borderColor: 'rgb(112, 112, 112) !important',
                borderRadius: '15px',
                marginTop: '-1px'
            };
        },
        container: (provided: any, state: any) => {
            return {
                ...provided,

                display: "inline-block",
                width: "100%"
            };
        },
        control: (provided: any, state: any) => {
            return {
                ...provided,
                border: '1px solid',
                borderRadius: '15px',
                borderColor: 'rgb(112, 112, 112) !important',
                outline: 'none',
                boxShadow: 'none',
                cursor: 'pointer',
                height: 'auto',
                minHeight: '0',
            };
        },
        valueContainer: (provided: any, state: any) => {
            return {
                ...provided,
                marginLeft: '4px',
            };
        },
        singleValue: (provided: any, state: any) => {
            return {
                ...provided,
                overflow: 'visible',
                minWidth: '100%',
                textAlign: 'center',
            };
        },
        placeholder: (provided: any, state: any) => {
            return {
                ...provided,
                lineHeight: '50%',
                overflow: 'visible',
                minWidth: '100%',
                textAlign: 'left',
            };
        },
        indicatorSeparator: (provided: any, state: any) => {
            return {
                ...provided,
                display: 'none',
            };
        },
        dropdownIndicator: (provided: any, state: any) => {
            return {
                ...provided,
                marginRight: '5px',
                color: '#3c99a0 !important',
            };
        },
    };

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon className={styles.icon} icon={faCaretDown} />
            </components.DropdownIndicator>
        );
    };

    return (
        <div>
            <AppInputLabel required={required}>{label}</AppInputLabel>
            <div className={styles.inputContainer}>
                <Select className={selectStyles.reactSelectContainer}
                    defaultValue={options[0]}
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    disabled={disabled}
                    options={options}
                    classNamePrefix="react-select"
                    //styles={selectStyles}
                    onChange={(option: ValueType<T>) => {
                        // @ts-ignore
                        helpers.setValue(option.value)
                    }}
                    styles={selectComponentStyles}
                    formatOptionLabel={optionFormat}
                    placeholder={"Please select"} />
            </div>
            {fieldMeta.touched && fieldMeta.error && (
                <div className={styles.error}>{fieldMeta.error}</div>
            )}
        </div>
    );
}

export default AppFormikValetSelect;
