import React from 'react';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import styles from './AppCalendar.module.css';
import classnames from 'classnames';

export interface AppCalendarProps {
    className?: string,
    value?: Date,
    onChange: (newDate: Date) => void,
    minDate?: Date,
    maxDate?: Date,
    minDetail?: 'year' | 'month' | 'decade' | 'century',
    defaultView?: 'year' | 'month' | 'decade' | 'century',
}
 
const AppCalendar: React.FC<AppCalendarProps> = ({
    className,
    value,
    onChange,
    minDate,
    maxDate,
    minDetail = 'year',
    defaultView,
}) => {
    const handleChange: OnChangeDateCallback = dateOrDates => {
        if (Array.isArray(dateOrDates)) throw new Error('Was only expecting one date to be selected.');
        const date = dateOrDates as Date;
        onChange(date);
    }

    return (
        <Calendar
            value={value}
            onChange={handleChange}
            className={classnames(styles.root, className)}
            tileClassName={styles.tile}
            minDate={minDate}
            maxDate={maxDate}
            minDetail={minDetail}
            defaultView={defaultView}
        />
    );
}
 
export default AppCalendar;