import React from 'react';
import config from '../../config';
import styles from './NavBarLogo.module.css';

const NavBarLogo = () => {
    return (
        <a href={config.freshStaticSiteHome} className="navbar-brand mr-auto mr-lg-0 p-0">
            <img
                className={styles.navLogo}
                alt="Mobile Car Valeting in Edinburgh & Glasgow | Fresh Car Valeting"
                src="/assets/images/logo/logo-trans.png"
            />
        </a>
    );
};

export default NavBarLogo;
