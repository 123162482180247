import React, { useState } from 'react';
import { queryCarModels } from '../../api';
import { CarLookup } from '../../models';
import { ComboBox, ComboBoxQueryResult } from '../AppInputs';

interface CarModelSearchFormProps {
    onSelect: (car: CarLookup|null) => void;
}

const CarModelSearchForm: React.FC<CarModelSearchFormProps> = ({
    onSelect,
}) => {
    const [searchError, setSearchError] = useState('');

    const handleSearch = async (query: string): Promise<ComboBoxQueryResult<CarLookup>[]> => {
        setSearchError('');
        const response = await queryCarModels(query);

        if (response.isError) {
            setSearchError(response.message);
            return [];
        }

        return response.content.map(carLookup => ({
            value: carLookup.id,
            label: `${carLookup.makeAndModel}`,
            item: carLookup,
        }));
    }

    return (
        <ComboBox
            doSearch={handleSearch}
            onSelect={onSelect}
            searchError={searchError}
            placeholder="Enter your model"
        />
    );
}

export default CarModelSearchForm;
