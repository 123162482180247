import { ApiStandardResponse, unpackStandardResponse } from "./common/ApiStandardResponse";
import { buildUrl } from "./common/buildUrl";
import { doErrorToastIfRequired } from "./common/doErrorToastIfRequired";
import * as httpClient from './common/httpClient'
import { ApiPreferredValeterDTO, PreferredValeterDTO } from "./Models/PreferredValeters";


export const addPreferredValeter = async (valet: PreferredValeterDTO) => {
    const url = buildUrl('PreferredValeter');
    const response = await httpClient.postRequest<ApiStandardResponse<any>>(url, valet);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, m => m);
}

export const getAllPreferredValeters = async () => {
    const url = buildUrl('PreferredValeter');
    const response = await httpClient.getRequest<ApiStandardResponse<ApiPreferredValeterDTO[]>>(url);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, m => m);
}

export const deletePreferredValeter = async (customerPreferredValeterId: number) => {
    const url = buildUrl(`PreferredValeter/${customerPreferredValeterId}`);
    const response = await httpClient.patchRequest(url, { isDeleted: true });
    doErrorToastIfRequired(response);
    return response;
}