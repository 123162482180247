import { createContext, useContext } from "react";

export interface CurrencyDetails {
  currency: Currency;
  symbol: string;
  exchangeRate: number;
}

export enum CountryCode {
  UK = "uk",
  IE = "ie",
}

export const countryCodeMap = {
  [CountryCode.UK]: 0,
  [CountryCode.IE]: 1,
};

export enum Currency {
  GBP = 0,
  EUR = 1,
}

export const currencySymbol = {
  [Currency.GBP]: "£",
  [Currency.EUR]: "€",
};

export const exchangeRate = {
  [Currency.GBP]: 1,
  [Currency.EUR]: 1.4,
};

export const countryInformationMap = {
  [CountryCode.UK]: {
    currency: Currency.GBP,
    symbol: currencySymbol[Currency.GBP],
    exchangeRate: exchangeRate[Currency.GBP],
  },
  [CountryCode.IE]: {
    currency: Currency.EUR,
    symbol: currencySymbol[Currency.EUR],
    exchangeRate: exchangeRate[Currency.EUR],
  },
};

export interface ILocalisationContext {
  country: CountryCode;
  currency: CurrencyDetails;
  isLocked: boolean;
  handleLocalisationChange: (location: CountryCode) => void;
}

const placeholderFunc = () => {
  throw new Error("Not implemented.");
};

export const LocalisationContext = createContext<ILocalisationContext>({
  country: CountryCode.UK,
  currency: countryInformationMap[CountryCode.UK],
  isLocked: true,
  handleLocalisationChange: placeholderFunc,
});

export const useLocalisation = () => useContext(LocalisationContext);
