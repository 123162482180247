import debounce from 'lodash.debounce';
import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import AppFormError from '../AppFormError';
import styles from './AppInput.module.css';

export interface ComboBoxQueryResult<T> {
    label: string,
    value: string,
    item: T,
}

interface ComboBoxProps<T> {
    doSearch: (query: string) => Promise<ComboBoxQueryResult<T>[]>,
    searchError?: string,
    onSelect: (selected: T|null) => void,
    placeholder: string,
}

function ComboBox<T>({
    doSearch,
    searchError,
    onSelect,
    placeholder,
}: ComboBoxProps<T>) {
    const handleLoadOptions = useCallback(
        debounce((inputValue: string, callback) => {
            onSelect(null);
            doSearch(inputValue).then(options => callback(options))
        }, 300),
        []
    );

    const handleChange = (option: any) => {
        onSelect(option.item);
    }

    return (
        <div className={styles.comboBoxRoot}>
            <AsyncSelect
                styles={{
                    control: () => ({
                        background: 'white',
                        border: '0.8px solid rgb(112, 112, 122)',
                        borderRadius: '30px',
                        display: 'flex',
                        cursor: 'text',
                        padding: '2px 12px',
                    }),
                    menu: () => ({
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        boxShadow: '3px 3px 10px 2px rgba(112,112,112,0.25)',
                        position: 'absolute',
                        left: 0,
                        width: '100%',
                    }),
                    option: (() => ({
                        borderRadius: '20px',
                        color: '#3c99a0',
                        cursor: 'pointer',
                        padding: '0.75em 1.25em',
                        ':hover': {
                            backgroundColor: '#3c99a0',
                            color: 'white',
                        }
                    })) as any
                }}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                loadOptions={handleLoadOptions}
                onChange={handleChange}
                menuIsOpen={searchError ? false : undefined}
                placeholder={placeholder}
            />
            <AppFormError show={!!searchError}>
                {searchError}
            </AppFormError>
        </div>
    )
}

export default ComboBox;