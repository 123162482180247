import React from 'react';
import { AppCard, AppCardsLayout, AppCardTitle } from '../Components';
import { ForgotPasswordForm } from '../Components/ForgotPasswordForm';
 
export const ForgotPasswordPage: React.FC = () => {
    return (
        <AppCardsLayout>
            <AppCard>
                <AppCardTitle>Forgot Password</AppCardTitle>
                <ForgotPasswordForm />
            </AppCard>
        </AppCardsLayout>
    );
};