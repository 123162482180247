import { createContext, useContext } from 'react';
import { HttpClientResponse } from '../api';
import { AuthResult } from './authClient';
import { ForgotPasswordDto } from './models/ForgotPassword';
import { FullAccountInformation } from './models/FullAccountInformation';
import { ResetPasswordDto } from './models/ResetPassword';
import { UpdatePasswordDto } from './models/UpdatePassword';
import { UserConsentDto } from './models/UpdateUserConsent';
import { UpdateUserDetailsDto } from './models/UpdateUserDetails';
import { AccountInformation } from './sessionStorageClient';

export interface IAuthContext {
    register: (email: string, password: string) => Promise<AuthResult>,
    logIn: (email:string, password: string) => Promise<AuthResult>,
    setLogInInformation: (accountInformation: AccountInformation, token: string) => void,
    logOut: () => Promise<AuthResult>,
    userName: string | null,
    isLoggedIn: boolean,
    getToken: () => string | null,
    getAccountInformation: () => AccountInformation,
    getFullAccountInformation: () => Promise<HttpClientResponse<FullAccountInformation>>,
    updateUserDetails: (newDetails: UpdateUserDetailsDto) => Promise<void>,
    updateUserConsent: (newDetails: UserConsentDto) => Promise<void>,
    updatePassword: (updatePasswordDto: UpdatePasswordDto) => Promise<AuthResult>,
    forgotPassword: (forgotPasswordDto: ForgotPasswordDto) => Promise<HttpClientResponse<null>>,
    resetPassword: (resetPasswordDto: ResetPasswordDto) => Promise<HttpClientResponse<null>>,
}

const placeholderFunc = () => { throw new Error('Not implemented.') }

export const AuthContext = createContext<IAuthContext>({
    register: placeholderFunc,
    logIn: placeholderFunc,
    setLogInInformation: placeholderFunc,
    logOut: placeholderFunc,
    userName: null,
    isLoggedIn: false,
    getToken: placeholderFunc,
    getAccountInformation: placeholderFunc,
    getFullAccountInformation: placeholderFunc,
    updateUserDetails: placeholderFunc,
    updateUserConsent: placeholderFunc,
    updatePassword: placeholderFunc,
    forgotPassword: placeholderFunc,
    resetPassword: placeholderFunc,
});

export const useAuth = () => useContext(AuthContext);