import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppButton, AppCardList } from '../../Components';
import ApiErrorLoaderWrapper from '../../Components/ErrorLoaderWrapper';
import { useIsMobileMedia } from '../../mediaQueries';
import { actions, selectors } from '../../store';

export interface CarsSelectListProps {
    
}
const CarsSelectList: React.FC<CarsSelectListProps> = () => {
    const isMobile = useIsMobileMedia();
    const dispatch = useDispatch();
    const {
        isLoading,
        apiError,
    } = useSelector(selectors.customerCars.apiState);
    const cars = useSelector(selectors.customerCars.all);

    const carIds = useSelector(selectors.makeBooking.selectedCarIds);
    const selectCarId = (carId: number) => dispatch(actions.makeBooking.selectCar(carId));
    const deselectCarId = (carId: number) => dispatch(actions.makeBooking.deselectCar(carId));

    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <AppCardList
                items={cars}
                getPrimaryInfo={car => car.makeAndModel}
                getSecondaryInfo={car => car.registrationNumber}
                emptyMessage="You don't have any cars yet."
                renderButton={carId => {
                    const isSelected = carIds.includes(carId);
                    return (
                        <AppButton
                            variant={isSelected ? 'inverted' : 'standard'}
                            onClick={() => isSelected ? deselectCarId(carId) : selectCarId(carId)}
                            size={isMobile ? 'small' : 'standard'}
                        >
                            {isSelected ? 'Selected' : 'Select'}
                        </AppButton>
                    );
                }}
            />
        </ApiErrorLoaderWrapper>
    );
}
 
export default CarsSelectList;