import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {checkPostcode} from '../../api';
import {actions, useDispatchEffect} from '../../store';
import { AppCard, AppCardContent, AppCardMessage, AppCardsLayout, AppCardTitle, AppCheckbox, AppInput } from "../../Components";
import { makeBookingPathBuilders } from "../MakeBooking";
import styles from "./LocationCheck.module.css";
import { validatePostcode } from "../../validation";
import { CountryCode, useLocalisation } from "../../Components/Localisation";
import UkFlag from "../../assets/uk_flag.svg";
import IeFlag from "../../assets/ie_flag.svg";

const LocationCheck: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { country, handleLocalisationChange } = useLocalisation();
  const [postcodeInputValue, setPostcodeInputValue] = useState("");
  const [postcodeValidationError, setPostcodeValidationError] =
    useState<string>("");
  const [postcodeIsCovered, setPostcodeIsCovered] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(0));

  const handlePostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostcodeInputValue(event.target.value);
  };

  const isValidResponse = (type: string): boolean => {
    return type === "Primary" || type === "Secondary";
  };

  const handlePostcodeSearch = async () => {
    setPostcodeValidationError("");

      const postcodeValidationResult = await validatePostcode(
        postcodeInputValue,
        country
      );

      if (!postcodeValidationResult.isValid) {
        setPostcodeValidationError(postcodeValidationResult.errorMessage);
        return;
      }

      if (country === CountryCode.UK) {
        setIsSearching(true);
        const newPostcodeIsCovered = await checkPostcode(
          postcodeInputValue,
          true
        );

        if (newPostcodeIsCovered.isError) {
          setPostcodeIsCovered(false);
          dispatch(actions.makeBooking.setPostcodeType(null));
        } else {
          setPostcodeIsCovered(isValidResponse(newPostcodeIsCovered.content));

          if(newPostcodeIsCovered.content) {
            dispatch(actions.makeBooking.reset());
            dispatch(actions.customerCars.reset());
            dispatch(actions.makeBooking.setSelectedPostcode(postcodeInputValue));
            dispatch(actions.makeBooking.setPostcodeType(newPostcodeIsCovered.content));
            dispatch(actions.packageInfo.fetchIfRequired(postcodeInputValue));

            history.push(makeBookingPathBuilders.addCar());
          }
        }

        setIsSearching(false);
        setHasSearched(true);
        return;
    }

    setPostcodeIsCovered(true);
    setHasSearched(true);
    dispatch(actions.makeBooking.setPostcodeType("Secondary"));
    dispatch(actions.makeBooking.reset());
    dispatch(actions.customerCars.reset());
    dispatch(actions.makeBooking.setSelectedPostcode(postcodeInputValue));
    // If IE address, return all package types.
    dispatch(actions.packageInfo.fetchIfRequired('ireland'));
    history.push(makeBookingPathBuilders.addCar());
  };

  return (
    <AppCardsLayout>
      <AppCard>
        <AppCardTitle>Location Check</AppCardTitle>
        <AppCardContent className={styles.content}> 
          {hasSearched || (
            <div className={styles.countryPicker}>
              <AppCheckbox
                label={<><img src={UkFlag} className={styles.flag} /> United Kingdom</>}
                checked={country === CountryCode.UK}
                onChange={() => handleLocalisationChange(CountryCode.UK)}
              />
              <AppCheckbox
                label={<><img src={IeFlag} className={styles.flag} /> Republic Of Ireland</>}
                checked={country === CountryCode.IE}
                onChange={() => handleLocalisationChange(CountryCode.IE)}
              />
            </div>
          )}

          <AppCardMessage gutterBottom>
            We'll use your postcode and country to identify if you have a Fresh Car valeter
            in your area.
          </AppCardMessage>  

          {hasSearched || (
            <AppInput
              disabled={isSearching}
              placeholder="Please enter your postcode"
              value={postcodeInputValue}
              onChange={handlePostcodeChange}
              onEnter={handlePostcodeSearch}
              showSearch
              errorMessage={postcodeValidationError}
            />          
          )}

          {(hasSearched && !postcodeIsCovered) &&
              <AppCardMessage header="Sorry" variant="error">
                We don't have coverage in your area at the moment, please keep
                an eye on our website and watch us grow. If you would like get
                in touch please contact us
              </AppCardMessage>
              }
        </AppCardContent>
      </AppCard>
    </AppCardsLayout>
  );
};

export default LocationCheck;