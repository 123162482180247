import React from 'react';
import Modal from 'react-modal';

import { useIsMobileMedia } from '../../mediaQueries';
import styles from './AppModal.module.css';

export interface AppModalProps {
    isOpen: boolean,
    onClose: () => void,
    maxLargeScreenWidth?: string,
}

Modal.setAppElement('#root');

const AppModal: React.FC<AppModalProps> = ({
    isOpen,
    onClose,
    maxLargeScreenWidth,
    children,
}) => {
    const isMobile = useIsMobileMedia();


    const customStyles: Modal.Styles = {
        content: {
            inset: isMobile ? '1rem' : '20px',
            maxWidth: isMobile ? '100%' : (maxLargeScreenWidth || '50%'),
            marginTop: '100px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
        >
            <div className={styles.content}>
                <button onClick={onClose}>X</button>
                {children}
            </div>
        </Modal>
    );
}

export default AppModal;