import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkPostcode } from '../../api';
import { AppButton, AppCardList } from '../../Components';
import ApiErrorLoaderWrapper from '../../Components/ErrorLoaderWrapper';
import { CountryCode, useLocalisation } from '../../Components/Localisation';
import { useIsMobileMedia } from '../../mediaQueries';
import { actions, selectors } from '../../store';

export interface AddressSelectListProps {
    
}
const AddressSelectList: React.FC<AddressSelectListProps> = () => {
    const isMobile = useIsMobileMedia();
    const dispatch = useDispatch();
    const { country } = useLocalisation();
    const {
        isLoading,
        apiError,
    } = useSelector(selectors.customerAddresses.apiState);
    const addresses = useSelector(selectors.customerAddresses.all);

    const selectedAddressId = useSelector(selectors.makeBooking.selectSelectedAddressId);
    const setSelectedAddressId = (addressId: number) => dispatch(actions.makeBooking.setSelectedAddress(addressId));
    const setSelectedPostcode = (postcode: string) => dispatch(actions.makeBooking.setSelectedPostcode(postcode));
    const setPostcodeType = (type: string) => dispatch(actions.makeBooking.setPostcodeType(type));

    const handleAddressSelect = async (addressId: number) => {
        const addressDetails = addresses.find(address => address.id === addressId);

        if(addressDetails) {
            if(country === CountryCode.UK) {
                const postcodeIsCovered = await checkPostcode(addressDetails.postcode);

                if(postcodeIsCovered.isError) {
                    return;
                }

                setSelectedAddressId(addressId);
                setSelectedPostcode(addressDetails.postcode);
                setPostcodeType(postcodeIsCovered.content);
                return;
            }

            setSelectedAddressId(addressId);
            setSelectedPostcode(addressDetails.postcode);
            setPostcodeType("Secondary");
        }
    }

    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <AppCardList
                items={addresses}
                getPrimaryInfo={address => address.addressLine1}
                getSecondaryInfo={address => address.postcode}
                emptyMessage="You don't have any addresses yet."
                renderButton={addressId => {
                    const isSelected = selectedAddressId === addressId; 
                    return (
                        <AppButton
                            variant={isSelected ? 'inverted' : 'standard'}
                            onClick={() => handleAddressSelect(addressId)}
                            size={isMobile ? 'small' : 'standard'}
                        >
                            {isSelected ? 'Selected' : 'Select'}
                        </AppButton>
                    );
                }}
            />
        </ApiErrorLoaderWrapper>
    );
}
 
export default AddressSelectList;