import React, { useState } from "react";
import DropdownToggle from "./DropdownToggle";
import DropdownMenu from "./DropdownMenu";
import config from "../../config";

const Packages = () => {
  const isTouchScreen = "ontouchstart" in document.documentElement;
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const renderToggle = () => (
    <DropdownToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
  );

  return (
    <>
      <li
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ position: "relative" }}
      >
        <a href={config.freshStaticSitePackages}>Packages</a>
        {renderToggle()}
        <DropdownMenu
          isOpen={(!isTouchScreen && isHovered) || isOpen}
          items={[
            {
              label: "Maintenance",
              onClick: () =>
                (window.location.href = config.freshStaticSiteMaintenance),
            },
            {
              label: "Car Valet Package Prices",
              onClick: () =>
                (window.location.href = config.freshStaticSiteFullValets),
            },
            {
              label: "Gift Vouchers",
              onClick: () =>
                (window.location.href = config.freshStaticSiteFreshVouchers),
            },
          ]}
        />
      </li>
    </>
  );
};

export default Packages;
