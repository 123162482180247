const authApiBaseUrl = process.env.REACT_APP_AUTH_API_BASE_URL;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const freshStaticSiteHomeBaseUrl = process.env.REACT_APP_FRESH_STATIC_SITE_BASE_URL;
const freshStaticSiteCarClubPath = process.env.REACT_APP_FRESH_STATIC_SITE_CAR_CLUB_PATH;
const freshStaticSitePackagesPath = process.env.REACT_APP_FRESH_STATIC_SITE_PACKAGES_PATH;
const freshStaticSiteLocationsPath = process.env.REACT_APP_FRESH_STATIC_SITE_LOCATIONS_PATH;
const freshStaticSiteFranchisingPath = process.env.REACT_APP_FRESH_STATIC_SITE_FRANCHISING_PATH;
const freshStaticSiteAboutPath = process.env.REACT_APP_FRESH_STATIC_SITE_ABOUT_PATH;
const freshStaticSiteOurWork = process.env.REACT_APP_FRESH_STATIC_SITE_OUR_WORK_PATH;
const freshStaticSiteBlog = process.env.REACT_APP_FRESH_STATIC_SITE_BLOG_PATH;
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const trackBookings = process.env.REACT_APP_TRACK_BOOKINGS;
const stripePrivateKey = process.env.REACT_APP_STRIPE_PRIVATE_KEY;
const freshStaticSiteDetailingPath = process.env.REACT_APP_FRESH_STATIC_SITE_DETAILING_PATH;
const freshStaticSiteFAQsPath = process.env.REACT_APP_FRESH_STATIC_SITE_FAQS_PATH;
const freshStaticSiteMeetTheTeam = process.env.REACT_APP_FRESH_STATIC_SITE_MEET_THE_TEAM_PATH;
const freshStaticSiteHopeForJustice = process.env.REACT_APP_FRESH_STATIC_SITE_HOPE_FOR_JUSTICE_PATH;
const freshStaticSiteMaintenance = process.env.REACT_APP_FRESH_STATIC_SITE_MAINTENANCE_PATH;
const freshStaticSiteFullValets = process.env.REACT_APP_FRESH_STATIC_SITE_FULL_VALETS_PATH;
const freshStaticSiteFreshVouchers = process.env.REACT_APP_FRESH_STATIC_SITE_FRESH_VOUCHERS_PATH;

class ConfigError extends Error {}

if (!authApiBaseUrl) throw new ConfigError('Configuation parameter REACT_APP_AUTH_API_BASE_URL missing in environment.');
if (!apiBaseUrl) throw new ConfigError('Configuation parameter REACT_APP_API_BASE_URL missing in environment.');
if (!freshStaticSiteHomeBaseUrl) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_BASE_URL missing in environment.');
if (!freshStaticSiteCarClubPath) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_CAR_CLUB_PATH missing in environment.');
if (!freshStaticSitePackagesPath) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_PACKAGES_PATH missing in environment.');
if (!freshStaticSiteLocationsPath) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_LOCATIONS_PATH missing in environment.');
if (!freshStaticSiteFranchisingPath) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_FRANCHISING_PATH missing in environment.');
if (!freshStaticSiteAboutPath) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_ABOUT_PATH missing in environment.');
if (!freshStaticSiteOurWork) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_OUR_WORK_PATH missing in environment.');
if (!freshStaticSiteBlog) throw new ConfigError('Configuation parameter REACT_APP_FRESH_STATIC_SITE_BLOG_PATH missing in environment.');
if (!googleMapsApiKey) throw new ConfigError('Configuation parameter REACT_APP_GOOGLE_MAPS_API_KEY missing in environment.');
if (!trackBookings) throw new ConfigError('Configuation parameter REACT_APP_TRACK_BOOKINGS missing in environment.');
if (!stripePrivateKey) throw new ConfigError('Configuration parameter REACT_APP_STRIPE_PRIVATE_KEY missing in environment.');
if (!freshStaticSiteDetailingPath)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_DETAILING_PATH missing in environment.");
if (!freshStaticSiteFAQsPath)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_FAQS_PATH missing in environment.");
if (!freshStaticSiteMeetTheTeam)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_MEET_THE_TEAM_PATH missing in environment.");
if (!freshStaticSiteHopeForJustice)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_HOPE_FOR_JUSTICE_PATH missing in environment.");
if (!freshStaticSiteMaintenance)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_MAINTENANCE_PATH missing in environment.");
if (!freshStaticSiteFullValets)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_FULL_VALETS_PATH missing in environment.");
if (!freshStaticSiteFreshVouchers)  throw new ConfigError("Configuation parameter REACT_APP_FRESH_STATIC_SITE_FRESH_VOUCHERS_PATH missing in environment.");

const config = {
  authApiBaseUrl,
  apiBaseUrl,
  googleMapsApiKey,
  stripePrivateKey,
  trackBookings: trackBookings.toLowerCase() === "true",
  freshStaticSiteHome: freshStaticSiteHomeBaseUrl,
  freshStaticSiteCarClub: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteCarClubPath}`,
  freshStaticSitePackages: `${freshStaticSiteHomeBaseUrl}/${freshStaticSitePackagesPath}`,
  freshStaticSiteLocations: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteLocationsPath}`,
  freshStaticSiteFranchising: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteFranchisingPath}`,
  freshStaticSiteAbout: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteAboutPath}`,
  freshStaticSiteOurWork: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteOurWork}`,
  freshStaticSiteBlog: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteBlog}`,
  freshStaticSiteDetailing: `${freshStaticSiteHomeBaseUrl}/${freshStaticSitePackagesPath}/${freshStaticSiteDetailingPath}`,
  freshStaticSiteFAQs: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteFAQsPath}`,
  freshStaticSiteMeetTheTeam: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteMeetTheTeam}`,
  freshStaticSiteHopeForJustice: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteHopeForJustice}`,
  freshStaticSiteMaintenance: `${freshStaticSiteHomeBaseUrl}/${freshStaticSitePackagesPath}/${freshStaticSiteMaintenance}`,
  freshStaticSiteFullValets: `${freshStaticSiteHomeBaseUrl}/${freshStaticSitePackagesPath}/${freshStaticSiteFullValets}`,
  freshStaticSiteFreshVouchers: `${freshStaticSiteHomeBaseUrl}/${freshStaticSiteFreshVouchers}`
};

export default config;