import React, { useEffect, useState } from 'react';
import { faBook, faCar, faStar, faUser }  from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { PageTitle } from '../../Components';
import { AppButton } from '../../Components';
import { pathBuilders } from '../../Routes';
import { actions, selectors, useDispatchEffect } from '../../store';

import styles from './AccountDashboard.module.css';
import DashboardCard from './DashboardCard';
import DashboardCardButtons from './DashboardCardButtons';
import CarsList from './CarsList';
import AddressesList from './AddressesList';
import BookingsList from './BookingsListContainer';
import NewBookingModal from '../NewBookingModal';
import { useDispatch, useSelector } from 'react-redux';
import PreferredValeterList from './PreferredValeterList';
import CarClubCard from './CarClubCard';
import { CountryCode, countryCodeMap, useLocalisation } from '../../Components/Localisation';

export const AccountDashboard = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { country, handleLocalisationChange } = useLocalisation();
    const addresses = useSelector(selectors.customerAddresses.all);

    useDispatchEffect(actions.makeBooking.reset);
    const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
    
    const handleNewBookingClick = () => {
        dispatch(actions.makeBooking.reset());
        setIsBookingModalOpen(true)
    };

    const handleCarClubClick = () => {
        window.open('https://www.freshcarvaleting.com/fresh-car-club/', '_blank')?.focus();
    };

    useEffect(() => {
        // Override country stored in localStorage based on first address location
        if(addresses.length > 0) {
            const isUkAddress = addresses[0].country === countryCodeMap[CountryCode.UK];

            if(isUkAddress) {
                handleLocalisationChange(CountryCode.UK);
            }

            if(!isUkAddress) {
                handleLocalisationChange(CountryCode.IE);
            }
        }
    }, [addresses]);

    const showCarClubCard = country === CountryCode.UK;

    return (
        <>
            <PageTitle>Account Dashboard</PageTitle>
            <div className={styles.root}>
                <div className={styles.column}>
                    {showCarClubCard &&
                        <CarClubCard
                            title="Car Club"
                            titleIcon={faStar}
                            titleLinkText="View Packages"
                            onTitleLinkClick={handleCarClubClick}
                        >
                            <p>Join our Car Club subscription service to get exclusive offers and discounts on your regular cleans.</p>
                        </CarClubCard>
                    }
                    <DashboardCard
                        title="Bookings"
                        titleIcon={faBook}
                        titleLinkText="New booking"
                        onTitleLinkClick={handleNewBookingClick}
                    >
                        <NewBookingModal
                            isOpen={isBookingModalOpen}
                            onClose={() => setIsBookingModalOpen(false)}
                        />
                        <BookingsList
                            onRebook={handleNewBookingClick}
                        />
                    </DashboardCard>
                </div>
                <div className={styles.column}>
                    <DashboardCard
                        title="Your Cars"
                        titleIcon={faCar}
                        titleLinkText="Add Car"
                        onTitleLinkClick={() => history.push(pathBuilders.addCar())}
                    >
                        <CarsList />
                    </DashboardCard>
                    <DashboardCard
                        title="Addresses"
                        titleIcon={faUser}
                        titleLinkText="Add Address"
                        onTitleLinkClick={() => history.push(pathBuilders.addAddress())}
                    >
                        <AddressesList />
                        <DashboardCardButtons>
                            <AppButton size="small" variant="inverted" onClick={() => history.push(pathBuilders.accountManagement())}>My Details</AppButton>
                        </DashboardCardButtons>
                    </DashboardCard>
                    <DashboardCard
                        title="Your Preferred Valeters"
                        titleIcon={faStar}
                    >
                        <PreferredValeterList />
                    </DashboardCard>
                </div>
            </div>
        </>
    );
}
