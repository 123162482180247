import React from "react";
import {
  AppButton,
  AppCard,
  AppCardButtons,
  AppCardTitle,
} from "../../Components";
import { Booking } from "../../models";
import BookingOverViewCarDetails from "./BookingOverViewCarDetails";
import styles from "./BookingOverview.module.css";
import BookingValeterBox from "./BookingValeterBox";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";
import { useLocalisation } from "../../Components/Localisation";

export interface BookingOverviewCardProps {
  booking: Booking;
  backToDashboard: () => void;
}

const BookingOverviewCard: React.FC<BookingOverviewCardProps> = ({
  booking,
  backToDashboard,
}) => {
  const { currency } = useLocalisation();
  const adjustedPrice = exchangeRateAdjustedPrice(
    booking.totalCost,
    currency.exchangeRate
  );
  const priceToDisplay = formatPriceWithCurrencySymbol(
    adjustedPrice,
    currency.symbol
  );

  return (
    <>
      <AppCard maxFullScreenWidth="50%">
        <div className={styles.subheading}>
          <AppCardTitle size="small">Booking Overview</AppCardTitle>
          <p className={styles.infoText}>
            A detailed look at your previous booking.
          </p>
        </div>
        <BookingValeterBox booking={booking} />
        <div className={styles.overviewContent}>
          {booking.bookingCustomerCars.map((car) => (
            <BookingOverViewCarDetails key={car.id} carDetails={car} />
          ))}
          <div className={styles.totalCostContainer}>
            <b>
              <p className={styles.totalCostLeft}>Total Cost: </p>
              <p className={styles.totalCostRight}>{priceToDisplay}</p>
            </b>
          </div>
        </div>
        <AppCardButtons>
          {/* <AppButton variant="inverted">Rebook</AppButton> */}
          <AppButton onClick={backToDashboard}>Back to Dashboard</AppButton>
        </AppCardButtons>
      </AppCard>
    </>
  );
};

export default BookingOverviewCard;
