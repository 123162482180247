import { AutomatedSlotLookup } from '../models';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import { ApiStandardResponse, unpackStandardResponse } from './common/ApiStandardResponse';
import * as httpClient from './common/httpClient'

export interface ApiCarPackageAutomatedSlotLookup {
    carCategory: string,
    packageGroupId: number,
    optionalExtraIds: number[],
}

export interface ApiAutomatedSlotLookup {
    carBookings: ApiCarPackageAutomatedSlotLookup[],
    postcode: string,
    date: string,
    resourceId?: number
}

export const queryAutomatedSlots = async (dto: ApiAutomatedSlotLookup) => {
    const url = buildUrl(`bookableSlots/query?api-version=2.0`);
    const response =  await httpClient.postRequest<ApiStandardResponse<AutomatedSlotLookup[]>>(url, dto);

    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, m => m);
}