import React from 'react';
import { Field, useField } from 'formik';

import styles from './AppInput.module.css';
import AppInputLabel from './AppInputLabel';

export interface AppInputProps {
    name: string,
    type: string,
    placeholder: string,
    label?: string,
    required?: boolean,
}
 
const AppFormikInput: React.FC<AppInputProps> = ({
    name,
    type,
    placeholder,
    label,
    required,
}) => {
    const [inputProps, fieldMeta] = useField(name);
    return (
        <div>
            <AppInputLabel required={required}>{label}</AppInputLabel>
            <div className={styles.inputContainer}>
                <Field
                    className={styles.input}
                    {...inputProps}
                    type={type}
                    placeholder={placeholder}
                />
            </div>
            {fieldMeta.touched && fieldMeta.error && (
                <div className={styles.error}>{fieldMeta.error}</div>
            )}
        </div>
    );
};
 
export default AppFormikInput;