import storageKeys from '../sessionStorageKeys';

export interface AccountInformation {
    customerId: number,
    displayName: string,
    email: string,
}

const getAccountInformation = () => {
    const jsonData = sessionStorage.getItem(storageKeys.accountInformation);
    if (!jsonData) return undefined;

    return JSON.parse(jsonData) as AccountInformation;
}

const setAccountInformation = (accountInformation: AccountInformation) => {
    // Ensure that no extra properties are saved.
    const newAccountInformation: AccountInformation = {
        customerId: accountInformation.customerId,
        displayName: accountInformation.displayName,
        email: accountInformation.email,
    };

    sessionStorage.setItem(storageKeys.accountInformation, JSON.stringify(newAccountInformation));
}

const sessionStorageClient = {
    setToken: (token: string) => sessionStorage.setItem(storageKeys.authToken, token),
    getToken: () => sessionStorage.getItem(storageKeys.authToken),
    clearToken: () => sessionStorage.removeItem(storageKeys.authToken),
    setAccountInformation,
    getAccountInformation,
    clearAccountInformation: () => sessionStorage.removeItem(storageKeys.accountInformation),
};

export default sessionStorageClient;