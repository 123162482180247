import React from 'react';

import styles from './ErrorLoaderWrapper.module.css';
import { HttpClientFailureResponse } from '../../api';

interface ApiErrorLoaderWrapperProps {
    isLoading: boolean,
    httpError?: HttpClientFailureResponse
}

const ApiErrorLoaderWrapper: React.FC<ApiErrorLoaderWrapperProps> = ({
    isLoading,
    httpError,
    children,
}) => {
    if (isLoading) {
        return (
            <p className={styles.loader}>Loading...</p>
        );
    }

    if (httpError) {
        return (
            <>
                <p className={styles.error}>Error, please try again.</p>
            </>
        );
    }

    return (
        <>
            {children}
        </>
    );
}

export default ApiErrorLoaderWrapper;