import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ApiErrorLoaderWrapper,
  AppButton,
  AppCard,
  AppCardContent,
  AppCardTitle,
  AppCheckbox,
  PageTitle,
} from "../../Components";
import {
  actions,
  aggregateSelectors,
  selectors,
  useDispatchEffect,
} from "../../store";
import styles from "./SelectOptionalExtras.module.css";
import { makeBookingPathBuilders, useCarId } from "./makeBookingPaths";
import { useHistory } from "react-router-dom";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";
import { useLocalisation } from "../../Components/Localisation";

export interface SelectOptionalExtrasProps {}

const SelectOptionalExtras: React.FC<SelectOptionalExtrasProps> = () => {
  const history = useHistory();
  const carId = useCarId();
  const { currency } = useLocalisation();
  const dispatch = useDispatch();
  const selectedOptionalExtraIds = useSelector(
    selectors.makeBooking.makeSelectPackgeOptionalExtraIdsForCar(carId)
  );
  const optionalExtraData = useSelector(
    aggregateSelectors.makeSelectAvailableOptionalExtrasForCarId(carId)
  );
  const packageInfoApi = useSelector(selectors.packageInfo.apiState);

  const handleOptionClick = (optionId: number) => {
    selectedOptionalExtraIds.includes(optionId)
      ? dispatch(actions.makeBooking.removePackageOption(carId, optionId))
      : dispatch(actions.makeBooking.addPackageOption(carId, optionId));
  };

  return (
    <>
      <PageTitle variant="modal">Select Optional Extras</PageTitle>
      <ApiErrorLoaderWrapper
        isLoading={packageInfoApi.isLoading}
        httpError={packageInfoApi.apiError}
      >
        {optionalExtraData.optionalExtraTypes.length === 0 && (
          <p style={{ textAlign: "center" }}>
            No optional extras are available for this package.
          </p>
        )}
        <div className={styles.root}>
          {optionalExtraData.optionalExtraTypes.map((optionalExtraCategory) => (
            <AppCard key={optionalExtraCategory} fullScreenWidth="300px">
              <AppCardTitle size="small">
                {optionalExtraCategory.toUpperCase()}
              </AppCardTitle>
              <AppCardContent>
                <ul className={styles.list}>
                  {optionalExtraData.optionalExtrasByCategory[
                    optionalExtraCategory
                  ].map((packageItem) => (
                    <li key={packageItem.packageItemId}>
                      <AppCheckbox
                        className={styles.checkbox}
                        label={`${packageItem.name} - ${
                          !packageItem.optionalExtraPrice
                            ? "POA"
                            : formatPriceWithCurrencySymbol(
                                exchangeRateAdjustedPrice(
                                  +packageItem.optionalExtraPrice.toFixed(2),
                                  currency.exchangeRate
                                ),
                                currency.symbol
                              )
                        }`}
                        description={packageItem.description}
                        checked={selectedOptionalExtraIds.includes(
                          packageItem.packageItemId
                        )}
                        onChange={() =>
                          handleOptionClick(packageItem.packageItemId)
                        }
                      />
                    </li>
                  ))}
                </ul>
              </AppCardContent>
            </AppCard>
          ))}
        </div>
        <div className={styles.buttons}>
          <AppButton
            variant="inverted"
            onClick={() => history.push(makeBookingPathBuilders.cars())}
          >
            Confirm
          </AppButton>
        </div>
      </ApiErrorLoaderWrapper>
    </>
  );
};

export default SelectOptionalExtras;
