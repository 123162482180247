import React, { useState }  from 'react';
import styles from './NewBookingModal.module.css';
import { AppModal, PageTitle } from '../../Components';
import CarsSelectList from './CarsSelectList';
import Buttons, { BookingStep } from './NewBookingModalButtons';
import AddressSelectList from './AddressSelectList';
import { useHistory } from 'react-router-dom';
import { makeBookingPathBuilders } from '../MakeBooking';

export interface NewBookingModalProps {
    isOpen: boolean,
    onClose: () => void,
}

const NewBookingModal: React.FC<NewBookingModalProps> = ({
    isOpen,
    onClose,
}) => {
    const history = useHistory();
    const [bookingStep, setBookingStep] = useState<BookingStep>('car');

    const handleBackClick = () => setBookingStep('car');

    const handleContinueClick = () => {
        if (bookingStep === 'address') {
            history.push(makeBookingPathBuilders.cars());
        }
        setBookingStep('address');
    }

    const handleClose = () => {
        setBookingStep('car');
        onClose();
    }

    return (
        <AppModal
            isOpen={isOpen}
            onClose={handleClose}
        >
            <PageTitle variant="modal">New Booking</PageTitle>
            <p
                className={styles.helpText}
            >
                Please note you can only select saved cars or addresses.
            </p>
            <div className={styles.selectList}>
                {bookingStep === 'car' ? (
                    <CarsSelectList />
                ) : (
                    <AddressSelectList />
                )}
            </div>
            <Buttons
                currentStep={bookingStep}
                onBackClick={handleBackClick}
                onContinueClick={handleContinueClick}
            />
        </AppModal>
    );
}

export default NewBookingModal;