import React, { useEffect, useState } from "react";
import { Form, FormikProps, useField, withFormik } from "formik";
import * as Yup from "yup";

import {
  AddressSearchResult,
  checkIfEmailIsAvailable,
  HttpClientResponse,
  HttpClientSuccessResponse,
} from "../../api";
import { AppInput, AppFormikSelect } from "../AppInputs";
import AppButton from "../AppButton";
import AppFormError from "../AppFormError";
import AppForm from "../AppForm/AppForm";
import { AppFormikSelectOption } from "../AppInputs/AppFormikSelect";
import AppFormikInput from "../AppInputs";
import { AppCardButtons, AppCardContent } from "../AppCard";
import styles from "./RegisterForm.module.css";
import AppFormikCalendar from "../AppCalendar/AppFormikCalendar";
import debounce from "lodash.debounce";
import AppFormikRadioGroup from "../AppInputs/AppFormikRadioGroup";
import AppInputLabel from "../AppInputs/AppInputLabel";
import AppStripeInputContainer from "../AppInputs/AppStripeInputContainer";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { pathBuilders } from "../../Routes";
import { CountryCode, useLocalisation } from "../Localisation";
import { ApiMakeBookingResponse, ApiRegisterResponse } from "../../api/Models";
import { useAuth } from "../../auth";

export interface RegisterFormValues {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  dateOfBirth?: null | string;
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string | null;
  townCity: string;
  paymentOption: string;
  billingPostcode?: string;
  isLoggedIn: boolean;
}

interface OtherProps {
  isLoadingAddresses: boolean;
  postcode: string;
  postcodeType?: string | null;
  addresses: AddressSearchResult[];
  formError: string;
}

const _RegisterForm: React.FC<OtherProps & FormikProps<RegisterFormValues>> = ({
  postcode,
  postcodeType,
  isLoadingAddresses,
  addresses,
  formError,
  isSubmitting,
  status,
}) => {
  const auth = useAuth();
  const { country } = useLocalisation();
  const [addressSelected, setAddressSelected] = useState(
    country === CountryCode.IE
  );
  const [, , addressLine2Helpers] = useField("addressLine2");
  const [, , addressLine3Helpers] = useField("addressLine3");
  const [, , townCityHelpers] = useField("townCity");
  const [, , isLoggedInHelpers] = useField("isLoggedIn");
  const [paymentOptionValue, , paymentOptionHelpers] =
    useField("paymentOption");

  const options: AppFormikSelectOption[] = addresses.map((address) => ({
    value: address.addressLine1,
    label: address.addressLine1,
    address,
  }));

  const handleAddressSelect = (value: string) => {
    const address = addresses.find((o) => o.addressLine1 === value);
    if (!address)
      throw new Error(
        `AppFormikSelect: Expected to find option for value ${value}.`
      );
    addressLine2Helpers.setValue(address.addressLine2);
    addressLine3Helpers.setValue(address.addressLine3);
    townCityHelpers.setValue(address.town);

    setAddressSelected(true);
  };

  const paymentOptionRadio =
    postcodeType === "Primary" ? (
      <AppFormikRadioGroup
        fullWidth
        name="paymentOption"
        label="Payment Option"
        placeholder="Please select your payment option"
        options={[
          {
            value: "isPayNow",
            label: "Pay now",
            description: "For a smoother experience, pay now.",
          },
          {
            value: "isPayLater",
            label: "Pay later",
            description: "Pay on the day.",
          },
        ]}
      />
    ) : null;

  useEffect(() => {
    isLoggedInHelpers.setValue(auth.isLoggedIn);
  }, [auth.isLoggedIn]);

  let buttonLabel = paymentOptionValue.value === "isPayLater"
            ? "Complete Booking"
            : "Make Payment & Complete Booking"

  if(isSubmitting) buttonLabel = "Submitting...";

  return (
    <Form>
      <AppCardContent className={styles.content}>
        <AppForm>
          <AppFormikInput
            label="Title"
            type="string"
            name="title"
            placeholder="Title"
            required
          />
          <AppFormikInput
            label="First Name"
            type="string"
            name="firstName"
            placeholder="First Name"
            required
          />
          <AppFormikInput
            label="Last Name"
            type="string"
            name="lastName"
            placeholder="Last Name"
            required
          />
          <AppFormikInput
            label="Email"
            type="email"
            name="email"
            placeholder="Email"
            required
          />
          <AppFormikInput
            label="Password"
            type="password"
            name="password"
            placeholder="Password"
            required
          />
          <Typography variant="body2" className={styles.termsAndConditions}>
            Passwords must have at least 6 characters, one digit, one lower case
            letter, one upper case letter, and one special character (e.g. "!"
            or "#").
          </Typography>
          <AppFormikInput
            label="Contact Number"
            type="string"
            name="phoneNumber"
            placeholder="Mobile Number"
            required
          />
          <AppInput label="Postcode" disabled value={postcode} />

          {isLoadingAddresses ? (
            <p>Loading...</p>
          ) : (
            !addressSelected && (
              <AppFormikSelect
                label="Address"
                name="addressLine1"
                options={options}
                placeholder="Please select your address"
                afterSelect={handleAddressSelect}
                required
              />
            )
          )}

          {addressSelected && (
            <>
              <AppFormikInput
                type="string"
                label="Address Line 1"
                name="addressLine1"
                placeholder="Address Line 1"
                required
              />
              <AppFormikInput
                type="string"
                label="Address Line 2"
                name="addressLine2"
                placeholder="Address Line 2"
              />
              <AppFormikInput
                type="string"
                label="Address Line 3"
                name="addressLine3"
                placeholder="Address Line 3"
              />
              <AppFormikInput
                type="string"
                label="Town / City"
                name="townCity"
                placeholder="Town / City"
                required
              />
            </>
          )}
          <AppFormikCalendar
            label="Date of Birth"
            name="dateOfBirth"
            maxDate={new Date()}
            minDetail="decade"
            defaultView="decade"
          />
          {paymentOptionRadio}
          {paymentOptionValue.value === "isPayNow" && (
            <>
              <AppFormikInput
                label="Billing postcode"
                name="billingPostcode"
                placeholder="Please enter your billing postcode"
                type="string"
              />
              <AppInputLabel>Card number</AppInputLabel>
              <AppStripeInputContainer>
                <CardNumberElement />
              </AppStripeInputContainer>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <AppInputLabel>Expiry date</AppInputLabel>
                  <AppStripeInputContainer>
                    <CardExpiryElement />
                  </AppStripeInputContainer>
                </Grid>
                <Grid item xs={6}>
                  <AppInputLabel>CVC number</AppInputLabel>
                  <AppStripeInputContainer>
                    <CardCvcElement />
                  </AppStripeInputContainer>
                </Grid>
              </Grid>
            </>
          )}
          <AppFormError show={status && status.isError}>
            {formError}
          </AppFormError>
        </AppForm>
      </AppCardContent>
      <AppCardButtons>
        <Typography variant="body2" className={styles.termsAndConditions}>
          Please note that by placing this booking you agree to our{" "}
          <Link to={pathBuilders.termsAndConditions()} target="_blank">
            terms and conditions 
            </Link> including cancellation fees
        </Typography>
        <AppButton type="submit" disabled={isSubmitting} variant="inverted">
          {buttonLabel}
        </AppButton>
      </AppCardButtons>
    </Form>
  );
};

interface RegisterFormProps {
  isLoggedIn: boolean;
  isLoadingAddresses: boolean;
  postcode: string;
  postcodeType?: string | null;
  addresses: AddressSearchResult[];
  formError: string;
  onSubmit: (values: RegisterFormValues) => Promise<HttpClientResponse<ApiRegisterResponse> | HttpClientResponse<ApiMakeBookingResponse> | HttpClientSuccessResponse<ApiMakeBookingResponse> |  undefined>;
  onSuccess: () => void;
}

const validateEmailIsAvailable = async (
  value: string | null | undefined,
  resolve: (result: boolean) => void
) => {
  // Just return true as other validations will fail
  if (!value) {
    resolve(true);
    return;
  }

  const response = await checkIfEmailIsAvailable(value);

  // Succeed this validation and hope for the best is only option I think (registration will fail anyway if email is in use).
  if (response.isError) {
    resolve(true);
    return;
  }

  return resolve(response.content);
};

const validateEmailIsAvailableDebounced = debounce(
  validateEmailIsAvailable,
  300
);

// We are now needing to pass in isLoggedIn state to RegisterFormValues
// to handle 3DS scenario where a user is now register and therefor email registered
// but needs to complete payment authenticated before submitting the booking
// we can use this property to then skip the email validation below
const RegisterFormSchema = Yup.object().shape<RegisterFormValues>({
  title: Yup.string()
    .required("Required.")
    .max(10, "Cannot exceed 10 characters"),
  firstName: Yup.string().required("Required."),
  lastName: Yup.string().required("Required."),
  email: Yup.string()
    .email("Invalid email.")
    .required("Required.")
    .when(['isLoggedIn'], {
      is: (isLoggedIn: boolean) => isLoggedIn === false,
      then: Yup.string()
                .email("Invalid email.")
                .required("Required.")
                .test("checkEmailUnique",
      "This email is already registered.",
      async (value) =>
        new Promise((resolve) =>
          validateEmailIsAvailableDebounced(value, resolve)
        ))
    }),
  password: Yup.string()
    .min(6, "Must be at least 6 characters.")
    .matches(
      /[^a-zA-Z\d\s]/,
      'Must contain at least one special character (e.g. "!" or "#").'
    )
    .matches(/.*[0-9].*/, "Must contain at least one digit.")
    .matches(/.*[a-z].*/, "Must contain at least one lower case letter.")
    .matches(/.*[A-Z].*/, "Must contain at least one upper case letter.")
    .required("Required."),
  dateOfBirth: Yup.string().nullable().default(null),
  phoneNumber: Yup.string().required("Required"),
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string(),
  addressLine3: Yup.string().nullable(),
  townCity: Yup.string().required("Required"),
  paymentOption: Yup.string().required("Required"),
  billingPostcode: Yup.string().when(["paymentOption"], {
    is: (paymentOption) => {
      return paymentOption === "isPayNow";
    },
    then: Yup.string()
      .required("Please enter your postcode")
      .min(5, "Missing part of your postcode")
      .max(8, "Too many characters")
      .test(
        "validatePostcodeFormat",
        "Please check you have entered a valid postcode",
        (p) => {
          if (!p) return true;
          // Postcode regex taken from fresh norse site. It's ok for this as we are cloning that site but don't copy it without testing elsewhere.
          const matches = p.match(/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i);
          return !!matches && matches.length !== 0;
        }
      ),
    otherwise: Yup.string().notRequired(),
  }),
  isLoggedIn: Yup.boolean()
});

const RegisterForm = withFormik<RegisterFormProps, RegisterFormValues>({
  mapPropsToValues: ({ postcodeType, isLoggedIn }) => ({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    dateOfBirth: null,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    townCity: "",
    paymentOption: postcodeType === "Primary" ? "isPayNow" : "isPayLater",
    postcodeType: "",
    billingPostcode: "",
    isLoggedIn
  }),
  validationSchema: RegisterFormSchema,
  handleSubmit: async (values, { props, setStatus }) => {
    setStatus({
      isError: false,
    });

    const result = await props.onSubmit(values);

    if (!result || result.isError) {
      setStatus({
        isError: true,
      });
      return;
    }

    props.onSuccess();
  },
})(_RegisterForm);

export default RegisterForm;
