import React from 'react';
import { AddressForm, AppCardsLayout, AppCardTitle } from '../../Components';
import { AppCard } from '../../Components';
import { ApiCreateAddressDto } from '../../api/Models';
import * as apiClient from '../../api';
import { apiErrorToast } from '../../toast';
import { useHistory } from 'react-router-dom';
import { pathBuilders } from '../../Routes';
import { useLocalisation } from '../../Components/Localisation';

export const AddAddressPage = () => {
    const history = useHistory();
    const { country } = useLocalisation();
    const handleSubmit = async (address: ApiCreateAddressDto) => {

        const result = await apiClient.addCustomerAddress(address);

        if (result.isError) {
            apiErrorToast(result.message);
            return;
        }

        history.push(pathBuilders.accountDashboard());
    }

    return (
        <AppCardsLayout>
            <AppCard>
                <AppCardTitle>Add Address</AppCardTitle>
                <AddressForm
                    country={country}
                    onSubmit={handleSubmit}
                    onCancel={() => history.goBack()}
                />
            </AppCard>
        </AppCardsLayout>
    )
}