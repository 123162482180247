import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import InnerResetPasswordForm, { ResetPasswordFormValues } from './ResetPasswordForm';
import { useAuth } from '../../auth';
import { pathBuilders } from '../../Routes';

export interface ResetPasswordFormContainerProps {
    
}

const initialValues: ResetPasswordFormValues = {
    newPassword: '',
    confirmNewPassword: '',
}

const ResetPasswordFormValidationSchema = Yup.object().shape<ResetPasswordFormValues>({
    newPassword: Yup.string()
        .min(6, 'Must be at least 6 characters.')
        .matches(/[^a-zA-Z\d\s]/, 'Must contain at least one character that isn\'t a number or a letter')
        .matches(/.*[0-9].*/, 'Must contain at least one digit.')
        .matches(/.*[a-z].*/, 'Must contain at least one lower case letter.')
        .matches(/.*[A-Z].*/, 'Must contain at least one upper case letter.')
        .required('Required.'),
    confirmNewPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match.'),
});
 
const ResetPasswordContainer: React.FC<ResetPasswordFormContainerProps> = () => {
    const { search } = useLocation();
    const { code, email } = queryString.parse(search);
    const { resetPassword } = useAuth();
    const history = useHistory();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ResetPasswordFormValidationSchema}
            onSubmit={async values => {
                var response = await resetPassword({
                    password: values.newPassword,
                    confirmPassword: values.confirmNewPassword,
                    code: code as string,
                    email: email as string,
                });

                if (!response.isError) {
                    history.push(pathBuilders.logIn());
                }
            }}
        >
            {formikProps => (
                <Form>
                    <InnerResetPasswordForm
                        {...formikProps}
                        email={email as string}
                    />
                </Form>
            )}
        </Formik>
    );
}
 
export default ResetPasswordContainer;