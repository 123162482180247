import React from 'react';
import cn from 'classnames';
import styles from './AppButton.module.css';

export interface AppButtonProps {
    disabled?: boolean,
    displayDisabled?: boolean,
    flexGrow?: number,
    fullWidth?: boolean,
    variant?: 'inverted'|'black'|'standard'|'white',
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    size?: 'small'|'standard',
    type?: 'button'|'submit'|'reset'
}
 
const AppButton: React.FC<AppButtonProps> = ({
    children,
    disabled,
    displayDisabled,
    flexGrow,
    fullWidth,
    variant,
    onClick,
    size,
    type,
}) => {
    return (
        <button
            onClick={onClick}
            className={cn({
                [styles.root]: true,
                [styles.inverted]: variant === 'inverted',
                [styles.black]: variant === 'black',
                [styles.white]: variant === 'white',
                [styles.small]: size === 'small',
                [styles.disabled]: displayDisabled,
            })}
            style={{
                flexGrow,
                width: fullWidth ? '100%' : undefined,
            }}
            type={type}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
 
export default AppButton;