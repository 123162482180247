import React from 'react';
import { useIsMobileMedia } from '../../mediaQueries';

import styles from './ProgressIndicator.module.css';

export interface ProgressIndicatorProps {
    currentStep: number,
}
 
const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
    currentStep,
}) => {
    const isMobile = useIsMobileMedia();

    return (
        <div className={styles.root}>
            <p className={styles.active}>{isMobile ? 'Location' : 'Location Check'}</p>
            <p className={currentStep > 0 ? styles.active : ''}>{isMobile ? 'Car' : 'Car & Package Details'}</p>
            <p className={currentStep > 1 ? styles.active : ''}>{isMobile ? 'Date' : 'Appointment Date'}</p>
            <p className={currentStep > 2 ? styles.active : ''}>{isMobile ? 'Confirm' : 'Confirm Booking'}</p>
        </div>
    );
}
 
export default ProgressIndicator;