import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CarForm } from '../../Components';
import AppCard, { AppCardsLayout, AppCardTitle } from '../../Components/AppCard';
import { AddCarDto } from '../../models';
import { actions, selectors, useDispatchEffect } from '../../store';
import { makeBookingPathBuilders } from '../MakeBooking';

export const AddCarNewCustomerPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(1));
    const maxExistingCarId = useSelector(selectors.customerCars.selectMaxExistingId);

    const handleSubmit = async (car: AddCarDto) => {
        const newCar = {
            ...car,
            id: maxExistingCarId + 1,
        };

        dispatch(actions.customerCars.addNewCustomerCar(newCar));
        dispatch(actions.makeBooking.selectCar(newCar.id));
        history.push(makeBookingPathBuilders.cars());
    }

    return (
        <AppCardsLayout>
            <AppCard>
                <AppCardTitle>Car Details</AppCardTitle>
                <CarForm
                    onSubmit={handleSubmit}
                />
            </AppCard>
        </AppCardsLayout>
    )
}
