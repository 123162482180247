import { ApiStandardResponse } from './common/ApiStandardResponse';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import { unpackStandardResponse } from './common/ApiStandardResponse';
import * as httpClient from './common/httpClient'
import { ApiPackageInfo } from './Models';

export const getAllPackageInfo = async () => {
    const url = buildUrl(`package-information`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiPackageInfo>>(url);
    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, m => m);
}

export const getPackageInfoByPostcode = async (postcode: string) => {
    const url = buildUrl(`package-information/${postcode}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiPackageInfo>>(url);
    doErrorToastIfRequired(response);

    return unpackStandardResponse(response, m => m);
}