import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentMethodResult } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  searchAddressesForPostcode,
  makeBookingAndRegister,
  HttpClientFailureResponse,
  AddressSearchResult,
  makeBookingExistingCustomer,
  getAllCars,
  getAllCustomerAddresses,
  HttpClientSuccessResponse,
} from "../../api";
import { useAuth } from "../../auth";
import { CountryCode, countryCodeMap, Currency, useLocalisation } from "../Localisation";
import { trackBooking } from "../../marketingHelpers";
import { BookingTimeSlot, MakeBookingAndRegisterDto, MakeBookingExistingCustomerDto } from "../../models";
import { makeBookingPathBuilders } from "../../pages";
import {
  actions,
  aggregateSelectors,
  selectors,
} from "../../store";
import RegisterForm, { RegisterFormValues } from "./RegisterForm";
import { formatPriceWithCurrencySymbol } from "../../priceHelpers";
import { ApiMakeBookingResponse } from "../../api/Models";

const errorResponse: HttpClientFailureResponse = {
  isError: true,
  message: "",
  statusCode: 0,
};

const RegisterFormContainer = () => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();
  const history = useHistory();
  const { country, currency } = useLocalisation();
  const dispatch = useDispatch();
  const [formError, setFormError] = useState("");
  const [addresses, setAddresses] = useState<AddressSearchResult[]>([]);
  const [addressSearchLoading, setAddressSearchLoading] = useState(false);
  const postcode = useSelector(selectors.makeBooking.selectSelectedPostcode);
  const postcodeType = useSelector(selectors.makeBooking.selectPostcodeType);
  const customerCarBookings = useSelector(
    aggregateSelectors.selectAllNewCustomerCarBookings
  );
  const bookingDetails = useSelector(
    selectors.makeBooking.selectBookingDetails
  );
  const bookingDetailsExistingCustomer = useSelector(selectors.makeBooking.selectMakeBookingExistingCustomerDto);

  const totalPriceSummary = useSelector(
    aggregateSelectors.selectTotalBookingPriceSummary(country)
  );

  const doAddressSearch = async () => {
    setAddressSearchLoading(true);
    const result = await searchAddressesForPostcode(postcode);

    if (!result.isError) {
      setAddresses(result.content);
    }

    setAddressSearchLoading(false);
  };

  const setErrorMessageAndGetResponse = (
    errorMessage: string
  ): HttpClientFailureResponse => {
    setFormError(errorMessage);
    return {
      isError: true,
      message: "",
      statusCode: 0,
    };
  };

  const setPaymentDetails = async (paymentMethod: string) => {
    dispatch(actions.makeBooking.setPaymentMetehod(paymentMethod));
  };

  const setRegisterAndBookingDetails = (
    registerAndBookDetails: RegisterFormValues
  ) => {
    dispatch(
      actions.makeBooking.setBookingDetails({
        date: bookingDetails.date,
        additionalComments: bookingDetails.additionalComments,
        timeSlot: bookingDetails.timeSlot as BookingTimeSlot,
        automatedSlot: bookingDetails.automatedSlot
          ? bookingDetails.automatedSlot
          : null,
        paymentOption: registerAndBookDetails.paymentOption,
      })
    );

    dispatch(
      actions.makeBooking.setCustomerDetais({
        ...registerAndBookDetails,
        postcode,
        dateOfBirth: registerAndBookDetails.dateOfBirth as string,
        country: countryCodeMap[country],
      })
    );
  };

  const handleSubmit = async (
    values: RegisterFormValues
  ) => {
    const isPayNow = values.paymentOption === "isPayNow";

    let createPaymentMethodResponse: PaymentMethodResult | undefined =
      undefined;
    let errorMessage =
      "There was a problem with your payment method. Please check your details and try again.";

    setRegisterAndBookingDetails(values);

    if (isPayNow) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        return setErrorMessageAndGetResponse(errorMessage);
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        return setErrorMessageAndGetResponse(errorMessage);
      }

      createPaymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          address: {
            postal_code: values.billingPostcode,
          },
        },
      });

      if (
        createPaymentMethodResponse.error ||
        !createPaymentMethodResponse.paymentMethod
      ) {
        return setErrorMessageAndGetResponse(errorMessage);
      } else {
        // Need to check if the user is authenticated in scenario where initial 3DS has failed
        // and user has had to re-submit
        if(auth.isLoggedIn) {
          return makeBookingExisting(isPayNow, createPaymentMethodResponse.paymentMethod.id);
        }

        await setPaymentDetails(createPaymentMethodResponse.paymentMethod.id);
        return await makeBookingRegister(values, isPayNow, createPaymentMethodResponse.paymentMethod.id);
      }
    }

    if(!isPayNow) {
      // Need to check if the user is authenticated in scenario where initial 3DS has failed
      // and user has had to re-submit
      if(auth.isLoggedIn) {
        return makeBookingExisting(isPayNow);
      }

      return await makeBookingRegister(values, isPayNow);
    }
  };

  const makeBookingRegister = async (
    values: RegisterFormValues,
    isPayNow: boolean,
    paymentMethodId?: string,
    paymentIntentId?: string) => {
        const formattedPriceInEuros = currency.currency === Currency.EUR ?
          formatPriceWithCurrencySymbol(
            totalPriceSummary.grandTotal,
            currency.symbol
          ) : null;

        const additionalComments = currency.currency === Currency.EUR ?
          bookingDetails.additionalComments + `\n\nPrice displayed in euros: ${formattedPriceInEuros}` :
          bookingDetails.additionalComments ;

        const bookingRequest: MakeBookingAndRegisterDto = {
          carBookings: customerCarBookings,
          customerDetails: {
            ...values,
            postcode,
            dateOfBirth: values.dateOfBirth as string,
            country: countryCodeMap[country],
          },
          requestedDate: bookingDetails.date,
          timeSlot: bookingDetails.timeSlot || undefined,
          automatedSlot: bookingDetails.automatedSlot || undefined,
          additionalComments,
          currency: currency.currency,
          paymentDetails: {
            isPayNow,
            paymentMethodId: paymentMethodId,
            paymentIntentId: paymentIntentId
          },
        };

        const response = await makeBookingAndRegister(bookingRequest);

        if(response.isError) {
          return setErrorMessageAndGetResponse(response.message);
        }

        if (response.content.paymentDetails?.requiresAction) {
            const { paymentIntentClientSecret } = response.content.paymentDetails;

            auth.setLogInInformation(
              response.content.userDetails,
              response.content.userDetails.token
            );

            return await handleAdditionalPaymentActionNew(
              paymentIntentClientSecret
            );
          }

        if (!response.isError) {
          auth.setLogInInformation(
            response.content.userDetails,
            response.content.userDetails.token
          );
          dispatch(
            actions.makeBooking.setCreatedBookingStatusId(
              response.content.booking.bookingStatusId
            )
          );
          trackBooking("Booking");
        } else {
          setFormError("Registration failed.");
        }

        return response;
  };

  const makeBookingExisting = async (isPayNow: boolean, paymentMethodId?: string, paymentIntentId?: string): Promise<HttpClientFailureResponse | HttpClientSuccessResponse<ApiMakeBookingResponse> | undefined> => {
    const formattedPriceInEuros = currency.currency === Currency.EUR ?
          formatPriceWithCurrencySymbol(
            totalPriceSummary.grandTotal,
            currency.symbol
          ) : null;

        const additionalComments = currency.currency === Currency.EUR ?
          bookingDetails.additionalComments + `\n\nPrice displayed in euros: ${formattedPriceInEuros}` :
          bookingDetails.additionalComments ;

        const customerCarsResponse = await getAllCars();
        const customerAddressesResponse = await getAllCustomerAddresses();

        if(customerCarsResponse.isError || customerAddressesResponse.isError) return setErrorMessageAndGetResponse("Could not fetch customer details");

        const customerCars = customerCarsResponse.content;
        const customerAddresses = customerAddressesResponse.content;
    
        const bookingDto: MakeBookingExistingCustomerDto = {
          booking: {
            ...bookingDetailsExistingCustomer.booking,
            bookingCustomerCars: 
              customerCars.map((customerCar, i) => ({
                bookingCustomerCarId: 0, 
                bookingId: 0,
                customerCarId: customerCar.id,
                makeAndModel: customerCarBookings[i].carMakeModel,
                registrationNumber: customerCarBookings[i].carNumberplate,
                category: customerCarBookings[i].category,
                packageGroupId: customerCarBookings[i].packageId,
                optionalExtras: customerCarBookings[i].optionalExtraIds.map(i => ({ packageItemId: i}))
              })),
            addressId: customerAddresses[0].id,
            additionalComments
          },
          paymentDetails: {
            isPayNow,
            paymentMethodId,
            paymentIntentId
          }
        };

        const response = await makeBookingExistingCustomer(bookingDto);

        if (response.isError) return response;

        if (response.content.paymentDetails?.requiresAction) {
            const { paymentIntentClientSecret } = response.content.paymentDetails;

            return await handleAdditionalPaymentActionNew(
              paymentIntentClientSecret
            );
          }

        dispatch(
          actions.makeBooking.setCreatedBookingStatusId(
            response.content.bookingStatusId
          )
        );
        trackBooking("Booking");
        return response;
  };

  const handleAdditionalPaymentActionNew = async (
    paymentIntentClientSecret: string
  ) => {
    if (stripe) {
      const cardAction = await stripe.handleCardAction(
        paymentIntentClientSecret
      );

      if (cardAction.error) return setErrorMessageAndGetResponse(cardAction.error.message ?? "");
;

      dispatch(actions.customerCars.fetch);
      dispatch(actions.customerAddresses.fetch);
      return makeBookingExisting(true, undefined, cardAction.paymentIntent.id);
    }
  };

  const handleSuccess = () => {
    const path = makeBookingPathBuilders.enquirySent();

    history.push(path);
  };

  useEffect(() => {
    if (country !== CountryCode.IE) {
      doAddressSearch();
    }
  }, []);

  return (
    <RegisterForm
      isLoggedIn={auth.isLoggedIn}
      postcode={postcode}
      postcodeType={postcodeType}
      isLoadingAddresses={addressSearchLoading}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      addresses={addresses}
      formError={formError}
    />
  );
};

export default RegisterFormContainer;
