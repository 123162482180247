import React from 'react';
import classnames from 'classnames';
import styles from './AppCard.module.css';

export interface AppCardTitleProps {
    subtitle?: React.ReactNode,
    size?: 'standard'|'small',
}
 
const AppCardTitle: React.SFC<AppCardTitleProps> = ({
    children,
    subtitle,
    size,
}) => {
    return (
        <div className={classnames({
            [styles.title]: true,
            [styles.small]: size === 'small',
        })}>
            <h1>{children}</h1>
            {subtitle && <h2>{subtitle}</h2>}
        </div>
    );
}
 
export default AppCardTitle;