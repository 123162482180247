import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

import styles from './AppFormikRadioGroup.module.css';
import AppInputLabel from './AppInputLabel';
import AppInputError from './AppInputError';
import AppCheckbox from './AppCheckbox';

export interface AppFormikRadioGroupOption {
    value: string,
    label: string,
    description: string,
}

export interface AppFormikRadioGroupProps<T> {
    name: string,
    placeholder: string,
    options: T[],
    label?: string,
    required?: boolean,
    fullWidth?: boolean,
}

function AppFormikRadioGroup<T extends AppFormikRadioGroupOption>({
    name,
    placeholder,
    options,
    label,
    required,
    fullWidth,
}: AppFormikRadioGroupProps<T>) {
    const [inputProps, fieldMeta, fieldHelpers] = useField(name);

    return (
        <div>
            <AppInputLabel required={required}>{label}</AppInputLabel>
            <div className={classNames({
                [styles.inputContainer]: true,
                [styles.wrap]: !fullWidth
            })}>
                {options.length > 0 ?
                    <>
                        {options.map(option => (
                            <AppCheckbox
                                key={option.value}
                                className={styles.checkbox}
                                label={option.label}
                                description={option.description}
                                checked={inputProps.value === option.value}
                                onChange={() => fieldHelpers.setValue(option.value)}
                            />
                        ))}
                    </>
                    :
                    <p className={styles.emptyState}>{placeholder}</p>
                }

            </div>
            {
                fieldMeta.touched && fieldMeta.error && (
                    <AppInputError>{fieldMeta.error}</AppInputError>
                )
            }
        </div >
    );
};

export default AppFormikRadioGroup;