import { CarLookup } from '../models';
import { ApiMakeModelSearchResult, parseApiCarSearchResponse } from './Models';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import { ApiStandardResponse, unpackStandardResponse } from './common/ApiStandardResponse';
import * as httpClient from './common/httpClient'

export const queryCarModels = async (query: string) => {
    const url = buildUrl(`carLookup/make-model-filter/${query}`);
    const response =  await httpClient.getRequest<ApiStandardResponse<ApiMakeModelSearchResult[]>>(url);

    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, response => response.map(parseApiCarSearchResponse));
}

export const lookUpCarByNumberPlate = async (numberPlate: string) => {
    const url = buildUrl(`carLookup/registration-lookup/${numberPlate}`);

    let response = await httpClient.getRequest<ApiStandardResponse<CarLookup>>(url);

    return unpackStandardResponse(response, m => m);
}