import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import * as apiClient from '../api';
import { HttpClientFailureResponse } from '../api';
import { ExistingBooking } from '../models';

export const sliceName = 'existingBookings';

const asyncThunk = createAsyncThunk<
    ExistingBooking[],
    void,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/fetchAll`,
    async (_, { rejectWithValue }) => {
        var response = await apiClient.getAllExistingBookings();
        if (response.isError) {
            return rejectWithValue(response);
        }
        
        return response.content;
    }
)

const entityAdapter = createEntityAdapter<ExistingBooking>({
    selectId: p => p.id,
    sortComparer: (a, b) => a.requestedDate.localeCompare(b.requestedDate),
});

type SliceState = {
    entities: EntityState<ExistingBooking>,
    isLoading: boolean,
    apiError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    entities: entityAdapter.getInitialState(),
    isLoading: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(asyncThunk.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(asyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.apiError = payload;
        });
        builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            entityAdapter.removeAll(state.entities);
            entityAdapter.setAll(state.entities, payload);
        });
    }
});

export const {
    reducer,
} = slice;

export const actions = {
    fetch: asyncThunk,
    fetchAllIfRequired: () => (dispatch: any, getState: () => SliceState) => {
        const allBookings = selectAll(getState());
        if (allBookings.length === 0) {
            dispatch(asyncThunk());
        }
    }
};

const selectSliceState = (state: any) => state[sliceName] as SliceState;

const entityAdapterSelectors = entityAdapter.getSelectors();

const selectAll = createSelector(
    selectSliceState,
    state => entityAdapterSelectors.selectAll(state.entities)
)

export const selectors = {
    apiState: createSelector(
        selectSliceState,
        selectAll,
        (state, allBookings) => ({
            isLoading: state.isLoading,
            apiError: state.apiError,
            bookings: allBookings,
        }),
    ),
    all: selectAll,
};