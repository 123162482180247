import { FormikProps } from 'formik';
import React from 'react';
import AppButton from '../AppButton';
import { AppCardButtons, AppCardContent, AppCardMessage } from '../AppCard';
import AppForm from '../AppForm';
import AppFormikInput from '../AppInputs';

export interface ResetPasswordFormValues {
    email: string,
}

interface OtherProps {
    emailHasBeenSent: boolean,
}

const ResetPasswordForm: React.FC<OtherProps & FormikProps<ResetPasswordFormValues>> = ({
    emailHasBeenSent,
    isSubmitting,
}) => {
    return (
        <>
            <AppCardContent>
                {emailHasBeenSent ? (
                    <AppCardMessage>
                        If this email address is associated with an account, an email has been sent containing a link to reset your password. Please allow a few minutes for the email to arrive.
                    </AppCardMessage>
                ) : (
                    <AppForm>
                        <AppFormikInput name="email" type="email" label="Email" placeholder="Email" />
                    </AppForm>
                )}
            </AppCardContent>
            <AppCardButtons>
                <AppButton disabled={isSubmitting || emailHasBeenSent} type="submit">{emailHasBeenSent ? 'Submitted' : 'Submit'}</AppButton>
            </AppCardButtons>
        </>
    );
}

export default ResetPasswordForm;