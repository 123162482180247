import {
  ApiCustomerCarBooking,
  ApiDetailedCustomerCarBookingDto,
  ApiExistingBookingDto,
  ApiExistingBookingsDto,
  ApiExistingDetailedBookingDto,
  CancellationReasonDto,
} from "../api/Models";
import { Currency } from "../Components/Localisation";
import { NewCustomerDetails } from "./NewCustomerDetails";

export type BookingTimeSlot = "morning" | "afternoon";

export interface CustomerCarBooking {
  id: number;
  bookingId: number;
  customerCarId: number;
  makeAndModel: string;
  category: string;
  registrationNumber: string;
  packageId: number;
  optionalExtraIds: number[];
}

export interface MakeBookingExistingCustomerCarDto {
  bookingCustomerCarId: number;
  bookingId: number;
  customerCarId: number;
  makeAndModel: string;
  registrationNumber: string;
  category?: string;
  packageGroupId: number;
  optionalExtras: { packageItemId: number }[];
}

export interface MakeBookingPaymentDetailsDto {
  isPayNow: boolean;
  paymentMethodId?: string | null;
  paymentIntentId?: string;
  paymentIntentClientSecret?: string;
  requiresAction?: boolean;
}

export interface MakeBookingExistingCustomerDto {
  booking: {
    bookingCustomerCars: MakeBookingExistingCustomerCarDto[];
    addressId: number;
    requestedDate: string;
    confirmedDate?: string;
    timeSlot?: BookingTimeSlot;
    additionalComments: string;
    bookingStatusId: number;
    preferredTime: string;
    bookingId: number;
    enquiryStatus: string;
    resourceId?: number;
    resourceName: string;
    timeOfDay: string;
    bookingReferenceNumber: string;
    cancellationReasonId?: number;
    cancellationReasonNotes?: string;
  };
  paymentDetails: MakeBookingPaymentDetailsDto;
}

export interface FirstTimeCarBooking {
  carMakeModel: string;
  carNumberplate: string;
  category: string;
  colour: string;
  year: string;
  packageId: number;
  optionalExtraIds: number[];
}

export interface MakeBookingAndRegisterDto {
  carBookings: FirstTimeCarBooking[];
  customerDetails: NewCustomerDetails;
  requestedDate: string;
  timeSlot?: BookingTimeSlot;
  automatedSlot?: string;
  additionalComments: string;
  paymentDetails: MakeBookingPaymentDetailsDto;
  currency: Currency;
}

export interface Booking {
  id: number;
  additionalComments: string;
  addressId: number;
  bookingCustomerCars: CustomerCarBooking[];
  bookingReferenceNumber: string;
  bookingStatusId: number;
  confirmedDate?: string;
  enquiryStatus: string;
  preferredTime: string;
  requestedDate: string;
  resourceName: string;
  resourceId?: number;
  timeOfDay: string;
  totalCost: number;
  isPrepaid: boolean;
  currency: Currency;
  cancellationReasonId?: number;
  cancellationReasonNotes?: string;
}

export interface ExistingBooking extends Booking {
  isPast: boolean;
}

export interface CancellationReason {
  id: number;
  reason: string;
}

export const parseApiBookingCustomerCar = ({
  bookingCustomerCarId,
  bookingId,
  customerCarId,
  makeAndModel,
  category,
  registrationNumber,
  packageGroupId,
  optionalExtras,
}: ApiCustomerCarBooking): CustomerCarBooking => ({
  id: bookingCustomerCarId,
  bookingId,
  customerCarId,
  makeAndModel,
  category,
  registrationNumber,
  packageId: packageGroupId,
  optionalExtraIds: optionalExtras.map(
    (optionalExtra) => optionalExtra.packageItemId
  ),
});

export const parseApiDetailedCustomerCarBookingDto = ({
  bookingCustomerCarId,
  bookingId,
  customerCarId,
  packageGroupId,
  registrationNumber,
  makeAndModel,
  category,
  detailedOptionalExtras,
}: ApiDetailedCustomerCarBookingDto): CustomerCarBooking => ({
  id: bookingCustomerCarId,
  bookingId,
  customerCarId,
  makeAndModel,
  category,
  registrationNumber,
  packageId: packageGroupId,
  optionalExtraIds: detailedOptionalExtras.map(
    (detailedOptionalExtra) => detailedOptionalExtra.packageItemId
  ),
});

export const parseApiDetailedBookingDto = ({
  bookingId,
  bookingAddress,
  requestedDate,
  confirmedDate,
  totalCost,
  bookingStatusId,
  enquiryStatus,
  resourceName,
  resourceId,
  bookingReferenceNumber,
  timeOfDay,
  additionalComments,
  bookingCustomerCarPackages,
  isPrepaid,
  currency,
  cancellationReasonId,
  cancellationReasonNotes,
}: ApiExistingDetailedBookingDto): Booking => ({
  additionalComments,
  addressId: bookingAddress.addressId,
  bookingCustomerCars: bookingCustomerCarPackages.map(
    parseApiDetailedCustomerCarBookingDto
  ),
  id: bookingId,
  bookingReferenceNumber,
  bookingStatusId,
  confirmedDate,
  enquiryStatus,
  preferredTime: timeOfDay,
  requestedDate,
  resourceName,
  resourceId,
  timeOfDay,
  totalCost,
  isPrepaid,
  currency,
  cancellationReasonId,
  cancellationReasonNotes,
});

export const parseApiBookingDto = ({
  additionalComments,
  addressId,
  bookingCustomerCars,
  bookingId,
  bookingReferenceNumber,
  bookingStatusId,
  confirmedDate,
  enquiryStatus,
  preferredTime,
  requestedDate,
  resourceName,
  timeOfDay,
  totalCost,
  isPrepaid,
  currency,
  cancellationReasonId,
  cancellationReasonNotes,
}: ApiExistingBookingDto): Booking => ({
  additionalComments,
  addressId,
  bookingCustomerCars: bookingCustomerCars.map(parseApiBookingCustomerCar),
  id: bookingId,
  bookingReferenceNumber,
  bookingStatusId,
  confirmedDate,
  enquiryStatus,
  preferredTime,
  requestedDate,
  resourceName,
  timeOfDay,
  totalCost,
  isPrepaid,
  currency,
  cancellationReasonId,
  cancellationReasonNotes,
});

export const parseApiExistingBookingsDto = ({
  previousBookings,
  upcomingBookings,
}: ApiExistingBookingsDto): ExistingBooking[] => {
  const previous = previousBookings.map(parseApiBookingDto);
  const upcoming = upcomingBookings.map(parseApiBookingDto);

  return previous
    .map((booking) => ({
      ...booking,
      isPast: true,
    }))
    .concat(
      upcoming.map((booking) => ({
        ...booking,
        isPast: false,
      }))
    );
};