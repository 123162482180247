import React from 'react';
import { useSelector } from 'react-redux';

import { ApiErrorLoaderWrapper } from '../../Components';
import { actions, selectors, useDispatchEffect } from '../../store';
import BookingsList from './BookingsList';

export interface BookingsListContainerProps{
    onRebook: () => void,
}

const BookingsListContainer: React.FC<BookingsListContainerProps> = ({
    onRebook
}) => {
    const {
        isLoading,
        apiError,
        bookings,
    } = useSelector(selectors.existingBookings.apiState);

    useDispatchEffect(actions.existingBookings.fetch);

    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <BookingsList 
            bookings={bookings}
            onRebook={onRebook}
        />
        </ApiErrorLoaderWrapper>
    )
}

export default BookingsListContainer;