import { ApiStandardResponse, unpackStandardResponse } from "./common/ApiStandardResponse";
import { buildUrl } from "./common/buildUrl";
import { doErrorToastIfRequired } from "./common/doErrorToastIfRequired";
import { ApiValeter } from "./Models/PreferredValeters";
import * as httpClient from './common/httpClient'

export const getAllPostcodeCoveringValeters = async (postcode: string, packageIds?: number[]) => {
    const url = buildUrl(`Postcode/PostcodeResource/${postcode}${packageIds ? `?commaSeparatedPackageGroupIds=${packageIds}` : ""}`);
    const response = await httpClient.getRequest<ApiStandardResponse<ApiValeter[]>>(url);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, m => m);
}
