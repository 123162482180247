import React from 'react';

import AppCheckbox from './AppCheckbox';
import { useField } from 'formik';

export interface AppFormikCheckboxProps {
    className?: string,
    name: string,
    label: string,
    description?: string,
}

const AppFormikCheckbox: React.FC<AppFormikCheckboxProps> = (props) => {
    const { name, ...restOfProps } = props;
    const [fieldInputProps, , fieldHelpers] = useField(name);
    return (
        <AppCheckbox
            {...restOfProps}
            checked={fieldInputProps.value}
            onChange={() => fieldHelpers.setValue(!fieldInputProps.value)}
        />
    );
}

export default AppFormikCheckbox;