import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '../auth';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const LogInAsPage: React.FC = () => {
    const auth = useAuth();
    const history = useHistory();
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchQueryParameters = () => {
        setIsLoading(true);
        const token = query.get("token");
        const displayName = query.get("displayName");
        const email = query.get("email");
        const customerId = query.get("customerId");

        if(displayName && token && email && customerId) {
            logInUser(token, displayName, email, customerId);
        } else {
            setIsLoading(false);
            setError("There was a problem logging in as user");
        }
        
    }

    const logInUser = (token: string, displayName: string, email: string, customerId: string) => {
        auth.setLogInInformation({ displayName, email, customerId: parseInt(customerId)}, token);
        history.push('/account-dashboard');
    }

    useEffect(() => {
        fetchQueryParameters();
    });

    return (
        <>
            <h1>Log In As</h1>
            {isLoading && <p>Loading...</p>}
            {error && <p>{error}</p>}
        </>
    );
}