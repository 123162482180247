import React from 'react';
import { AppButton, AppCard, AppCardButtons, AppCardContent, AppCardTitle, AppFormikCheckbox } from '../../Components';
import AppForm from '../../Components/AppForm';
import consentItems from './consentItems'
import styles from './AccountManagement.module.css';
import { Form, FormikProps, withFormik } from 'formik';

export interface UserConsentFormValues {
    emailConsent: boolean,
    phoneConsent: boolean,
    smsConsent: boolean,
    postalConsent: boolean,
}

export interface OtherProps {
    initialValues: {
        emailConsent?: boolean,
        phoneConsent?: boolean,
        smsConsent?: boolean,
        postalConsent?: boolean,
    },
    onCancel: () => void,
}

const InnerUpdateConsentCard: React.FC<OtherProps & FormikProps<UserConsentFormValues>> = ({
    onCancel,
    isSubmitting,
}) => {

    return (
        <AppCard maxFullScreenWidth="50%">
            <AppCardTitle size="small">Update Consent</AppCardTitle>
            <Form>
                <AppCardContent>
                    <AppForm>
                        <ul className={styles.consentItemsContainer}>
                            <li>
                                <AppFormikCheckbox
                                    label={consentItems.emailConsent.label}
                                    description={consentItems.emailConsent.description}
                                    name="emailConsent"
                                />
                            </li>
                            <li>
                                <AppFormikCheckbox
                                    label={consentItems.phoneConsent.label}
                                    description={consentItems.phoneConsent.description}
                                    name="phoneConsent"
                                />
                            </li>
                            <li>
                                <AppFormikCheckbox
                                    label={consentItems.smsConsent.label}
                                    description={consentItems.smsConsent.description}
                                    name="smsConsent"
                                />
                            </li>
                            <li>
                                <AppFormikCheckbox
                                    label={consentItems.postalConsent.label}
                                    description={consentItems.postalConsent.description}
                                    name="postalConsent"
                                />
                            </li>
                        </ul>
                    </AppForm>
                </AppCardContent>
                <AppCardButtons>
                    <AppButton onClick={onCancel}>Cancel</AppButton>
                    <AppButton type="submit" disabled={isSubmitting} variant="inverted">Save Details</AppButton>
                </AppCardButtons>
            </Form>
        </AppCard>
    );
}

interface UserConsentFormProps {
    initialValues: {
        emailConsent?: boolean,
        phoneConsent?: boolean,
        smsConsent?: boolean,
        postalConsent?: boolean,
    },
    onSubmit: (values: UserConsentFormValues) => Promise<void>,
    onCancel: () => void,
}

const UserConsentCard = withFormik<UserConsentFormProps, UserConsentFormValues>({
    mapPropsToValues: ({ initialValues }) => ({
        emailConsent: initialValues.emailConsent || false,
        phoneConsent: initialValues.phoneConsent || false,
        smsConsent: initialValues.smsConsent || false,
        postalConsent: initialValues.postalConsent || false,
    }),
    handleSubmit: async (values, { props: { onSubmit } }) => {
        console.log("updating consent");
        await onSubmit(values);
    }
})(InnerUpdateConsentCard);

export default UserConsentCard;