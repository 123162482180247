import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton, AppCard, AppCardButtons, AppCardContent, AppCardTitle } from '../../Components';
import { Car } from '../../models';
import { aggregateSelectors } from '../../store';
import CarEnquirySummary from './CarEnquirySummary';

export interface ViewCarsCardProps {
    car: Car,
    onCarClick: (carId: number) => void,
}
 
const ViewCarsCard: React.FC<ViewCarsCardProps> = ({
    car,
    onCarClick,
}) => {
    const carPackage = useSelector(aggregateSelectors.makeSelectPackageForCar(car.id));
    const optionalExtras = useSelector(aggregateSelectors.makeSelectOptionalExtrasForCar(car.id));

    return (
        <AppCard key={car.id} maxFullScreenWidth="400px" fullScreenWidth="100%">
            <AppCardTitle subtitle={car.registrationNumber}>
                {car.makeAndModel}
            </AppCardTitle>
            <AppCardContent>
                <CarEnquirySummary
                    carPackage={carPackage}
                    carCategory={car.category}
                    optionalExtras={optionalExtras}
                />
            </AppCardContent>
            <AppCardButtons>
                <AppButton
                    variant={carPackage ? 'standard' : 'inverted'}
                    fullWidth
                    onClick={() => onCarClick(car.id)}
                >
                    {carPackage ? 'Update Package' : 'Select Package'}
                </AppButton>
            </AppCardButtons>
        </AppCard>
    )
}
 
export default ViewCarsCard;