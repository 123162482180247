import { AddCarDto, parseApiCars } from '../models';
import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import { ApiStandardResponse, unpackStandardResponse } from './common/ApiStandardResponse';
import * as httpClient from './common/httpClient'
import { ApiCar } from './Models';

export const getAllCars = async () => {
    const url = buildUrl('cars');
    const response = await httpClient.getRequest<ApiStandardResponse<ApiCar[]>>(url);

    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, parseApiCars);
}

export const addCar = async (car: AddCarDto) => {
    const url = buildUrl('cars');
    const response = await httpClient.postRequest(url, car);
    doErrorToastIfRequired(response);
    return response;
}

export const deleteCar = async (id: number) => {
    const url = buildUrl(`cars/${id}`);
    const response = await httpClient.patchRequest(url, { isDeleted: true });
    doErrorToastIfRequired(response);
    return response;
}