import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit";
import * as apiClient from '../api';
import { HttpClientFailureResponse } from "../api";
import { PreferredValeter } from "../models/PreferredValeters";
import { authClient } from '../auth';

export const sliceName = 'preferredValeter';

const asyncThunk = createAsyncThunk<
    PreferredValeter[],
    void,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/fetchAll`,
    async (_, { rejectWithValue }) => {
        var response = await apiClient.getAllPreferredValeters();

        if (response.isError) {
            return rejectWithValue(response);
        }

        return response.content;
    }
)

const deleteThunk = createAsyncThunk<
    number,
    number,
    {
        rejectValue: HttpClientFailureResponse
    }
>(
    `${sliceName}/delete`,
    async (customerPreferredValeterId, { rejectWithValue }) => {
        var response = await apiClient.deletePreferredValeter(customerPreferredValeterId);
        if (response.isError) {
            return rejectWithValue(response);
        }
        return customerPreferredValeterId;
    }
)


const entityAdapter = createEntityAdapter<PreferredValeter>({
    selectId: p => p.customerPreferredValeterId,
});

type SliceState = {
    entities: EntityState<PreferredValeter>,
    isLoading: boolean,
    isDeleting: boolean,
    apiError?: HttpClientFailureResponse,
    deleteApiError?: HttpClientFailureResponse,
}

const initialState: SliceState = {
    entities: entityAdapter.getInitialState(),
    isLoading: false,
    isDeleting: false,
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(asyncThunk.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(asyncThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.apiError = payload;
        });
        builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            entityAdapter.removeAll(state.entities);
            entityAdapter.setAll(state.entities, payload);
        });
        builder.addCase(deleteThunk.pending, state => {
            state.isDeleting = true;
        });
        builder.addCase(deleteThunk.rejected, (state, { payload }) => {
            state.isDeleting = false;
            state.deleteApiError = payload;
        });
        builder.addCase(deleteThunk.fulfilled, (state, { payload }) => {
            state.isDeleting = false;
            entityAdapter.removeOne(state.entities, payload);
        });
    }
});

export const {
    reducer,
} = slice;

export const actions = {
    fetch: asyncThunk,
    fetchAllIfRequired: () => (dispatch: any, getState: () => SliceState) => {
        if (!authClient.getAccountInformation()) return;
        
        const allPreferredValeters = selectAll(getState());
        if (allPreferredValeters.length === 0) {
            dispatch(asyncThunk());
        }
    },
    deletePreferredValeter: (customerPreferredValeterId: number) => deleteThunk(customerPreferredValeterId),
};

const selectSliceState = (state: any) => state[sliceName] as SliceState;

const entityAdapterSelectors = entityAdapter.getSelectors();

const selectAll = createSelector(
    selectSliceState,
    state => entityAdapterSelectors.selectAll(state.entities)
)

export const selectors = {
    apiState: createSelector(
        selectSliceState,
        selectAll,
        (state, allPreferredValeters) => ({
            isLoading: state.isLoading,
            apiError: state.apiError,
            preferredValeters: allPreferredValeters,
        }),
    ),
    all: selectAll,
};
