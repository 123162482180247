import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Car } from '../../models';
import { AppButton, AppCardList, ApiErrorLoaderWrapper } from '../../Components';
import { actions, selectors, useDispatchEffect } from '../../store';

const CarsList: React.FC = () => {
    const {
        isLoading,
        isDeleting,
        apiError,
    } = useSelector(selectors.customerCars.apiState);
    const cars = useSelector(selectors.customerCars.all);
    const dispatch = useDispatch();

    const deleteCar = (carId: number) => dispatch(actions.customerCars.deleteSavedCar(carId));

    const confirmDeleteCar = (carId: number) => {
        const confirmed = window.confirm('This action is irreversible, are you sure you want to delete this car?');
        
        if (confirmed) {
            deleteCar(carId);
        }
    }

    useDispatchEffect(actions.customerCars.fetch);

    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={apiError}
        >
            <AppCardList
                items={cars as Car[]}
                getPrimaryInfo={car => car.makeAndModel}
                getSecondaryInfo={car => car.registrationNumber}
                emptyMessage="You don't have any cars yet."
                renderButton={carId => (
                    <AppButton
                        size="small"
                        variant="black"
                        disabled={isDeleting}
                        onClick={() => confirmDeleteCar(carId)}
                    >
                        Delete
                    </AppButton>
                )}
            />
        </ApiErrorLoaderWrapper>
    )
}

export default CarsList;