import React from "react";
import { AppModal, PageTitle } from "../../Components";
import CancelBookingFormContainer from "./CancelBookingFormContainer";

export interface CancelBookingModalProps {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: (cancellationReasonId: string, cancellationReasonNotes: string) => void,
  onRebook: () => void,
}

const CancelBookingModal: React.FC<CancelBookingModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    onRebook,
}) => {

return (
  <AppModal isOpen={isOpen} onClose={onClose}>
    <PageTitle variant="modal">Cancel Booking</PageTitle>
    <CancelBookingFormContainer onSubmit={onSubmit} onRebook={onRebook}/>
  </AppModal>
);
};
export default CancelBookingModal;