import React from 'react';
import { useField } from 'formik';

import styles from './AppInput.module.css';
import AppInputLabel from './AppInputLabel';

export interface AppFormikSelectOption {
    value: string,
    label: string,
}

export interface AppFormikSelectProps<T> {
    name: string,
    placeholder: string,
    options: T[],
    label?: string,
    afterSelect?: (option: string) => void,
    required?: boolean,
    disabled?: boolean,
}

function AppFormikSelect<T extends AppFormikSelectOption>({
    name,
    placeholder,
    options,
    label,
    afterSelect,
    required,
    disabled,
}: AppFormikSelectProps<T>) {
    const [inputProps, fieldMeta] = useField(name);
    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        inputProps.onChange(event);

        if (afterSelect) {
            const { value } = event.target;
            afterSelect(value);
        }
    }

    return (
        <div>
            <AppInputLabel required={required}>{label}</AppInputLabel>
            <div className={styles.inputContainer}>
                <select className={styles.input} {...inputProps} onChange={handleSelect} disabled={disabled}>
                    <option value="" disabled>{placeholder}</option>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            {fieldMeta.touched && fieldMeta.error && (
                <div className={styles.error}>{fieldMeta.error}</div>
            )}
        </div>
    );
};

export default AppFormikSelect;