import React, { ReactElement } from 'react';

import styles from './AppCheckbox.module.css';

export interface AppCheckboxProps {
    className?: string,
    label: string | ReactElement,
    description?: string,
    checked: boolean,
    onChange: () => void,
}

const AppCheckbox: React.FC<AppCheckboxProps> = ({
    className,
    label,
    description,
    checked,
    onChange,
}) => {
    return (
        <div>
            <label className={`${styles.root} ${className}`}>{label}
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <span />
            </label>
            {description && (
                <p className={styles.description}>{description}</p>
            )}
        </div>
    );
}

export default AppCheckbox;