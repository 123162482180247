import React from 'react';
import { useSelector } from 'react-redux';
import { faCar }  from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmationSummaryCar, selectors } from '../../store';
import styles from './EnquiryConfirmation.module.css';
import CarEnquirySummary from './CarEnquirySummary';

export interface EnquiryConfirmationCarSummaryProps {
    car: ConfirmationSummaryCar,
}

const EnquiryConfirmationCarSummary: React.FC<EnquiryConfirmationCarSummaryProps> = ({
    car,
}) => {
    const carPackage = useSelector(selectors.packageInfo.makeSelectPackageById(car.packageId));
    const optionalExtras = useSelector(selectors.packageInfo.makeSelectOptionalExtrasByIds(car.optionalExtraIds));

    return (
        <>
        {car &&
            <div className={styles.carCard}>
            <div className={styles.carTitle}>
                <div>
                    <h3>{car?.makeAndModel}</h3>
                    <p>{car?.registrationNumber}</p>
                </div>
                <FontAwesomeIcon icon={faCar} />
            </div>
            <CarEnquirySummary
                carPackage={carPackage}
                carCategory={car.category}
                optionalExtras={optionalExtras}
            />
        </div>
        }
        </>
    );
}

export default EnquiryConfirmationCarSummary;