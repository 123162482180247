import React from 'react';
import { useField } from 'formik';
import AppCalendar from './AppCalendar';
import AppInputLabel from '../AppInputs/AppInputLabel';
import AppInputError from '../AppInputs/AppInputError';
import { serialiseDate } from '../../dateHelpers';

export interface AppFormikCalendarProps {
    className?: string,
    label: string,
    name: string,
    required?: boolean,
    minDate?: Date,
    maxDate?: Date,
    minDetail?: 'year' | 'month' | 'decade' | 'century',
    defaultView?: 'year' | 'month' | 'decade' | 'century',
}
 
const AppFormikCalendar: React.SFC<AppFormikCalendarProps> = ({
    label,
    name,
    required,
    ...appCalendarProps
}) => {
    const [inputProps, fieldMeta, fieldHelpers] = useField<string>(name);
    const value = inputProps.value ? new Date(inputProps.value) : undefined;
    
    const handleChange = (newDate: Date) => {
        const serialisedDate = serialiseDate(newDate);
        fieldHelpers.setValue(serialisedDate);
    };

    return (
        <div>
            <AppInputLabel required={required}>{label}</AppInputLabel>
            <AppCalendar
                {...appCalendarProps}
                value={value}
                onChange={handleChange}
            />
            <AppInputError
                show={fieldMeta.touched && !!fieldMeta.error}
                children={fieldMeta.error}
            />
        </div>
    );
}
 
export default AppFormikCalendar;