import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutomatedSlotLookup, BookingTimeSlot, MakeBookingExistingCustomerDto } from '../models';
import { NewCustomerDetails } from '../models/NewCustomerDetails';

export const sliceName = 'makeBooking';

type RootState = {
    [sliceName]: SliceState,
}

type SliceState = {
    selectedPostcode: string,
    selectedCarIds: number[],
    selectedAddressId: number | null,
    carPackages: { [carId: number]: number },
    carPackageOptionalExtraIds: { [carId: number]: number[] },
    currentStepIndex: number,
    requestedDate: string,
    timeSlot: BookingTimeSlot | '',
    automatedSlot: string | null,
    additionalComments: string,
    postcodeType: string | null,
    createdBookingStatusId: number,
    paymentOption: string,
    paymentMethodId: string | null,
    newCustomerDetails?: NewCustomerDetails
}

const tomorrow = new Date();
tomorrow.setDate((new Date()).getDate() + 1);

const initialState: SliceState = {
    selectedPostcode: '',
    selectedCarIds: [],
    selectedAddressId: null,
    carPackages: {},
    carPackageOptionalExtraIds: {},
    currentStepIndex: 0,
    requestedDate: tomorrow.toISOString(),
    timeSlot: '',
    automatedSlot: '',
    additionalComments: '',
    postcodeType: null,
    createdBookingStatusId: -1,
    paymentOption: 'isPayNow',
    paymentMethodId: null
}

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setSelectedPostcode: {
            reducer: (state, { payload }: PayloadAction<string>) => {
                state.selectedPostcode = payload
            },
            prepare: (postcode: string) => ({
                payload: postcode
            })
        },
        setPostcodeType: {
            reducer: (state, { payload }: PayloadAction<string | null>) => {
                state.postcodeType = payload
            },
            prepare: (postcodeType: string | null) => ({
                payload: postcodeType
            })
        },
        selectCar: {
            reducer: (state, { payload }: PayloadAction<number>) => {
                state.selectedCarIds.push(payload);
            },
            prepare: (carId: number) => ({
                payload: carId,
            })
        },
        deselectCar: {
            reducer: (state, { payload }: PayloadAction<number>) => {
                state.selectedCarIds = state.selectedCarIds.filter(carId => carId !== payload);
            },
            prepare: (carId: number) => ({
                payload: carId,
            })
        },
        setSelectedAddress: {
            reducer: (state, { payload }: PayloadAction<number>) => {
                state.selectedAddressId = payload;
            },
            prepare: (addressId: number) => ({
                payload: addressId,
            })
        },
        setCurrentStepIndex: {
            reducer: (state, { payload }: PayloadAction<number>) => {
                state.currentStepIndex = payload
            },
            prepare: (newStepIndex: number) => ({
                payload: newStepIndex
            })
        },
        setCarPackage: {
            reducer: (state, { payload: { carId, packageId } }:
                PayloadAction<{ carId: number, packageId: number }>
            ) => {
                state.carPackages[carId] = packageId;
                state.carPackageOptionalExtraIds[carId] = [];
            },
            prepare: (carId: number, packageId: number) => ({
                payload: {
                    carId,
                    packageId
                }
            })
        },
        setPaymentMetehod: {
            reducer: (state, { payload }: PayloadAction<string>
            ) => {
                state.paymentMethodId = payload;
            },
            prepare: (paymentMethod: string) => ({
                payload: paymentMethod
            })
        },
        addPackageOption: {
            reducer: (state, { payload: { carId, packageItemId } }:
                PayloadAction<{ carId: number, packageItemId: number }>
            ) => {
                if (!state.carPackageOptionalExtraIds[carId]) state.carPackageOptionalExtraIds[carId] = [];
                state.carPackageOptionalExtraIds[carId].push(packageItemId);
            },
            prepare: (carId: number, packageItemId: number) => ({
                payload: {
                    carId,
                    packageItemId
                }
            })
        },
        removePackageOption: {
            reducer: (state, { payload: { carId, packageItemId } }:
                PayloadAction<{ carId: number, packageItemId: number }>
            ) => {
                state.carPackageOptionalExtraIds[carId] = state.carPackageOptionalExtraIds[carId].filter(id => id !== packageItemId);
            },
            prepare: (carId: number, packageItemId: number) => ({
                payload: {
                    carId,
                    packageItemId
                }
            })
        },
        clearPackageOptions: {
            reducer: (state, { payload: { carId } }:
                PayloadAction<{ carId: number }>
            ) => {
                state.carPackageOptionalExtraIds[carId] = [];
            },
            prepare: (carId: number) => ({
                payload: {
                    carId,
                }
            })
        },
        setBookingDetails: {
            reducer: (state, { payload: { date, additionalComments, timeSlot, automatedSlot, paymentOption } }:
                PayloadAction<{ date: string, additionalComments: string, timeSlot: BookingTimeSlot, automatedSlot: string | null, paymentOption: string }>
            ) => {
                state.requestedDate = date;
                state.additionalComments = additionalComments;
                state.timeSlot = timeSlot;
                state.automatedSlot = automatedSlot;
                state.paymentOption = paymentOption;
            },
            prepare: (bookingDetails: { date: string, additionalComments: string, timeSlot: BookingTimeSlot, automatedSlot: string | null, paymentOption: string }) => ({
                payload: {
                    ...bookingDetails,
                }
            })
        },
        setCustomerDetais: {
            reducer: (state, { payload }:
                PayloadAction<NewCustomerDetails>
            ) => {
                state.newCustomerDetails = payload;
            },
            prepare: (customerDetails: NewCustomerDetails) => ({
                payload: {
                    ...customerDetails
                }
            })
        },
        reset: () => initialState,
        setCreatedBookingStatusId: {
            reducer: (state, { payload }: PayloadAction<number>) => {
                state.createdBookingStatusId = payload;
            },
            prepare: (statusId: number) => ({
                payload: statusId
            }),
        }
    },
});

export const {
    reducer,
    actions,
} = slice;

const selectSliceState = (state: any) => state[sliceName] as SliceState;

export const selectors = {
    selectSelectedPostcode: createSelector(
        selectSliceState,
        state => state.selectedPostcode
    ),
    selectPostcodeType: createSelector(
        selectSliceState,
        state => state.postcodeType
    ),
    selectedCarIds: createSelector(
        selectSliceState,
        state => state.selectedCarIds
    ),
    makeSelectPackageIdForCarId: (carId: number) => createSelector(
        selectSliceState,
        state => state.carPackages[carId]
    ),
    selectAllCarsHavePackages: createSelector(
        selectSliceState,
        state => {
            return state.selectedCarIds
                .every(carId => {
                    const carPackage = state.carPackages[carId];
                    return carPackage !== undefined;
                });
        }
    ),
    selectSelectedAddressId: createSelector(
        selectSliceState,
        state => state.selectedAddressId,
    ),
    selectCurrentStepIndex: createSelector(
        selectSliceState,
        state => state.currentStepIndex,
    ),
    selectCarPackageIds: createSelector(
        selectSliceState,
        state => state.carPackages,
    ),
    selectCarOptionalExtraIds: createSelector(
        selectSliceState,
        state => state.carPackageOptionalExtraIds,
    ),
    makeSelectPackgeOptionalExtraIdsForCar: (carId: number) => createSelector(
        selectSliceState,
        state => state.carPackageOptionalExtraIds[carId] || [],
    ),
    selectBookingDetails: createSelector(
        selectSliceState,
        state => ({
            date: state.requestedDate,
            additionalComments: state.additionalComments,
            timeSlot: state.timeSlot,
            automatedSlot: state.automatedSlot,
            paymentOption: state.paymentOption
        })
    ),
    selectNewCustomerDetails: createSelector(
        selectSliceState,
        state => state.newCustomerDetails
    ),
    selectCreatedBookingStatusId: createSelector(
        selectSliceState,
        state => state.createdBookingStatusId,
    ),
    selectMakeBookingExistingCustomerDto: createSelector(
        selectSliceState,
        (state): MakeBookingExistingCustomerDto => {
            const automatedSlot: AutomatedSlotLookup | undefined = state.automatedSlot ? JSON.parse(state.automatedSlot) : null;

            return {
                booking: {
                    bookingCustomerCars: Object.keys(state.carPackages).map(carIdString => {
                        const carId = parseInt(carIdString);    
                        const optionalExtraIds = state.carPackageOptionalExtraIds[carId] || [];
    
                        return {
                            bookingCustomerCarId: 0,
                            bookingId: 0,
                            customerCarId: carId,
                            packageGroupId: state.carPackages[carId],
                            registrationNumber: "nd66ekv",
                            makeAndModel: "Ford Fiesta ST Turbo",
                            optionalExtras: optionalExtraIds.map(optionalExtraId => ({
                                packageItemId: optionalExtraId,
                            })),
                        }
                    }),
                    bookingId: 0,
                    addressId: state.selectedAddressId as number,
                    requestedDate: state.requestedDate,
                    // Below is a hack to deal with the fact that MBH returns a local date time at the moment.
                    // New MBH should always deal in UTC dates which means we should be able to just pass through in future.
                    confirmedDate: automatedSlot ? new Date(automatedSlot.slotStartTime).toISOString() : undefined,
                    additionalComments: state.additionalComments,
                    bookingStatusId: automatedSlot ? 2 : 1,
                    preferredTime: state.timeSlot,
                    enquiryStatus: "",
                    resourceId: automatedSlot ? automatedSlot.resourceId : undefined,
                    resourceName: "",
                    timeOfDay: state.timeSlot,
                    bookingReferenceNumber: "",
                },
                paymentDetails: {
                    isPayNow: state.paymentOption === 'isPayNow',
                    paymentMethodId: state.paymentMethodId
                }
            }
        }
    ),
};

export const selectStateToSave = (state: RootState): SliceState => {
    return selectSliceState(state);
}