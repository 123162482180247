import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PreferredValeterDTO } from "../../api/Models/PreferredValeters";
import { addPreferredValeter } from "../../api/preferredValeter";
import { AppButton } from "../../Components";
import { Booking } from "../../models";
import { actions, selectors, useDispatchEffect } from "../../store";
import { successToast } from "../../toast";
import styles from './BookingOverview.module.css';

export interface BookingValeterBoxProps {
    booking: Booking
}

const BookingValeterBox: React.FC<BookingValeterBoxProps> = ({ booking }) => {
    const {
        isLoading,
        apiError,
    } = useSelector(selectors.preferredValeter.apiState);
    const valeters = useSelector(selectors.preferredValeter.all);
    const dispatch = useDispatch();
    useDispatchEffect(actions.preferredValeters.fetch);
    const deletePreferredValeter = (customerPreferredValeterId: number) => dispatch(actions.preferredValeters.deletePreferredValeter(customerPreferredValeterId));
    const preferredValeter = valeters.find(valeter => valeter.resourceId === booking.resourceId);

    const addToFavourites = async () => {
        if (booking.resourceId) {
            if (preferredValeter) {
                deletePreferredValeter(preferredValeter.customerPreferredValeterId)
                if (!apiError) {
                    successToast("Valeter was removed from your favourites");
                }
            }
            else {
                const valet: PreferredValeterDTO = {
                    resourceName: booking.resourceName,
                    resourceId: booking.resourceId
                }
                const response = await addPreferredValeter(valet)
                if (!response.isError) {
                    dispatch(actions.preferredValeters.fetch());
                    successToast("Valeter was added to your favourites");
                }
            }
        }
    }


    return (
        <>
            <div className={styles.valetContainer}>
                <div className={styles.valeterDetails}>
                    <div className={styles.valetText}>
                        <b>
                            <p>Your Valeter was:</p>
                            <br />
                            <p>{booking.resourceName}</p>
                        </b>
                    </div>
                </div>
                <div className={styles.favouriteButton}>
                    <AppButton
                        onClick={addToFavourites}
                        size="small"
                        disabled={isLoading}
                        fullWidth
                    >
                        <FontAwesomeIcon className={preferredValeter ? styles.icon : styles.unclickedIcon} icon={faStar} />
                        Add to Favourites
                    </AppButton>
                </div>
            </div>
        </>
    );
}

export default BookingValeterBox;