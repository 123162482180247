import React, { useEffect, useState } from 'react';

import { useAuth } from '../../auth';
import PersonalDetailsCard, { PersonalDetailsFormValues } from './PersonalDetailsCard';
import UpdateConsentCard, { UserConsentFormValues } from './UpdateConsentCard';
import UpdatePasswordCard, { UpdatePasswordFormValues } from './UpdatePasswordCard';
import { useHistory } from 'react-router-dom';
import { pathBuilders } from '../../Routes';
import styles from './AccountManagement.module.css';
import { FullAccountInformation } from '../../auth/models/FullAccountInformation';
import { ApiErrorLoaderWrapper } from '../../Components';
import { HttpClientFailureResponse } from '../../api';

export interface AccountManagementProps {
    
}
 
export const AccountManagement: React.FC<AccountManagementProps> = () => {
    const auth = useAuth();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [accountInformation, setAccountInformation] = useState<FullAccountInformation|null>(null);
    const [failureResponse, setFailureResponse] = useState<HttpClientFailureResponse|undefined>();

    useEffect(() => {
        const getAccountInformation = async () => {
            var response = await auth.getFullAccountInformation();

            if (response.isError) {
                setFailureResponse(response);
            }
            else {
                setAccountInformation(response.content);
            }

            setIsLoading(false);
        };

        getAccountInformation();
    }, [auth])

    const handleCancel = () => history.push(pathBuilders.accountDashboard());

    const handleUpdateDetails = async (values: PersonalDetailsFormValues) => {
        await auth.updateUserDetails(values);
    }

    const handleUpdateConsent = async (values: UserConsentFormValues) => {
        await auth.updateUserConsent(values);
    }

    const handleUpdatePassword = async (values: UpdatePasswordFormValues) => {
       const result = await auth.updatePassword({
            ...values,
            email: accountInformation?.email as string,
        });
        return result.isError;
    }
    
    return (
        <ApiErrorLoaderWrapper
            isLoading={isLoading}
            httpError={failureResponse}
        >
            <div className={styles.root}>
                <PersonalDetailsCard
                    acccountCreatedOn={accountInformation?.createdDate}
                    initialValues={accountInformation as FullAccountInformation}
                    onSubmit={handleUpdateDetails}
                    onCancel={handleCancel}
                />
                <UpdateConsentCard
                    initialValues={{
                        emailConsent: accountInformation?.emailConsent,
                        phoneConsent: accountInformation?.phoneConsent,
                        smsConsent: accountInformation?.smsConsent,
                        postalConsent: accountInformation?.postalConsent,
                    }}
                    onSubmit={handleUpdateConsent}
                    onCancel={handleCancel}
                />
                <UpdatePasswordCard
                    onSubmit={handleUpdatePassword}
                    onCancel={handleCancel}
                />
            </div>
        </ApiErrorLoaderWrapper>
    );
}