import React from 'react';
import styles from './AppCard.module.css';

export interface AppCardsLayoutProps {
    maxColumnLayout?: boolean;
}
 
const AppCardsLayout: React.FC<AppCardsLayoutProps> = ({
    maxColumnLayout,
    children
}) => {
    return (
        <div className={maxColumnLayout ? styles.layoutContainerMaxColumn : styles.layoutContainer}>
            {children}
        </div>
    );
}

export default AppCardsLayout;