import { createSelector } from "@reduxjs/toolkit";

import { PackageItem } from "../models";
import { selectors as customerCars } from "./customerCars";
import { selectors as customerAddresses } from "./customerAddresses";
import { selectors as makeBooking } from "./makeBooking";
import { selectors as preferredValeter } from "./preferredValeters";
import { selectors as existingBookings } from "./existingBookings";
import { selectors as packageInfo } from "./packageInfo";
import { selectors as cancellationReasons } from "./cancellationReasons";
import { ApiAutomatedSlotLookup } from "../api";
import { CountryCode, countryInformationMap } from "../Components/Localisation";
import { exchangeRateAdjustedPrice } from "../priceHelpers";

export const selectors = {
  customerCars,
  customerAddresses,
  makeBooking,
  existingBookings,
  packageInfo,
  preferredValeter,
  cancellationReasons,
};

export const selectApiAutomatedSlotLookup = createSelector(
  makeBooking.selectCarPackageIds,
  makeBooking.selectCarOptionalExtraIds,
  customerCars.all,
  (
    allCarPackageIds,
    allCarOptionalExtras,
    customerCars
  ): Omit<ApiAutomatedSlotLookup, "date" | "postcode"> => {
    const result: Omit<ApiAutomatedSlotLookup, "date" | "postcode"> = {
      carBookings: [],
    };

    Object.keys(allCarPackageIds).forEach((carIdString) => {
      const carId = parseInt(carIdString);
      const carDetails = customerCars.find((car) => car.id === carId);
      const packageGroupId = allCarPackageIds[carId];
      const optionalExtraIds = allCarOptionalExtras[carId];

      if (carDetails) {
        result.carBookings.push({
          carCategory: carDetails.category,
          packageGroupId,
          optionalExtraIds,
        });
      } else {
        console.error(`No customer car found for id ${carId}.`);
      }
    });

    return result;
  }
);

const selectTotalBookingPriceSummary = (country: CountryCode) =>
  createSelector(
    makeBooking.selectCarPackageIds,
    makeBooking.selectCarOptionalExtraIds,
    packageInfo.selectAllPackages,
    packageInfo.selectAllOptionalExtras,
    customerCars.all,
    (
      allCarPackageIds,
      allCarOptionalExtraIds,
      allPackages,
      allOptionalExtras,
      customerCars
    ) => {
      const currencyDetails = countryInformationMap[country];
      let packagesTotal = 0;
      let optionalExtrasTotal = 0;

      Object.keys(allCarPackageIds).forEach((carIdString) => {
        const carId = parseInt(carIdString);
        const carDetails = customerCars.find((car) => car.id === carId);
        const carPackageId = allCarPackageIds[carId];
        const carPackage = allPackages.find(
          (p) => p.packageGroupId === carPackageId
        );
        const price =
          carPackage && carDetails
            ? carPackage.possiblePrices.find(
                (possiblePrice) =>
                  possiblePrice.carCategory === carDetails.category
              )?.price
            : 0;
        packagesTotal += price
          ? exchangeRateAdjustedPrice(price, currencyDetails.exchangeRate)
          : 0;
        const carOptionalExtraIds = allCarOptionalExtraIds[carId] || [];

        carOptionalExtraIds
          .map((optionalExtraId) => {
            return allOptionalExtras.find(
              (item) => item.packageItemId === optionalExtraId
            ) as PackageItem;
          })
          .forEach((optionalExtra) => {
            // handle case where optional extras are not loaded yet.
            optionalExtrasTotal += optionalExtra
              ? exchangeRateAdjustedPrice(
                  optionalExtra.optionalExtraPrice,
                  currencyDetails.exchangeRate
                )
              : 0;
          });
      });
      return {
        packagesTotal,
        optionalExtrasTotal,
        grandTotal: packagesTotal + optionalExtrasTotal,
      };
    }
  );

export type ConfirmationSummaryCar = {
  id: number;
  registrationNumber: string;
  makeAndModel: string;
  category: string;
  packageId: number;
  optionalExtraIds: number[];
};

export const selectConfirmationSummaryCars = createSelector(
  customerCars.all,
  makeBooking.selectedCarIds,
  makeBooking.selectCarPackageIds,
  makeBooking.selectCarOptionalExtraIds,
  (allCars, selectedCarIds, carPackageIds, carOptionalExtraIds) => {
    const selectedCars = selectedCarIds.map((carId) =>
      allCars.find((car) => car.id === carId)
    );
    const result: ConfirmationSummaryCar[] = selectedCars.map((car) => ({
      id: car?.id || 0,
      registrationNumber: car?.registrationNumber || "",
      makeAndModel: car?.makeAndModel || "",
      category: car?.category || "",
      packageId: carPackageIds[car?.id || 0],
      optionalExtraIds: carOptionalExtraIds[car?.id || 0] || [],
    }));
    return result;
  }
);

const makeSelectPackageForCar = (carId: number) =>
  createSelector(
    (state) => state,
    makeBooking.makeSelectPackageIdForCarId(carId),
    (state, packageId) => packageInfo.makeSelectPackageById(packageId)(state)
  );

const makeSelectOptionalExtrasForCar = (carId: number) =>
  createSelector(
    makeBooking.makeSelectPackgeOptionalExtraIdsForCar(carId),
    packageInfo.selectAllOptionalExtras,
    (packeOptionIds, allOptionalExtras) => {
      const items: PackageItem[] = [];
      packeOptionIds.forEach((packageOptionId) => {
        const item = allOptionalExtras.find(
          (item) => item.packageItemId === packageOptionId
        );
        if (item) items.push(item);
      });
      return items;
    }
  );

const makeSelectAvailableOptionalExtrasForCarId = (carId: number) =>
  createSelector(
    (state) => state,
    makeBooking.makeSelectPackageIdForCarId(carId),
    (state, packageId) => {
      const packageOptionalExtras =
        packageInfo.makeSelectOptionalExtrasForPackage(packageId)(state);
      const result: {
        optionalExtraTypes: string[];
        optionalExtrasByCategory: { [key: string]: PackageItem[] };
      } = {
        optionalExtraTypes: [],
        optionalExtrasByCategory: {},
      };

      packageOptionalExtras.forEach((optionalExtra) => {
        if (!optionalExtra) return;
        if (!result.optionalExtraTypes.includes(optionalExtra.categoryName)) {
          result.optionalExtraTypes.push(optionalExtra.categoryName);
          result.optionalExtrasByCategory[optionalExtra.categoryName] = [];
        }

        result.optionalExtrasByCategory[optionalExtra.categoryName].push(
          optionalExtra
        );
      });

      return result;
    }
  );

const selectAllNewCustomerCarBookings = createSelector(
  customerCars.all,
  makeBooking.selectedCarIds,
  makeBooking.selectCarPackageIds,
  makeBooking.selectCarOptionalExtraIds,
  (allCars, selectedCarIds, carPackageIds, carOptionalExtraIds) => {
    const selectedCars = allCars.filter((car) =>
      selectedCarIds.includes(car.id)
    );
    return selectedCars.map((car) => ({
      carMakeModel: car.makeAndModel,
      carNumberplate: car.registrationNumber,
      category: car.category,
      colour: car.colour,
      year: car.year,
      packageId: carPackageIds[car.id],
      optionalExtraIds: carOptionalExtraIds[car.id],
    }));
  }
);

export const aggregateSelectors = {
  selectTotalBookingPriceSummary,
  makeSelectPackageForCar,
  makeSelectOptionalExtrasForCar,
  makeSelectAvailableOptionalExtrasForCarId,
  selectAllNewCustomerCarBookings,
};
