import React from 'react';
import { AppButton, AppButtons, AppCard, AppCardContent, AppCardsLayout, AppCardTitle } from '../../Components';
import { ConfirmationSummaryCar } from '../../store';
import styles from './EnquiryConfirmation.module.css';
import EnquiryConfirmationCarSummary from './EnquiryConfirmationCarSummary';
import TotalPrice from './TotalPrice';

export interface EnquiryConfirmationProps {
    statusId: number,
    onReturnToDashboardClicked: () => void,
    cars: ConfirmationSummaryCar[],
    totalPrice: number,
    isPrepaid?: boolean,
}

const EnquiryConfirmation: React.FC<EnquiryConfirmationProps> = ({
    statusId,
    onReturnToDashboardClicked,
    cars,
    totalPrice,
    isPrepaid,
}) => {   
    const title = statusId === 2 ?
        'Booking Confirmed'
        :
        'Enquiry Sent';

    const confirmationMessage = statusId === 2 ?
        <>
            <p>You will receive an email confirming the details of your booking shortly.</p>
            <p>If you haven't heard from us please check your email spam folder.</p>
        </>
        :
        <>
            <p>Thanks for sending your enquiry, one of the team will be in touch shortly to confirm your booking.</p>
            <p>If you haven't heard from us please check your email spam folder.</p>
        </>;

    return (
        <>
            <AppCardsLayout>
                <AppCard maxFullScreenWidth="80%">
                    <AppCardTitle>{title}</AppCardTitle>
                    <AppCardContent className={styles.root}>
                        {confirmationMessage}
                        <div className={styles.carCards}>
                            {cars.map(car => (
                                <EnquiryConfirmationCarSummary key={car.id} car={car} />
                            ))}
                        </div>
                        <TotalPrice 
                            hideBottomBorder
                            style={{ margin: 0 }}
                            totalPrice={totalPrice}
                            isPrepaid={isPrepaid}
                        />
                    </AppCardContent>
                </AppCard>
            </AppCardsLayout>
            <AppButtons sideBySide className={styles.buttons}>
                <AppButton
                    variant="inverted"
                    onClick={onReturnToDashboardClicked}
                >
                    Return to Dashboard
                </AppButton>
            </AppButtons>
        </>
    );
}

export default EnquiryConfirmation;