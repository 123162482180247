import { useField } from 'formik';
import React from 'react';

import styles from './AppInput.module.css';
import AppInputLabel from './AppInputLabel';

interface AppFormikTextAreaProps {
    name: string,
    label?: string,
    placeholder: string,
}

const AppFormikTextArea: React.FC<AppFormikTextAreaProps> = ({
    name,
    label,
    placeholder,
}) => {
    const [inputProps, fieldMeta] = useField(name);
    return (
        <div>
            <AppInputLabel>{label}</AppInputLabel>
            <div className={styles.inputContainer}>
                <textarea
                    className={`${styles.input} ${styles.textArea}`}
                    placeholder={placeholder}
                    rows={5}
                    {...inputProps}
                />
            </div>
            {fieldMeta.touched && fieldMeta.error && (
                <div className={styles.error}>{fieldMeta.error}</div>
            )}
        </div>
    )
}

export default AppFormikTextArea;