export const exchangeRateAdjustedPrice = (
  value: number,
  multiplier: number
) => {
  return multiplier === 1 ? value : Math.ceil(value * multiplier);
};

export const formatPriceWithCurrencySymbol = (
  value: number,
  symbol: string
) => {
  return `${symbol}${value.toFixed(2)}`;
};
