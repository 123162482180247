import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  AppButton,
  AppCard,
  AppCardButtons,
  AppCardContent,
  AppCardsLayout,
  AppCardTitle,
} from "../../Components";
import { Package } from "../../models";
import PackageSelectTitle from "./PackageSelectTitle";
import styles from "./SelectPackage.module.css";
import { selectors } from "../../store";
import { useCarId } from "./makeBookingPaths";
import { useLocalisation } from "../../Components/Localisation";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";

interface PackageCardProps {
  package: Package;
  carCategory: string;
  onSelect: () => void;
}

const PackageCard: React.FC<PackageCardProps> = ({
  package: {
    name,
    durationDescription,
    possiblePrices,
    description,
    packageItemIds,
  },
  carCategory,
  onSelect,
}) => {
  const { currency } = useLocalisation();
  const [expandedSectionIndex, setExpandedSectionIndex] = useState(-1);
  const packageItemDetails = useSelector(
    selectors.packageInfo.selectAllOptionalExtras
  );
  const createHandleSectionClick = (index: number) => () => {
    setExpandedSectionIndex(expandedSectionIndex === index ? -1 : index);
  };
  const price =
    possiblePrices.find(
      (possiblePrice) => possiblePrice.carCategory === carCategory
    )?.price ?? 0;
  const adjustedPrice = exchangeRateAdjustedPrice(price, currency.exchangeRate);
  const priceToDisplay = formatPriceWithCurrencySymbol(
    adjustedPrice,
    currency.symbol
  );

  const packageDetailsAccordion = packageItemIds.map((item, index) => {
    const details = packageItemDetails.find(
      (detailItem) => detailItem.packageItemId === item
    );
    return (
      <Accordion
        className={styles.accordion}
        key={item}
        expanded={expandedSectionIndex === index}
        onChange={createHandleSectionClick(index)}
      >
        <AccordionSummary expandIcon={<span>&#9660;</span>}>
          {details?.name}
        </AccordionSummary>
        <AccordionDetails>{details?.description}</AccordionDetails>
      </Accordion>
    );
  });

  return (
    <AppCard fullScreenWidth="100%">
      <AppCardTitle
        size="small"
        subtitle={
          <>
            <p>{durationDescription}</p>
            <p>{description}</p>
          </>
        }
      >
        {name} {priceToDisplay}
      </AppCardTitle>
      <AppCardContent>{packageDetailsAccordion}</AppCardContent>
      <AppCardButtons>
        <AppButton variant="inverted" fullWidth onClick={onSelect}>
          Book Now
        </AppButton>
      </AppCardButtons>
    </AppCard>
  );
};

interface SelectPackageProps {
  packages: Package[];
  onPackageSelect: (packageId: number) => void;
}

const SelectPackage: React.FC<SelectPackageProps> = ({
  packages,
  onPackageSelect,
}) => {
  const carId = useCarId();
  const carDetails = useSelector(
    selectors.customerCars.makeSelectCarById(carId)
  );

  const numberOfColumns = packages.length > 1 ? '2' : '1';
  document.documentElement.style.setProperty(
    '--columnsOnTablet',
    numberOfColumns
    );

  return (
    <>
      <PackageSelectTitle message="Choose a package for your car." />
      <AppCardsLayout maxColumnLayout>
        {packages.length === 0 && <p>No packages available.</p>}
        {packages.map((p) => (
          <PackageCard
            key={p.packageGroupId}
            package={p}
            carCategory={carDetails ? carDetails.category : ""}
            onSelect={() => onPackageSelect(p.packageGroupId)}
          />
        ))}
      </AppCardsLayout>
    </>
  );
};

export default SelectPackage;
