import { faCar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Car } from "../../models";
import { aggregateSelectors } from "../../store";
import CarEnquirySummaryMainItem from "./CarEnquirySummaryMainItem";
import styles from './RegisterAndBookPriceSummary.module.css';


interface RegisterAndBookPriceSummaryProps {
    car: Car
}

const RegisterAndBookPriceSummary: React.FC<RegisterAndBookPriceSummaryProps> = ({
    car,
}) => {

    const carPackage = useSelector(aggregateSelectors.makeSelectPackageForCar(car.id));
    const optionalExtras = useSelector(aggregateSelectors.makeSelectOptionalExtrasForCar(car.id));

    let totalOptionalExtrasCost = 0;
    const price = carPackage ? carPackage.possiblePrices.find(possiblePrice => possiblePrice.carCategory === car.category)?.price : 0;
    optionalExtras.forEach(item => totalOptionalExtrasCost += item ? item.optionalExtraPrice : 0);
    let totalCost = price ? price : 0;
    totalCost += totalOptionalExtrasCost;

    return (
        <div className={styles.packageDetails}>
            <div className={styles.carDetails}>
                <div className={styles.carHeader}>
                    <div className={styles.carHeaderDetails}>
                        <h2 className={styles.makeAndModel}>{car.makeAndModel}</h2>
                        <h3 className={styles.regPlate}>{car.registrationNumber}</h3>
                    </div>
                    <FontAwesomeIcon className={styles.carIcon} icon={faCar} size={"2x"}/>
                </div>
            </div>
            <div>
                <h3>Package:</h3>
                <CarEnquirySummaryMainItem
                    name={carPackage ? carPackage.name : 'No package selected.'}
                    price={price}
                />
            </div>
            <div className={styles.carDetails}>
                <h3>Optional Extras:</h3>
                {optionalExtras.length === 0 ? (
                    <p>No optional extras selected.</p>
                ) : (
                    optionalExtras.map(optionalExtra => (
                        <CarEnquirySummaryMainItem
                            key={optionalExtra?.packageItemId}
                            name={optionalExtra ? optionalExtra.name : ''}
                            price={optionalExtra?.optionalExtraPrice}
                        />
                    ))
                )}
            </div>
            <br />
        </div>
    );
}

export default RegisterAndBookPriceSummary;
