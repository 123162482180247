import React from "react";
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { AppButton, AppButtons, AppForm, AppFormikInput } from "../../Components";
import AppInputLabel from "../../Components/AppInputs/AppInputLabel";
import AppStripeInputContainer from "../../Components/AppInputs/AppStripeInputContainer";
import { Form, FormikProps, withFormik } from 'formik';
import AppFormError from "../../Components/AppFormError";
import { HttpClientResponse } from "../../api";
import { ApiMakeBookingResponse, ApiRegisterResponse } from "../../api/Models";
import styles from './MakePayment.module.css';
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { pathBuilders } from "../../Routes";

interface MakePaymentFormValues {
    billingPostcode: string
}

interface OtherMakePaymentProps {
    disabled: boolean
}

const InnerMakePaymentForm: React.FC<OtherMakePaymentProps & FormikProps<MakePaymentFormValues>> = ({
    isSubmitting,
    status,
    disabled
}) => {
    return (
        <Form className={styles.paymentForm}>
            <AppForm>
                <AppFormikInput 
                    label="Billing postcode"
                    name="billingPostcode"
                    placeholder="Please enter your billing postcode"
                    type="text" />
                <AppInputLabel>Card number</AppInputLabel>
                <AppStripeInputContainer>
                    <CardNumberElement />
                </AppStripeInputContainer>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <AppInputLabel>Expiry date</AppInputLabel>
                        <AppStripeInputContainer>
                            <CardExpiryElement />
                        </AppStripeInputContainer>
                    </Grid>
                    <Grid item xs={6}>
                        <AppInputLabel>CVC number</AppInputLabel>
                        <AppStripeInputContainer>
                            <CardCvcElement />
                        </AppStripeInputContainer> 
                    </Grid>
                </Grid>
                <AppFormError show={status}>There was a problem with your payment method. Please check your details and try again.</AppFormError>
                <AppButtons>
                    <Typography variant="body2">Please note that by placing this booking you agree to our <Link to={pathBuilders.termsAndConditions()} target="_blank" > terms and conditions</Link> including cancellation fees</Typography>
                    <AppButton type="submit" variant="inverted" disabled={isSubmitting || disabled}>{isSubmitting ? 'Submitting...' : 'Book now'}</AppButton>
                </AppButtons>
            </AppForm>
        </Form>
    );
};

interface MakePaymentFormProps {
    initialValues: MakePaymentFormValues,
    onSubmit: (data: MakePaymentFormValues) => Promise<HttpClientResponse<ApiMakeBookingResponse | ApiRegisterResponse>>,
    onSuccess: () => void,
    disabled: boolean
}

const ValidationSchema = Yup.object().shape<MakePaymentFormValues>({
    billingPostcode: Yup.string()
            .required('Please enter your postcode')
            .min(5, 'Missing part of your postcode')
            .max(8, 'Too many characters')
            .test(
                'validatePostcodeFormat',
                'Please check you have entered a valid postcode',
                p => {
                    if (!p) return true;
                    // Postcode regex taken from fresh norse site. It's ok for this as we are cloning that site but don't copy it without testing elsewhere.
                    const matches = p.match(/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i);
                    return !!matches && matches.length !== 0;
                }
            )
})

const MakePaymentForm = withFormik<MakePaymentFormProps, MakePaymentFormValues>({
    mapPropsToValues: props => props.initialValues,
    validationSchema: ValidationSchema,
    handleSubmit: async (
        values,
        {
            setStatus,
            props: {
                onSubmit,
                onSuccess
            }
        }
    ) => {
        const response = await onSubmit(values);

        if(response.isError) {
            setStatus(true);
        } else {
            onSuccess();
        }
    }
})(InnerMakePaymentForm);

export default MakePaymentForm;