import { Formik } from "formik";
import React from "react";
import CancelBookingForm from "./CancelBookingForm";
import * as Yup from 'yup';


export interface CancelBookingFormContainerProps {
  onSubmit: (cancellationReasonId: string, cancellationReasonNotes: string) => void,
  onRebook: () => void,
}

export interface CancelBookingFormContainerValues {
  cancellationReasonId: string;
  cancellationReasonNotes: string;
};

const CancelBookingFormValidationSchema = Yup.object().shape<CancelBookingFormContainerValues>({
  cancellationReasonId: Yup.string().required('Required'),
  cancellationReasonNotes: Yup.string()
})

const CancelBookingFormContainer: React.FC<CancelBookingFormContainerProps> = ({
  onSubmit,
  onRebook,
}) => {
  
  const initialValues: CancelBookingFormContainerValues = {
    cancellationReasonId: "",
    cancellationReasonNotes: "",
  };
  
  const handleSubmit = (values: CancelBookingFormContainerValues) => {
    onSubmit(values.cancellationReasonId, values.cancellationReasonNotes);
  };
  
  
    return (
    <>
      <Formik 
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={CancelBookingFormValidationSchema}>
        <CancelBookingForm onSubmit={onSubmit} onRebook={onRebook}/>
      </Formik>
    </>
  );
};


export default CancelBookingFormContainer;
