import { ApiAddress } from "../api/Models";

export interface AddressCore {
    addressLine1: string,
    addressLine2: string,
    addressLine3?: string,
    postcode: string,
    town: string,
    country: number
}

export interface Address extends AddressCore {
    id: number,
}

export const parseApiAddress = ({
    addressId,
    addressLine1,
    addressLine2,
    addressLine3,
    postcode,
    town,
    country
}: ApiAddress): Address => ({
    id: addressId,
    addressLine1,
    addressLine2,
    addressLine3,
    postcode,
    town,
    country
});