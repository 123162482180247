import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeBookingPathBuilders } from ".";
import {
  HttpClientFailureResponse,
  HttpClientResponse,
  makeBookingAndRegister,
  makeBookingExistingCustomer,
} from "../../api";
import { ApiMakeBookingResponse, ApiRegisterResponse } from "../../api/Models";
import { useAuth } from "../../auth";
import {
  AppCard,
  AppCardContent,
  AppCardsLayout,
  AppCardTitle,
} from "../../Components";
import { trackBooking } from "../../marketingHelpers";
import {
  actions,
  aggregateSelectors,
  ConfirmationSummaryCar,
  selectors,
} from "../../store";
import EnquiryConfirmationCarSummary from "./EnquiryConfirmationCarSummary";
import MakePaymentForm from "./MakePaymentForm";
import TotalPrice from "./TotalPrice";
import { useHistory } from "react-router-dom";
import { MakeBookingAndRegisterDto } from "../../models";
import styles from "./MakePayment.module.css";
import { useLocalisation } from "../../Components/Localisation";

export interface MakePaymentProps {
  cars: ConfirmationSummaryCar[];
  totalPrice: number;
  isPrepaid?: boolean;
}

interface PaymentFormValues {
  billingPostcode: string;
}

const errorResponse: HttpClientFailureResponse = {
  isError: true,
  message: "",
  statusCode: 0,
};

const MakePayment: React.FC<MakePaymentProps> = ({
  cars,
  totalPrice,
  isPrepaid,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();
  const { currency } = useLocalisation();
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingDetails = useSelector(
    selectors.makeBooking.selectBookingDetails
  );
  const customerDetails = useSelector(
    selectors.makeBooking.selectNewCustomerDetails
  );
  const customerCarBookings = useSelector(
    aggregateSelectors.selectAllNewCustomerCarBookings
  );
  const makeBookingDto = useSelector(
    selectors.makeBooking.selectMakeBookingExistingCustomerDto
  );

  const setPaymentDetails = async (paymentMethod: string) => {
    dispatch(actions.makeBooking.setPaymentMetehod(paymentMethod));
  };

  const handleSubmitBooking = async (
    formValues: PaymentFormValues
  ): Promise<
    HttpClientResponse<ApiMakeBookingResponse | ApiRegisterResponse>
  > => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return errorResponse;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return errorResponse;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          postal_code: formValues.billingPostcode,
        },
      },
    });

    if (error || !paymentMethod) {
      return errorResponse;
    } else {
      await setPaymentDetails(paymentMethod.id);
      const response = auth.isLoggedIn
        ? await makeBookingExisting(paymentMethod.id)
        : await makeBookingRegister(paymentMethod.id);

      if (!response) return errorResponse;
      return response;
    }
  };

  const handleSuccess = () => {
    const path = makeBookingPathBuilders.enquirySent();

    history.push(path);
  };

  const makeBookingExisting = async (
    paymentMethodId?: string,
    paymentIntentId?: string
  ): Promise<HttpClientResponse<ApiMakeBookingResponse> | undefined> => {
    const bookingDto = {
      booking: {
        ...makeBookingDto.booking,
        bookingCustomerCars: makeBookingDto.booking.bookingCustomerCars.map(
          (customerCar) => {
            const carDetails = cars.find(
              (car) => car.id === customerCar.customerCarId
            );
            customerCar.category = carDetails?.category;
            return customerCar;
          }
        ),
      },
      paymentDetails: {
        ...makeBookingDto.paymentDetails,
        paymentMethodId,
        paymentIntentId,
      },
    };

    const response = await makeBookingExistingCustomer(bookingDto);
    if (response.isError) return response;

    if (response.content.paymentDetails?.requiresAction) {
      const { paymentIntentClientSecret } = response.content.paymentDetails;

      return await handleAdditionalPaymentActionExisting(
        paymentIntentClientSecret
      );
    } else {
      dispatch(
        actions.makeBooking.setCreatedBookingStatusId(
          response.content.bookingStatusId
        )
      );
      trackBooking("Booking");
      return response;
    }
  };

  const makeBookingRegister = async (
    paymentMethodId?: string,
    paymentIntentId?: string
  ): Promise<HttpClientResponse<ApiRegisterResponse> | undefined> => {
    if (customerDetails) {
      const bookingRequest: MakeBookingAndRegisterDto = {
        carBookings: customerCarBookings,
        customerDetails: {
          ...customerDetails,
        },
        requestedDate: bookingDetails.date,
        timeSlot: bookingDetails.timeSlot || undefined,
        automatedSlot: bookingDetails.automatedSlot || undefined,
        additionalComments: bookingDetails.additionalComments,
        currency: currency.currency,
        paymentDetails: {
          isPayNow: true,
          paymentMethodId: paymentMethodId,
          paymentIntentId: paymentIntentId,
        },
      };

      const response = await makeBookingAndRegister(bookingRequest);

      if (response.isError) return errorResponse;

      if (response.content.paymentDetails?.requiresAction) {
        const { paymentIntentClientSecret } = response.content.paymentDetails;

        return await handleAdditionalPaymentActionNew(
          paymentIntentClientSecret
        );
      }

      if (!response.isError) {
        auth.setLogInInformation(
          response.content.userDetails,
          response.content.userDetails.token
        );
        dispatch(
          actions.makeBooking.setCreatedBookingStatusId(
            response.content.booking.bookingStatusId
          )
        );
        trackBooking("Booking");
      }

      return response;
    }
  };

  const handleAdditionalPaymentActionExisting = async (
    paymentIntentClientSecret: string
  ) => {
    if (stripe) {
      const cardAction = await stripe.handleCardAction(
        paymentIntentClientSecret
      );

      if (cardAction.error) return errorResponse;

      return makeBookingExisting(undefined, cardAction.paymentIntent.id);
    }
  };

  const handleAdditionalPaymentActionNew = async (
    paymentIntentClientSecret: string
  ) => {
    if (stripe) {
      const cardAction = await stripe.handleCardAction(
        paymentIntentClientSecret
      );

      if (cardAction.error) return errorResponse;

      return makeBookingRegister(undefined, cardAction.paymentIntent.id);
    }
  };

  return (
    <>
      <AppCardsLayout>
        <AppCard maxFullScreenWidth="80%">
          <AppCardTitle>Make Payment</AppCardTitle>
          <AppCardContent className={styles.root}>
            <div className={styles.carCards}>
              {cars.map((car) => (
                <EnquiryConfirmationCarSummary key={car.id} car={car} />
              ))}
            </div>
            <TotalPrice
              style={{ margin: 0 }}
              totalPrice={totalPrice}
              isPrepaid={isPrepaid}
            />
            <MakePaymentForm
              onSubmit={handleSubmitBooking}
              onSuccess={handleSuccess}
              initialValues={{
                billingPostcode: "",
              }}
              disabled={!stripe && !elements}
            />
          </AppCardContent>
        </AppCard>
      </AppCardsLayout>
    </>
  );
};

export default MakePayment;
