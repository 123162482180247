import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth } from '../../auth';
import { pathBuilders } from '../../Routes';
import { actions, useDispatchEffect } from '../../store';
import EnquiryConfirmation from './EnquiryConfirmation';
import { useBookingId } from './makeBookingPaths';
import { getBookingById } from '../../api/bookings';
import { Booking } from '../../models';

const PaymentConfirmationRedirectContainer: React.FC = () => {
    const auth = useAuth();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const bookingId = useBookingId();
    const [booking, setBooking] = useState<Booking | undefined>();
    useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(3));

    useEffect(() => {
        const makeRequest = async () => {
            const response = await getBookingById(bookingId);
            if (!response.isError) {
                setBooking(response.content);
            }
        };
        makeRequest();        
    }, [bookingId]);

    useEffect(() => {
        history.push(auth.isLoggedIn ? pathBuilders.accountDashboard() : pathBuilders.logIn());
        history.push(routeMatch.url);
    }, [auth.isLoggedIn, history, routeMatch.url]);

    const handleReturnToDashboardClicked = () => history.push(pathBuilders.accountDashboard());

    if (!booking) {
        return null;
    }

    return (
        <EnquiryConfirmation
            statusId={booking.bookingStatusId}
            onReturnToDashboardClicked={handleReturnToDashboardClicked}
            cars={booking.bookingCustomerCars}
            totalPrice={booking.totalCost}
            isPrepaid={booking.isPrepaid}
        />
    );
}

export default PaymentConfirmationRedirectContainer;