import { CountryCode } from ".";

const storageKeys = {
  localisation: "localisation",
};

const getLocalisationInformation = (): CountryCode | undefined => {
  const jsonData = localStorage.getItem(storageKeys.localisation);

  if (!jsonData) return undefined;

  return jsonData as CountryCode;
};

const setLocalisationInformation = (country: CountryCode) => {
  localStorage.setItem(storageKeys.localisation, country);
};

const localStorageClient = {
  getLocalisationInformation,
  setLocalisationInformation,
};

export default localStorageClient;
