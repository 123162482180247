import React from "react";
import { useLocalisation } from "../../Components/Localisation";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";
import styles from "./CarEnquirySummary.module.css";

interface CarEnquirySummaryMainItemProps {
  name: string;
  price?: number;
}

const CarEnquirySummaryMainItem: React.FC<CarEnquirySummaryMainItemProps> = ({
  name,
  price,
}) => {
  const { currency } = useLocalisation();
  const adjustedPrice = price
    ? exchangeRateAdjustedPrice(price, currency.exchangeRate)
    : "";
  const priceToDisplay =
    typeof adjustedPrice === "number"
      ? formatPriceWithCurrencySymbol(adjustedPrice, currency.symbol)
      : adjustedPrice;

  return (
    <p className={styles.billingItem}>
      {name}
      <span className={styles.packagePrice}>{priceToDisplay}</span>
    </p>
  );
};

export default CarEnquirySummaryMainItem;
