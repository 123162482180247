import React from "react";
import { useField } from "formik";
import styles from "./BookingTimeSlotPicker.module.css";
import AppInputLabel from "../../Components/AppInputs/AppInputLabel";
import AppInputError from "../../Components/AppInputs/AppInputError";
import BookingTimeSlot from "./BookingTimeSlot";
import EcoIcon from "../../assets/eco-icon.svg";

export interface BookingTimeSlotPickerProps {
  name: string;
  placeholder: string;
  availableSlots: BookingSlot[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export interface BookingSlot {
  value: string;
  label: string;
  isEcoSlot: boolean;
}

function BookingTimeSlotPicker({
  name,
  placeholder,
  availableSlots,
  label,
  required,
  disabled,
}: BookingTimeSlotPickerProps) {
  const [_, fieldMeta] = useField(name);

  const ecoSlots = availableSlots.filter((s) => s.isEcoSlot);
  const nonEcoSlots = availableSlots.filter((s) => !s.isEcoSlot);

  return (
    <div>
      <AppInputLabel required={required}>{label}</AppInputLabel>

      {availableSlots.length === 0 && (
        <p className={styles.emptyState}>{placeholder}</p>
      )}

      {ecoSlots.length > 0 && (
        <div className={styles.ecoSlotContainer}>
          <h3>Eco appointments</h3>
          <p>
            By choosing an eco appointment you will be minimising the distance
            we travel and helping to reduce carbon emissions.
          </p>
          <div className={styles.ecoSlotContainerIcon} style={{backgroundImage: `url(${EcoIcon})`}}></div>
          <div className={styles.inputContainer}>
            {ecoSlots.map((slot, index) => (
              <BookingTimeSlot
                index={index}
                name={name}
                slot={slot}
                disabled={disabled}
              />
            ))}
          </div>
          <br />
        </div>
      )}
      
      {nonEcoSlots.length > 0 && (
        <div className={styles.inputContainer}>       
          {nonEcoSlots.map((slot, index) => (
            <BookingTimeSlot
              index={index}
              name={name}
              slot={slot}
              disabled={disabled}
            />
          ))}        
        </div>
      )}

      {fieldMeta.touched && fieldMeta.error && (
        <AppInputError>{fieldMeta.error}</AppInputError>
      )}
    </div>
  );
}

export default BookingTimeSlotPicker;
