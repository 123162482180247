import React from 'react';
import classnames from 'classnames';

import styles from './AppCard.module.css';
import { useIsMobileMedia } from '../../mediaQueries';

export interface AppCarProps {
    maxFullScreenWidth?: string,
    fullScreenWidth?: string,
}
 
const AppCard: React.FC<AppCarProps> = ({
    children,
    maxFullScreenWidth = '600px',
    fullScreenWidth = '50%',
}) => {
    const isMobile = useIsMobileMedia();
    return (
        <div
            className={classnames({
                [styles.root]: true,
            })}
            style={{
                maxWidth: isMobile ? '100%' : maxFullScreenWidth,
                width: isMobile ? '100%' : fullScreenWidth,
            }}
        >
            {children}
        </div>
    );
}
 
export default AppCard;