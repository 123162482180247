import React from 'react';
import classnames from 'classnames';
import styles from './Hamburger.module.css';

export interface HamburgerProps {
    isOpen: boolean,
    onClick: () => void,
}

const Hamburger: React.FC<HamburgerProps> = ({
    onClick,
    isOpen,
}) => {
    return (
        <button
            className={styles.navToggle}
            onClick={onClick}
        >
            <span
                className={classnames({
                    [styles.hamburger]: true,
                    [styles.open]: isOpen,
                })}
            />
        </button>
    );
}

export default Hamburger;