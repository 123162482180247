import React from 'react';
import classnames from 'classnames';

import styles from './AppInput.module.css';
import AppInputLabel from './AppInputLabel';
import { ReactComponent as MagnifyingGlass } from './magnifyingGlass.svg';
import AppInputError from './AppInputError';

interface AppInputProps {
    value?: string,
    disabled?: boolean,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onEnter?: () => void,
    label?: string,
    placeholder?: string,
    showSearch?: boolean,
    type?: string,
    errorMessage?: string,
}

const AppInput: React.FC<AppInputProps> = ({
    value,
    disabled,
    onChange,
    onEnter,
    label,
    placeholder,
    showSearch,
    type = 'string',
    errorMessage,
}) => {
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!onEnter) return;

        if (event.key === 'Enter') {
            onEnter();
        }
    };

    return (
        <div>
            <AppInputLabel>{label}</AppInputLabel>
            <div className={styles.inputContainer}>
                <input
                    disabled={disabled}
                    className={classnames({
                        [styles.input]: true,
                        [styles.search]: showSearch,
                    })}
                    onChange={onChange}
                    onKeyUp={handleKeyUp}
                    value={value}
                    placeholder={placeholder}
                    type={type}
                />
                {showSearch && (
                    <button
                        className={styles.searchButton}
                        disabled={disabled}
                        onClick={onEnter && onEnter}
                    >
                        <MagnifyingGlass />
                    </button>
                )}
            </div>
            <AppInputError>{errorMessage}</AppInputError>
        </div>
    )
}

export default AppInput;