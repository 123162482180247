import React, { useState } from "react";
import classnames from "classnames";

import { ExistingBooking } from "../../models";
import styles from "./BookingsList.module.css";
import {
  formatDateStringDateOnly,
  formatTimeOnly,
  formatUnifiedDateTime,
} from "../../dateFormatter";
import { useDispatch, useSelector } from "react-redux";
import { actions, useDispatchEffect, selectors } from "../../store";
import BookingCarPackageDetail from "./BookingCarPackageDetail";
import { successToast } from "../../toast";
import { AppButton } from "../../Components";
import CancelBookingModal from "../../Components/CancelBookingForm/CancelBookingModal";
import { cancelBooking } from "../../api";

export interface BookingListUpcomingItemProps {
  booking: ExistingBooking;
  onRebook: () => void;
}

const BookingListUpcomingItem: React.FC<BookingListUpcomingItemProps> = ({
  booking: {
    addressId,
    id,
    bookingCustomerCars,
    enquiryStatus,
    requestedDate,
    confirmedDate,
    timeOfDay,
    totalCost,
    isPrepaid,
    currency,
    additionalComments,
  },
  onRebook
}) => {
  const dispatch = useDispatch();
  const address = useSelector(selectors.customerAddresses.all).find(
    (address) => address.id === addressId
  );
  const formattedDate = formatUnifiedDateTime(
    requestedDate,
    confirmedDate,
    timeOfDay
  );
  const [changeCancelModalIsOpen, setChangeCancelModalIsOpen] = useState(false);

  let isEuros: boolean = false;
  let totalPriceEuros: number;

  if (additionalComments) {
    totalPriceEuros = parseFloat(
      additionalComments.split("displayed in euros: €")[1]
    );
    isEuros = !isNaN(totalPriceEuros);
  }

  const handleCloseChangeCancelModal = () => {
    setChangeCancelModalIsOpen(false);
  };

  const handleCancelButtonClick = async (
    cancellationReasonId: string,
    cancellationReasonNotes: string
  ) => {
    var response = await cancelBooking(
      id,
      confirmedDate,
      parseInt(cancellationReasonId, 10),
      cancellationReasonNotes
    );

    if (!response.isError) {
      successToast("Booking has been cancelled.");
      handleCloseChangeCancelModal();
      dispatch(actions.existingBookings.fetch());
    }
  };

  const carList = bookingCustomerCars.map((car, i) => (
    <BookingCarPackageDetail
      car={car}
      key={i}
      currency={currency}
      // This is a hack to get this showing right for now, this should be handled more elegantly.
      totalCost={i === 0 ? (!isNaN(totalPriceEuros) ? totalPriceEuros : totalCost) : 0}
      isPrepaid={isPrepaid}
      isEuros={isEuros}
    />
  ));

  return (
    <li key={id} className={classnames(styles.booking, styles.upcomingBooking)}>
      <div className={styles.bookingCarPackagesContainer}>{carList}</div>
      <p className={classnames(styles.bookingAddress)}>
        {address?.addressLine1},{" "}
        {address ? address.postcode : "(Address Removed)"}
      </p>
      <p>
        <span className={styles.enquiryStatus}>{enquiryStatus}: </span>
        {formattedDate}
      </p>
      <CancelBookingModal
        isOpen={changeCancelModalIsOpen}
        onClose={handleCloseChangeCancelModal}
        onSubmit={handleCancelButtonClick}
        onRebook={onRebook}
      />
      {enquiryStatus !== "Cancelled" && (
        <div className={styles.cancelButton}>
          <AppButton
            onClick={() => setChangeCancelModalIsOpen(true)}
            variant="standard"
            size="small"
          >
            Cancel Booking
          </AppButton>
        </div>
      )}
    </li>
  );
};

export default BookingListUpcomingItem;
