import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { usePackageCategoryId, useServiceTypeId } from ".";

import {
  AppButton,
  AppCard,
  AppCardButtons,
  AppCardContent,
  AppCardsLayout,
} from "../../Components";
import ApiErrorLoaderWrapper from "../../Components/ErrorLoaderWrapper";
import { useLocalisation } from "../../Components/Localisation";
import { PackageCategory } from "../../models";
import {
  exchangeRateAdjustedPrice,
  formatPriceWithCurrencySymbol,
} from "../../priceHelpers";
import { actions, selectors, useDispatchEffect } from "../../store";
import { makeBookingPathBuilders, useCarId } from "./makeBookingPaths";
import PackageSelectTitle from "./PackageSelectTitle";

import styles from "./SelectPackageCategory.module.css";

interface PackageCategoryCardProps {
  package: PackageCategory;
}

const PackageCategoryCard: React.FC<PackageCategoryCardProps> = ({
  package: {
    packageGroupCategoryId: categoryId,
    imageUrl,
    title,
    startingPrices,
    description,
  },
}) => {
  const history = useHistory();
  const { currency } = useLocalisation();
  const carId = useCarId();
  const serviceTypeId = useServiceTypeId();
  const carDetails = useSelector(
    selectors.customerCars.makeSelectCarById(carId)
  );
  const price =
    startingPrices.find(
      (startingPrice) => startingPrice.carCategory === carDetails?.category
    )?.price ?? 0;
  const adjustedPrice = exchangeRateAdjustedPrice(price, currency.exchangeRate);
  const priceToDisplay = formatPriceWithCurrencySymbol(
    adjustedPrice,
    currency.symbol
  );

  return (
    <AppCard maxFullScreenWidth="100%" fullScreenWidth="100%">
      <img className={styles.image} src={imageUrl} alt={title} />
      <AppCardContent>
        <div className={styles.cardContent}>
          <h2>{title}</h2>
          <p>From {priceToDisplay}</p>
          <p>{description}</p>
        </div>
      </AppCardContent>
      <AppCardButtons>
        <AppButton
          variant="inverted"
          fullWidth
          onClick={() =>
            history.push(makeBookingPathBuilders.carPackages(carId, serviceTypeId, categoryId))
          }
        >
          View Packages
        </AppButton>
      </AppCardButtons>
    </AppCard>
  );
};

const SelectPackageCategory = () => {
  const serviceTypeId = useServiceTypeId();
  const packageInfoApi = useSelector(selectors.packageInfo.apiState);
  const packageCategories = useSelector(
    selectors.packageInfo.makeSelectPackageCategoriesByServiceTypeId(serviceTypeId)
  );
  useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(1));

  document.documentElement.style.setProperty(
    '--columns',
    packageCategories.length.toString());
    
  return (
    <ApiErrorLoaderWrapper
      isLoading={packageInfoApi.isLoading}
      httpError={packageInfoApi.apiError}
    >
      <>
        <PackageSelectTitle message="Choose a package for your car." />
        <div className={styles.cardContainer}>
          {packageCategories.map((p) => (
            <PackageCategoryCard key={p.packageGroupCategoryId} package={p} />
          ))}
        </div>
      </>
    </ApiErrorLoaderWrapper>
  );
};

export default SelectPackageCategory;
