import React from 'react';
import styles from './AppCard.module.css';
import { AppButtons } from '../AppButton';

export interface AppCardButtonsProps {
}

const AppCardButtons: React.FC<AppCardButtonsProps> = ({
    children,
}) => {
    return (
        <div className={styles.buttons}>
            <AppButtons>
                {children}
            </AppButtons>
        </div>
    );
}

export default AppCardButtons;