import React from 'react';
import { useSelector } from 'react-redux';
import { AppButton } from '../../Components';
import { AppButtons } from '../../Components/AppButton';
import { useIsMobileMedia } from '../../mediaQueries';
import { selectors } from '../../store';

export type BookingStep = 'car'|'address'

export interface NewBookingModalButtonsProps {
    currentStep: BookingStep
    onBackClick: () => void,
    onContinueClick: () => void,
}

const NewBookingModalButtons: React.FC<NewBookingModalButtonsProps> = ({
    currentStep: bookingStep,
    onBackClick,
    onContinueClick
}) => {
    const selctedCarIds = useSelector(selectors.makeBooking.selectedCarIds);
    const selectedAddress = useSelector(selectors.makeBooking.selectSelectedAddressId);
    const isMobile = useIsMobileMedia();

    const continueDisabled = bookingStep === 'car' ?
        selctedCarIds.length === 0 :
        !selectedAddress;

    return (
        <AppButtons>
            {bookingStep === 'address' && (
                <AppButton
                    onClick={onBackClick}
                    size={isMobile ? 'small' : 'standard'}
                >
                    Back
                </AppButton>
            )}
            <AppButton
                variant="inverted"
                onClick={onContinueClick}
                disabled={continueDisabled}
                size={isMobile ? 'small' : 'standard'}
            >
                {bookingStep === 'address' ? 'Make Booking' : 'Continue'}
            </AppButton>
        </AppButtons>
    );
}

export default NewBookingModalButtons;