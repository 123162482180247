import moment from "moment";
import {
  MakeBookingAndRegisterDto,
  MakeBookingExistingCustomerDto,
  AutomatedSlotLookup,
  parseApiExistingBookingsDto,
  parseApiDetailedBookingDto,
  Booking,
} from "../models";
import { buildUrl } from "./common/buildUrl";
import {
  ApiStandardResponse,
  unpackStandardResponse,
} from "./common/ApiStandardResponse";
import { doErrorToastIfRequired } from "./common/doErrorToastIfRequired";
import * as httpClient from "./common/httpClient";
import {
  ApiRegisterResponse,
  RegisterApiDto,
  BookingApiDto,
  ApiExistingBookingsDto,
  ApiMakeBookingResponse,
  ApiExistingDetailedBookingDto,
  PaymentApiDto,
  CancellationReasonDto,
} from "./Models";

export const checkIfEmailIsAvailable = async (email: string) => {
  const url = buildUrl("account/EmailAvailable");
  const response = await httpClient.postRequest<ApiStandardResponse<boolean>>(
    url,
    { email }
  );

  doErrorToastIfRequired(response);

  return unpackStandardResponse(response, (x) => x);
};

export const makeBookingExistingCustomer = async (
  bookingRequest: MakeBookingExistingCustomerDto
) => {
  bookingRequest.booking.preferredTime = enquiryDateTime(
    bookingRequest.booking.requestedDate,
    bookingRequest.booking.preferredTime
  );

  const url = buildUrl("paid-bookings");
  const response = await httpClient.postRequest<
    ApiStandardResponse<ApiMakeBookingResponse>
  >(url, bookingRequest);

  doErrorToastIfRequired(response);
  return unpackStandardResponse(response, (m) => m);
};

interface RegisterAndBookingPayload {
  registerDTO: RegisterApiDto;
  bookingDTO: BookingApiDto;
  paymentDetails: PaymentApiDto;
}

export const makeBookingAndRegister = async (
  bookingAndRegisterRequest: MakeBookingAndRegisterDto
) => {
  const url = buildUrl("newcustomerbookings");

  const registerPayload: RegisterApiDto = {
    email: bookingAndRegisterRequest.customerDetails.email,
    password: bookingAndRegisterRequest.customerDetails.password,
    confirmPassword: bookingAndRegisterRequest.customerDetails.password,
    title: bookingAndRegisterRequest.customerDetails.title,
    firstName: bookingAndRegisterRequest.customerDetails.firstName,
    lastName: bookingAndRegisterRequest.customerDetails.lastName,
    contactNumber: bookingAndRegisterRequest.customerDetails.phoneNumber,
    dateOfBirth: bookingAndRegisterRequest.customerDetails.dateOfBirth,
    address: {
      addressLine1: bookingAndRegisterRequest.customerDetails.addressLine1,
      addressLine2: bookingAndRegisterRequest.customerDetails.addressLine2,
      addressLine3: bookingAndRegisterRequest.customerDetails.addressLine3,
      town: bookingAndRegisterRequest.customerDetails.townCity,
      postcode: bookingAndRegisterRequest.customerDetails.postcode,
      country: bookingAndRegisterRequest.customerDetails.country,
    },
  };

  const bookingPayload: BookingApiDto = {
    requestedDate: bookingAndRegisterRequest.requestedDate,
    additionalComments: bookingAndRegisterRequest.additionalComments,
    preferredTime: enquiryDateTime(
      bookingAndRegisterRequest.requestedDate,
      bookingAndRegisterRequest.timeSlot
    ),
    bookingStatusId: 1,
    currency: bookingAndRegisterRequest.currency,
    bookingCustomerCars: bookingAndRegisterRequest.carBookings.map((car) => ({
      registrationNumber: car.carNumberplate,
      makeAndModel: car.carMakeModel,
      packageGroupId: car.packageId,
      category: car.category,
      colour: car.colour,
      year: car.year,
      optionalExtras: car.optionalExtraIds.map((optionalExtraId) => ({
        packageItemId: optionalExtraId,
      })),
    })),
  };

  const paymentPayload: PaymentApiDto = {
    isPayNow: bookingAndRegisterRequest.paymentDetails.isPayNow,
    paymentMethodId: bookingAndRegisterRequest.paymentDetails.paymentMethodId,
    paymentIntentId: bookingAndRegisterRequest.paymentDetails.paymentIntentId,
  };

  if (bookingAndRegisterRequest.automatedSlot) {
    let slot: AutomatedSlotLookup = JSON.parse(
      bookingAndRegisterRequest.automatedSlot
    );
    bookingPayload.confirmedDate = new Date(slot.slotStartTime).toISOString();
    bookingPayload.resourceId = slot.resourceId;
    bookingPayload.bookingStatusId = 2;
  }

  const masterPayload: RegisterAndBookingPayload = {
    registerDTO: registerPayload,
    bookingDTO: bookingPayload,
    paymentDetails: paymentPayload,
  };

  const response = await httpClient.postRequest<
    ApiStandardResponse<ApiRegisterResponse>
  >(url, masterPayload);

  if (response.isError) {
    return response;
  }

  doErrorToastIfRequired(response);

  return unpackStandardResponse(response, (m) => m);
};

export const getAllExistingBookings = async () => {
  const url = buildUrl("bookings");
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiExistingBookingsDto>
  >(url);
  doErrorToastIfRequired(response);
  return unpackStandardResponse(response, parseApiExistingBookingsDto);
};

export const getBookingById = async (bookingId: number) => {
  const url = buildUrl("bookings");
  const response = await httpClient.getRequest<
    ApiStandardResponse<ApiExistingDetailedBookingDto>
  >(`${url}/${bookingId}`);
  doErrorToastIfRequired(response);
  return unpackStandardResponse(response, parseApiDetailedBookingDto);
};

const enquiryDateTime: Function = (
  date: string,
  timeSlot: string
): string | undefined => {
  if (timeSlot === "morning") {
    return moment(date).set({ hour: 9, minute: 0 }).format();
  } else if (timeSlot === "afternoon") {
    return moment(date).set({ hour: 14, minute: 0 }).format();
  } else {
    return undefined;
  }
};

export interface ApiUpdateBookingStatusDto {
  enquiryStatus: string;
  confirmedDate?: string;
  cancellationReasonId: number;
  cancellationReasonNotes: string;
}

export const cancelBooking = async (
  bookingId: number,
  confirmedDate: string | undefined,
  cancellationReasonId: number,
  cancellationReasonNotes: string
) => {
  const url = buildUrl(`bookings/enquirystatus/${bookingId}?api-version=2.0`);
  var dto: ApiUpdateBookingStatusDto = {
    enquiryStatus: "Cancelled",
    confirmedDate: confirmedDate || undefined,
    cancellationReasonId: cancellationReasonId,
    cancellationReasonNotes: cancellationReasonNotes,
  };
  const response = await httpClient.patchRequest<any>(url, dto);
  doErrorToastIfRequired(response);
  return response;
};

export const getCancellationReasons = async () => {
  const url = buildUrl("bookings/cancellationreasons");
  const response = await httpClient.getRequest<
    ApiStandardResponse<CancellationReasonDto[]>
  >(url);
  doErrorToastIfRequired(response);
  return unpackStandardResponse(response, m => m);
};