import React, { useEffect, useState } from "react";
import queryString from "query-string";
import {
  CountryCode,
  LocalisationContext,
  countryInformationMap,
} from "./LocalisationContext";
import { useLocation } from "react-router-dom";
import localStorageClient from "./localStorageClient";
import { useSelector } from "react-redux";
import { selectors } from "../../store";

type LocationProviderProps = {
  children: React.ReactNode;
};

interface LocationQueryParams {
  country?: CountryCode;
}

const alwaysEnabledPaths = ["/logIn", `/make-booking/location-check`];

const LocalisationtionProvider: React.FC<LocationProviderProps> = ({
  children,
}) => {
  const { search, pathname } = useLocation();
  const { country = CountryCode.UK }: LocationQueryParams =
    queryString.parse(search);

  const addresses = useSelector(selectors.customerAddresses.all);

  const persistedLocation = localStorageClient.getLocalisationInformation();

  const [selectedCountry, setSelectedCountry] = useState(
    persistedLocation ?? country
  );
  const [isLocked, setIsLocked] = useState(true);

  useEffect(() => {
    localStorageClient.setLocalisationInformation(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    if (alwaysEnabledPaths.includes(pathname)) {
      setIsLocked(false);
    }
    
    if(!alwaysEnabledPaths.includes(pathname)) {
      if (
        pathname === "/account-dashboard" &&
        addresses.length === 0
      ) {
        setIsLocked(false);
        return;
      }

      setIsLocked(true);
    }
  }, [pathname, addresses]);

  return (
    <LocalisationContext.Provider
      value={{
        country: selectedCountry,
        currency: countryInformationMap[selectedCountry],
        isLocked,
        handleLocalisationChange: setSelectedCountry,
      }}
    >
      {children}
    </LocalisationContext.Provider>
  );
};

export default LocalisationtionProvider;
