import { buildUrl } from './common/buildUrl';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import { ApiStandardResponse, unpackStandardResponse } from './common/ApiStandardResponse';
import * as httpClient from './common/httpClient'

export interface AddressSearchResult {
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    postcode: string,
    town: string,
}

export const searchAddressesForPostcode = async (postcode: string) => {
    const url = buildUrl('addresses/search');
    const payload = { postcode };
    const response = await httpClient.postRequest<ApiStandardResponse<AddressSearchResult[]>>(url, payload);
    doErrorToastIfRequired(response);
    return unpackStandardResponse(response, m => m);
}