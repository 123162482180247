import { CarLookup } from '../../models';

export interface ApiCar  {
    customerCarId: number,
    makeAndModel: string,
    registrationNumber: string,
    category: string,
    colour: string,
    year: string
}

export interface ApiMakeModelSearchResult {
    carMakeModel: string,
    carCategory: string
}

export const parseApiCarSearchResponse = ({
    carMakeModel,
    carCategory
}: ApiMakeModelSearchResult): CarLookup => ({
    id: '',
    makeAndModel: carMakeModel,
    category: carCategory,
    year: '',
    colour: '' 
});