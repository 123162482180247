import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Route } from 'react-router-dom';
import { actions, selectors, useDispatchEffect } from '../../store';
import { makeBookingPathBuilders } from './makeBookingPaths';

import ProgressIndicator from './ProgressInidicator';
import SelectPackageContainer from './SelectPackageContainer';
import SelectPackageCategory from './SelectPackageCategory';
import ViewCars from './ViewCars';
import EnquiryConfirmationContainer from './EnquiryConfirmationContainer';
import ChooseDateFormContainer from './ChooseDateFormContainer';
import { LocationCheck } from '../LocationCheck';
import { AddCarNewCustomerPage } from '../AddCarNewCustomer';
import { RegisterAndBook } from './RegisterAndBook';
import SelectOptionalExtras from './SelectOptionalExtras';
import PaymentConfirmationRedirectContainer from './PaymentConfirmationRedirectContainer';
import MakePaymentContainer from './MakePaymentContainer';
import {useLocalisation} from "../../Components/Localisation";
import SelectServiceType from './SelectServiceType';

export interface MakeBookingPageProps {

}

export const MakeBookingPage: React.FC<MakeBookingPageProps> = () => {
    useDispatchEffect(actions.customerCars.fetchIfRequired);
    const dispatch = useDispatch();
    const currentStep = useSelector(selectors.makeBooking.selectCurrentStepIndex);
    const selectedPostcode = useSelector(selectors.makeBooking.selectSelectedPostcode);

    const {country} = useLocalisation()

    useEffect(() => {
        const postcode = country === 'ie' ? 'ireland' : selectedPostcode;
        dispatch(actions.packageInfo.fetchIfRequired(postcode));
    }, [selectedPostcode, country])
    
    return (
        <>
            <ProgressIndicator currentStep={currentStep} />
            <Route exact path={makeBookingPathBuilders.locationCheck()}>
                <LocationCheck />
            </Route>
            <Route exact path={makeBookingPathBuilders.addCar()}>
                <AddCarNewCustomerPage />
            </Route>
            <Route exact path={makeBookingPathBuilders.cars()}>
                <ViewCars />
            </Route>
            <Route exact path={makeBookingPathBuilders.serviceTypes(':carId')}>
                <SelectServiceType />
            </Route>
            <Route exact path={makeBookingPathBuilders.carPackageCategories(':carId', ':serviceTypeId')}>
                <SelectPackageCategory />
            </Route>
            <Route exact path={makeBookingPathBuilders.carPackages(':carId', ':serviceTypeId', ':categoryId')}>
                <SelectPackageContainer />
            </Route>
            <Route path={makeBookingPathBuilders.optionalExtras(':carId', ':serviceTypeId', ':categoryId', ':packageId')}>
                <SelectOptionalExtras />
            </Route>
            <Route path={makeBookingPathBuilders.chooseDate()}>
                <ChooseDateFormContainer />
            </Route>
            <Route path={makeBookingPathBuilders.makePayment()}>
                <MakePaymentContainer />
            </Route>
            <Route path={makeBookingPathBuilders.completeFirstTimeBooking()}>
                <RegisterAndBook />
            </Route>
            <Route path={makeBookingPathBuilders.enquirySent()}>
                <EnquiryConfirmationContainer />
            </Route>
            <Route path={makeBookingPathBuilders.paymentConfirmed(':bookingId')}>
                <PaymentConfirmationRedirectContainer />
            </Route>
        </>
    );
}