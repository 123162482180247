import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocalisation } from "../../Components/Localisation";
import config from "../../config";
import {
  actions,
  aggregateSelectors,
  selectConfirmationSummaryCars,
  useDispatchEffect,
} from "../../store";
import MakePayment from "./MakePayment";

const stripePromise = loadStripe(config.stripePrivateKey);

const MakePaymentContainer: React.FC = () => {
  const { country } = useLocalisation();
  const cars = useSelector(selectConfirmationSummaryCars);
  const totalPriceSummary = useSelector(
    aggregateSelectors.selectTotalBookingPriceSummary(country)
  );
  useDispatchEffect(() => actions.makeBooking.setCurrentStepIndex(3));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <MakePayment cars={cars} totalPrice={totalPriceSummary.grandTotal} />
    </Elements>
  );
};

export default MakePaymentContainer;
