import { Form } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppButton, AppButtons, AppFormikSelect } from "..";
import { AppFormikSelectOption } from "../AppInputs/AppFormikSelect";
import AppFormikTextArea from "../AppInputs/AppFormikTextArea";
import AppLoader from "../AppLoader";
import { selectors, useDispatchEffect, actions } from "../../store";

export interface CancelBookingFormProps {
  onSubmit: (cancellationReasonId: string, cancellationReasonNotes: string) => void,
  onRebook: () => void,
}

const CancelBookingForm: React.FC<CancelBookingFormProps> = ({ 
  onRebook,
}) => {
const { isLoading, cancellationReasons } = useSelector(
   selectors.cancellationReasons.apiState
 );
 useDispatchEffect(actions.cancellationReasons.fetch);

  const cancellationOptions: AppFormikSelectOption[] = cancellationReasons.map(cr => ({
    value: cr.id.toString(),
    label: cr.reason
  }));

const [reasonSelected, setReasonSelected] = useState<string>()

const onRebookValidation = () => {
  if (reasonSelected){
    onRebook();
  };
};

if (isLoading) return <AppLoader />;
return (
  <>
    <Form>
      <AppFormikSelect
        name="cancellationReasonId"
        label="Cancellation Reason"
        options={cancellationOptions}
        placeholder="Please select"
        required
        afterSelect={setReasonSelected}
      />
      <br />
      {reasonSelected && <AppFormikTextArea
        name="cancellationReasonNotes"
        label="Cancellation Notes"
        placeholder="Please add any notes about your cancellation"
      />}
      <AppButtons>
        <AppButton type="submit">
          Cancel Booking
        </AppButton>
        <AppButton type="submit" variant="inverted" onClick={onRebookValidation}>
          Cancel and Rebook
        </AppButton>
      </AppButtons>
    </Form>
  </>
);
};

export default CancelBookingForm;