import React from 'react';
import classnames from 'classnames';

import styles from './PageTitle.module.css';

interface PageTitleProps {
    variant?: 'modal',
}

const PageTitle: React.FC<PageTitleProps> = ({
    children,
    variant,
}) => {
    return (
        <h1
            className={classnames({
                [styles.root]: true,
                [styles.modal]: variant === 'modal',
            })}
        >
            {children}
        </h1>
    );
}

export default PageTitle;