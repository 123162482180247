import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './DropdownMenu.module.css';

export interface DropdownMenuItem {
    label: string,
    icon?: any,
    onClick: () => void,
}

interface DropdownMenuProps {
    isOpen: boolean,
    items: DropdownMenuItem[],
    inverted?: boolean,
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
    isOpen,
    items,
    inverted,
}) => {
    const renderItemContent = (item: DropdownMenuItem) => (
        <>
            {item.icon && (
                <FontAwesomeIcon className={styles.icon} icon={item.icon} />
            )}
            {item.label}
        </>
    )
    return (
        <ul className={classnames({
            [styles.root]: true,
            [styles.open]: isOpen,
            [styles.inverted]: inverted,
        })}>
            {items.map(item => (
                <li key={item.label}>
                    <button onClick={item.onClick}>
                        {renderItemContent(item)}
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default DropdownMenu;