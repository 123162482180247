import { buildUrl } from './common/buildUrl';
import { ApiStandardResponse, unpackStandardResponse } from './common/ApiStandardResponse';
import { doErrorToastIfRequired } from './common/doErrorToastIfRequired';
import * as httpClient from './common/httpClient'

export const checkPostcode = async (postcode: string, allow404: boolean = false) => {
    const url = buildUrl(`postcode/postcodecoverage?api-version=2.0`);
    const payload = { postcode };
    const allowedStatuses = allow404 ? [404] : [];
    const response =  await httpClient.postRequest<ApiStandardResponse<string>>(url, payload, allowedStatuses);
    doErrorToastIfRequired(response);
    if (response.isError) return response;

    return unpackStandardResponse(response, m => m);
}