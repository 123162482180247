import React from 'react';
import styles from './AppCard.module.css';

export interface AppCardContentProps {
    className?: string,
    style?: React.CSSProperties,
}
 
const AppCardContent: React.FC<AppCardContentProps> = ({
    children,
    className,
    style,
}) => {
    return (
        <div className={`${styles.content} ${className}`} style={style}>
            {children}
        </div>
    );
}

export default AppCardContent;