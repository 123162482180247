import * as httpClient from '../api';
import { HttpClientResponse } from '../api';
import { ApiStandardResponse, unpackStandardResponse } from '../api/common/ApiStandardResponse';
import { doErrorToastIfRequired } from '../api/common/doErrorToastIfRequired';
import config from '../config';
import { errorToast, successToast } from '../toast';
import { ForgotPasswordDto } from './models/ForgotPassword';
import { FullAccountInformation } from './models/FullAccountInformation';
import { LogInResponse } from './models/LogInResponse';
import { RegisterResponse } from './models/RegisterResponse';
import { ResetPasswordDto } from './models/ResetPassword';
import { UpdatePasswordDto } from './models/UpdatePassword';
import { UpdateUserConsentApiResponse, UserConsentDto } from './models/UpdateUserConsent';
import { UpdateUserDetailsApiResponse, UpdateUserDetailsDto } from './models/UpdateUserDetails';
import sessionStorageClient, { AccountInformation } from './sessionStorageClient';

interface AuthFailureResult {
    isError: true,
    error: string,
}

interface AuthSuccessResult {
    isError: false,
    displayName: string,
}

export type AuthResult = AuthFailureResult | AuthSuccessResult;

interface CreateAuthClientOptions {
    authApiBaseUrl: string,
}

const createAuthClient = ({
    authApiBaseUrl,
}: CreateAuthClientOptions) => {

    const register = async (email: string, password: string, confirmPassword: string): Promise<AuthResult> => {
        const response = await httpClient.postRequest<RegisterResponse>(`${authApiBaseUrl}/api/account/register`, {
            email,
            password,
            confirmPassword,
        });

        if (response.isError) {
            return {
                isError: true,
                error: response.message,
            }
        }

        sessionStorageClient.setAccountInformation(response.content.payload);
        sessionStorageClient.setToken(response.content.payload.token);

        return {
            isError: false,
            displayName: response.content.payload.displayName,
        };
    };

    const logIn = async (email: string, password: string): Promise<AuthResult> => {

        const response = await httpClient.postRequest<LogInResponse>(`${authApiBaseUrl}/api/account/login`, {
            email,
            password,
        });

        if (response.isError) {
            return {
                isError: true,
                error: response.message,
            };
        }

        sessionStorageClient.setAccountInformation(response.content.payload);
        sessionStorageClient.setToken(response.content.payload.token);

        return {
            isError: false,
            displayName: response.content.payload.displayName,
        };
    };

    const setLogInInformation = (accountInformation: AccountInformation, token: string) => {
        sessionStorageClient.setAccountInformation(accountInformation);
        sessionStorageClient.setToken(token);
    }

    const logOut = async (): Promise<AuthResult> => {
        const response = await httpClient.postRequest<void>(`${authApiBaseUrl}/api/account/logout`);

        if (response.isError) {
            return {
                isError: true,
                error: response.message,
            }
        }

        sessionStorageClient.clearAccountInformation();
        sessionStorageClient.clearToken();

        return {
            isError: false,
            displayName: '',
        }
    }

    const getFullAccountInformation = async () => {
        var response = await httpClient.getRequest<ApiStandardResponse<FullAccountInformation>>(`${authApiBaseUrl}/api/account/accountInformation`);

        doErrorToastIfRequired(response);

        return unpackStandardResponse(response, m => m);
    }

    const updateUserDetails = async (newDetails: UpdateUserDetailsDto): Promise<AuthResult> => {

        const response = await httpClient.postRequest<UpdateUserDetailsApiResponse>(`${authApiBaseUrl}/api/account/updateUserDetails`, newDetails);

        if (response.isError) {
            errorToast('Sorry, there was a problem updating your details, please try again later.')
            return {
                isError: true,
                error: response.message,
            }
        }

        sessionStorageClient.setAccountInformation(response.content.payload);
        successToast('Your details have been updated.');

        return {
            isError: false,
            displayName: response.content.payload.displayName,
        }
    }

    const updateUserConsent = async (newDetails: UserConsentDto): Promise<AuthResult> => {
        const response = await httpClient.postRequest<UpdateUserConsentApiResponse>(`${authApiBaseUrl}/api/account/updateConsent`, newDetails);

        if (response.isError) {
            errorToast('Sorry, there was a problem updating your consent preferences, please try again later.')
            return {
                isError: true,
                error: response.message,
            }
        }

        successToast('Your consent preferences have been updated.');

        return {
            isError: false,
            displayName: sessionStorageClient.getAccountInformation()?.displayName as string,
        }
    }

    const updatePassword = async (updatePasswordDto: UpdatePasswordDto): Promise<AuthResult> => {

        const response = await httpClient.postRequest<UpdateUserConsentApiResponse>(`${authApiBaseUrl}/api/account/updatePassword`, updatePasswordDto);

        if (response.isError) {
            errorToast('Sorry, there was a problem updating your password, please ensure that you have the correct current password.')
            return {
                isError: true,
                error: response.message,
            }
        }

        successToast('Your password has been updated.');

        return {
            isError: false,
            // returning userName made sense for log in log out but seems silly now for this and other functions.
            // TODO: look at different response type or different way of handling userName updates.
            displayName: sessionStorageClient.getAccountInformation()?.displayName as string,
        }
    }

    const forgotPassword = async (forgotPasswordDto: ForgotPasswordDto): Promise<HttpClientResponse<null>> => {
        const response = await httpClient.postRequest<null>(`${authApiBaseUrl}/api/account/forgotPassword`, forgotPasswordDto);

        if (response.isError) {
            errorToast('Sorry, there was a problem making your request, please try again.')
            return response;
        }

        return response;
    }

    const resetPassword = async (resetPasswordDto: ResetPasswordDto): Promise<HttpClientResponse<null>> => {
        const response = await httpClient.postRequest<null>(`${authApiBaseUrl}/api/account/resetPassword`, resetPasswordDto);

        if (response.isError) {
            errorToast('Sorry, there was a problem resetting your password, please try again.')
            return response;
        }

        successToast('Your password has been reset, you can now log in.');
        return response;
    }

    const getToken = () => {
        return sessionStorageClient.getToken();
    }

    const loadSession = () => {
        const accountInformation = sessionStorageClient.getAccountInformation();
        const userName = accountInformation?.displayName;

        return {
            isLoggedIn: !!userName,
            userName,
        };
    };

    const getAccountInformation = () => {
        return sessionStorageClient.getAccountInformation();
    }

    return {
        register,
        logIn,
        setLogInInformation,
        logOut,
        loadSession,
        getToken,
        getAccountInformation,
        getFullAccountInformation,
        updateUserDetails,
        updateUserConsent,
        updatePassword,
        forgotPassword,
        resetPassword,
    }
};

const authClient = createAuthClient({
    authApiBaseUrl: config.authApiBaseUrl,
});

export default authClient;
