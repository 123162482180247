import { FormikProps } from 'formik';
import React from 'react';
import AppButton from '../AppButton';
import { AppCardButtons, AppCardContent } from '../AppCard';
import AppForm from '../AppForm';
import AppFormikInput, { AppInput } from '../AppInputs';

export interface ResetPasswordFormValues {
    newPassword: string,
    confirmNewPassword: string,
}

interface OtherProps {
    email: string,
}

const ResetPasswordForm: React.FC<OtherProps & FormikProps<ResetPasswordFormValues>> = ({
    email,
}) => {
    return (
        <>
            <AppCardContent>
                <AppForm>
                    <AppInput type="text" label="Email" disabled value={email} />
                    <AppFormikInput name="newPassword" type="password" label="New Password" placeholder="New Password" />
                    <AppFormikInput name="confirmNewPassword" type="password" label="Confirm New Password" placeholder="Confirm New Password" />
                </AppForm>
            </AppCardContent>
            <AppCardButtons>
                <AppButton type="submit">Submit</AppButton>
            </AppCardButtons>
        </>
    );
}

export default ResetPasswordForm;