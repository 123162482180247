import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import AppFormikInput from '../AppInputs';
import AppButton from '../AppButton';
import AppForm from '../AppForm';
import { AppCardButtons, AppCardContent } from '../AppCard';
import { checkPostcode } from '../../api';
import debounce from 'lodash.debounce';
import { CountryCode, countryCodeMap } from '../Localisation';

export interface AddressFormValues {
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    postcode: string,
    town: string,
    country: number,
}

interface OtherProps {
    onCancel: () => void,
}

const InnerAddressForm: React.FC<OtherProps & FormikProps<AddressFormValues>> = ({
    isSubmitting,
    onCancel,
}) => {
    return (
        <Form>
            <AppCardContent style={{ paddingBottom: '1rem' }}>
                <AppForm>
                    <AppFormikInput label="Address Line 1" type="string" name="addressLine1" placeholder="Address Line 1" required />
                    <AppFormikInput label="Address Line 2" type="string" name="addressLine2" placeholder="Address Line 2" />
                    <AppFormikInput label="Address Line 3" type="string" name="addressLine3" placeholder="Address Line 3" />
                    <AppFormikInput label="Postcode" type="string" name="postcode" placeholder="Postcode" required />
                    <AppFormikInput label="Town / City" type="string" name="town" placeholder="Town / City" required />
                </AppForm>
            </AppCardContent>
            <AppCardButtons>
                <AppButton
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </AppButton>
                <AppButton
                    type="submit"
                    variant="inverted"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Submitting...' : 'Save Address'}
                </AppButton>
            </AppCardButtons>
        </Form>
    );
}

interface AddressFormProps {
    country: CountryCode;
    onSubmit: (address: AddressFormValues) => Promise<any>,
    onCancel: () => void,
}

const validatePostcodeIsCovered = async (postcode: string|null|undefined, resolve: (result: boolean) => void) => {
    if (!postcode) {
        resolve(true);
        return;
    }
    // Validating Postcode Regex again, so that if not valid we don't bother calling the API endpoint.
    if (!validatePostcodeRegex(postcode)) {
        resolve(true);
        return;
    }

    const postcodeIsCovered = await checkPostcode(postcode, true);
    if(postcodeIsCovered.statusCode === 200) {
        resolve(true);
        return;
    }
    resolve(false);
    return;
}

const validatePostcodeRegex = (postcode: string) => {
    const matches = postcode.match(/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i);
    return !!matches && matches.length !== 0;
}

const validatePostcodeCoveredDebounced = debounce(validatePostcodeIsCovered, 500);

const ValidationSchema = (country: CountryCode) => Yup.object().shape<AddressFormValues>({
    addressLine1: Yup.string()
        .required('Required.'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    postcode: Yup.string()
        .required('Please enter your postcode')
        .min(5, 'Missing part of your postcode')
        .max(8, 'Too many characters')
        .test(
            'validatePostcodeFormat',
            'Please check you have entered a valid postcode',
            postcode => {
                if(country === CountryCode.IE) return true;
                if (!postcode) return true;
                // Postcode regex taken from fresh norse site. It's ok for this as we are cloning that site but don't copy it without testing elsewhere.
                return validatePostcodeRegex(postcode)
            }
        )
        .test('validatePostcodeCovered',
                'We dont have coverage in your area at the moment',
                async (postcode) => new Promise(resolve => validatePostcodeCoveredDebounced(postcode, resolve))),
    town: Yup.string()
        .required('Required.'),
    country: Yup.number().required('Required.')
});

const AddressForm = withFormik<AddressFormProps, AddressFormValues>({
    mapPropsToValues: ({ country }) => ({
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        postcode: '',
        town: '',
        country: countryCodeMap[country]
    }),
    validationSchema: ({ country }: AddressFormProps) => ValidationSchema(country),
    handleSubmit: async (
        values,
        { props: { onSubmit } }
    ) => {
        await onSubmit(values);
    },
})(InnerAddressForm);

export default AddressForm;