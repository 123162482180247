import React from 'react';
import styles from './DashboardCardButtons.module.css';

const DashboardCardButtons: React.FC = ({
    children,
}) => {
    return (
        <div className={styles.root}>{children}</div>
    );
};

export default DashboardCardButtons;