import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './CarClubCard.module.css';
import AppButton from '../../Components/AppButton';
import { useIsMobileMedia } from '../../mediaQueries';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

export interface CardProps {
    title: React.ReactNode,
    titleIcon: any,
    titleLinkText?: string,
    onTitleLinkClick?: () => void,
}

const CarClubCard: React.FC<CardProps> = ({
        title,
        titleIcon,
        titleLinkText,
        onTitleLinkClick,
        children,
    }) => {
    const isMobile = useIsMobileMedia();

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <FontAwesomeIcon className={styles.cardIcon} icon={titleIcon} size="2x" />
                <div>{title}</div>
                <div className={styles.spacer} />
                { titleLinkText &&
                    <AppButton
                        onClick={onTitleLinkClick}
                        variant="white"
                        size="small"
                    >
                        {isMobile ? '+' : titleLinkText}
                        <FontAwesomeIcon className={styles.linkIcon} icon={faExternalLinkAlt} />
                    </AppButton>
                }
            </div>
            {children}
        </div>
    );
}

export default CarClubCard;